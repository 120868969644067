import React from "react";
import styled from "styled-components";

export const OnboardB = ({ title }) => {
  return (
    <OnboardBox>
      <img src="/images/onboard_text_image1.svg"  alt="icon"/>
      <h3 className="title mb-2">{title}</h3>
    </OnboardBox>
  );
};

const OnboardBox = styled.div`
  background-color: white;
  width: 420px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 9px 58.568px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: auto;
    height: 80px;
  }
  & h3 {
    font-weight: 400;
    font-size: 18px;
    color: #2c2c2c;
    font-family: "MatterNormal", sans-serif;
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
    & img {
      width: auto;
      height: 70px;
    }
    & h3 {
      font-size: 20px;
      padding-right: 40px;
    }
  }
  @media (max-width: 1024px) {
    width: 350px;
    margin-bottom: 20px;
    & img {
      width: auto;
      height: 70px;
    }
    & h3 {
      font-size: 19px;
      /* padding-right:10px; */
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
    & img {
      width: auto;
      height: 70px;
    }
    & h3 {
      font-size: 19px;
    }
  }
`;
