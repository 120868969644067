import React from "react";
import styled from "styled-components";

const StyledEventTypeRadio = styled.div`
  background-color: #ffffff;
  border: 1px solid;
  border-color: #00000012;
  border-radius: 24px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;

  & .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .leftdiv {
      background-color: blue;
      width: 100%;
    }

    & .rightdiv {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    width: 220px;
    padding: 30px 10px;
  }

  @media (max-width: 640px) {
    width: 155px;
    padding: 20px 10px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 25px 30px;
  }
`;

const StyledEventTypeInput = styled.div`
  background-color: #ffffff;
  border: 1px solid;
  border-color: #00000012;
  border-radius: 24px;
  padding: 35px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;

  & input {
    border: none;
    border-bottom: 1px solid #747474;
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 5px;
    color: #747474;
    font-family: "MatterNormal", sans-serif;

    &:focus {
      outline: none;
      border-color: transparent;
      box-shadow: none;
    }
  }
  @media (max-width: 1200px) {
    width: 220px;
    padding: 30px 10px;

    & input {
      font-size: 23px;
    }
  }

  @media (max-width: 640px) {
    width: 155px;
    padding: 20px 10px;
    & input {
      font-size: 18px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 25px 30px;
    & input {
      font-size: 20px;
    }
  }
`;

const RadioInput = styled.input.attrs({ type: "radio" })`
  opacity: 0;
`;

const RadioStyled = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  position: relative;
  top: -4px;
  ${RadioInput}:checked + & {
    background-color: white;
    border: 1.5px solid #353535;
  }

  ${RadioInput}:checked + &:before {
    content: "";
    width: 13px;
    height: 13px;
    background-color: #353535;
    border-radius: 50%;
  }
`;

const StyledEventType = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  & img {
    width: auto;
    height: 40px;
  }

  & p {
    font-weight: 400;
    font-size: 20px;
    color: #2c2c2c;
    font-family: "MatterNormal", sans-serif;
  }
  @media (max-width: 1200px) {
    & img {
      width: auto;
      height: 30px;
    }
    & p {
      font-size: 23px;
    }
  }
  @media (max-width: 640px) {
    & img {
      width: auto;
      height: 25px;
    }
    & p {
      font-size: 14px;
    }
  }
  @media (max-width: 480px) {
    & img {
      width: auto;
      height: 30px;
    }
    & p {
      font-size: 20px;
    }
  }
`;

const Eventype = ({ iconSrc, altText, label }) => {
  return (
    <StyledEventType>
      <div>
        <img src={iconSrc} alt={altText} />
      </div>
      <div>
        <p>{label}</p>
      </div>
    </StyledEventType>
  );
};

export const EventypeRadio = ({
  checked,
  onChange,
  label,
  iconSrc,
  altText,
}) => {
  return (
    <StyledEventTypeRadio>
      <div className="wrapper">
        <Eventype iconSrc={iconSrc} altText={altText} label={label} />
        <label>
          <RadioInput type="radio" checked={checked} onChange={onChange} />
          <RadioStyled />
        </label>
      </div>
    </StyledEventTypeRadio>
  );
};

export const EventypeInput = ({ value, onChangeInput }) => {
  return (
    <StyledEventTypeInput>
      <input
        type="input"
        value={value}
        onChange={onChangeInput}
        placeholder="Specify"
      />
    </StyledEventTypeInput>
  );
};
