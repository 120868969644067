import React, { useEffect,useState} from "react";
import styled from "styled-components";
import {useParams,useNavigate } from "react-router-dom";

export default function CallConfirmation({ alt }) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const navigate = useNavigate();
  
  const { token } = useParams();

  useEffect(() => {
    timeBeforeRedirect();
    const storedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode);
    }
  }, []);

  const timeBeforeRedirect = () => {
    setTimeout(() => {
      navigate(`/${token}/call-waiter`);

    }, 3000);
  };


  return (
    <Parent isDarkMode={isDarkMode}>
        <div className="parent">
      <div className="bouncing-element">
        <img src="/images/callsent.png" alt={alt} className="callsent" />
      </div>
      <div>
        <h1>Call Sent</h1>
      </div>
      <div>
        <img
          src="/images/celebrate.png"
          alt="celebrate"
          className="celebrate"
        />
      </div>
      <p>Waiter will arrive in about 5 minutes</p>
      </div>
    </Parent>
  );
}

const Parent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};
 
.parent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

 

   @media screen and (max-width: 820px) {
    .callsent {
    width: auto;
    height: 18rem;
    border: ${(props) => (props.isDarkMode ? "1px solid white" : "none")};
    border-radius: 50%;
  }

  h1 {
    color: ${(props) => (props.isDarkMode ? "white" : " #1d1d1d")};
    text-align: center;
    font-family: Gilroy;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-family: "MatterMedium", sans-serif;
  }
  p{
    color: ${(props) => (props.isDarkMode ? "white" : " #1d1d1d")};
    text-align: center;
    font-size: 20px;
    font-family: "MatterNormal", sans-serif;
  }
  .celebrate {
    width: auto;
    height: 50px;
  }
  } 

 
  @media screen and (max-width: 640px) {
    .callsent {
      width: auto;
      height: 200px;
    border: ${(props) => (props.isDarkMode ? "2px solid white" : "none")};
    }
    h1 {
      font-size: 30px;
    }
    p{
      font-size: 17px;
    }
    .celebrate {
      width: auto;
      height: 30px;
    }
  }
`;
