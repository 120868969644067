
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const request = axios.create({
  baseURL,
  cache: {
    readOnError: (error, request) => {
      return error.response.status >= 400 && error.response.status < 600;
    },
    clearOnStale: false,
  },
});

const getAccessToken = async () => {
  const refreshToken = localStorage?.getItem("refreshToken");
  try {
    const response = await fetch(`${baseURL}auth/token/refresh/`, {
      method: "POST",
      body: JSON.stringify({ refreshToken }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.error);
    }
    
    return data.token;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

request.interceptors.response.use(null, async (error) => {
  if (error.response && error.response.status === 401) {
    try {
      const token = await getAccessToken();
      error.config.headers.Authorization = `Bearer ${token}`;
      return axios.request(error.config);
    } catch (err) {
      // Handle refresh token failure or other errors
      console.error("Failed to refresh token", err);
      // Redirect user to login page or show error message
    }
  }
  return Promise.reject(error);
});

export const headers = (token) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  };
};

export default request;
