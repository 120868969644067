import React, { useState } from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { dateConverter } from "../../../../utils/dateTimeConverter";
import { ModalDropDown } from "../../components/ModalBox/ModalDropDown";
import Modal from "../../components/ModalBox/Modal";
import { STATUS_OPTIONS } from "../../../website/components/data";
import StatusHandler from "../../../../utils/statusHandler";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledNavLink,
  StyledNavLink2,
  DropdownWrapper,
} from "../../../../utils/styles/admin/requeststyles";
import useAdminRequest from "../../../../utils/apilogics/admin/requestLogic";
export const AdminRequest = () => {
  const {
    openDropdownIndex,
    setOpenDropdownIndex,
    loading,
    isModalUpdateRequestOpen,
    dropError,
    searchTerm,
    setSearchTerm,
    pageNumber,
    setPageNumber,
    setRequestid,
    setSelectedOption,
    activationrequestdata,
    activationrequestloading,
    activationrequesterror,
    closeModalUpdateRequest,
    openModalUpdateRequest,
    handleSearch,
    handleDropdownToggle,
    setDropError,
    formik,
  } = useAdminRequest();
  return (
    <Wrapper>
      {isModalUpdateRequestOpen && (
        <form onSubmit={formik.handleSubmit}>
          <Modal
            title="Treat Request"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalUpdateRequest}
          >
            <div className="wrapper-div">
              <ModalDropDown
                options={STATUS_OPTIONS?.map((e, index) => ({
                  label: e.label,
                  value: e.value,
                }))}
                error={dropError}
                defaultOption={{ label: "Select an option", value: null }}
                onSelect={(selectedOption) => {
                  setSelectedOption(selectedOption.value);
                  setDropError(null);
                }}
                // error={dropError}
              />
            </div>
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      <MainContent>
        <CenteredContent>
          <DashHeader2 backOnclick={`/dashboard-admin-overview/`} />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Activation Requests"
            onChange={handleSearch}
            placeholder="search requests"
            {...(!activationrequestloading && {
              request:
                activationrequestdata &&
                activationrequestdata?.data?.results.length === 0
                  ? ""
                  : activationrequestdata,
            })}
          >
            <StatusHandler
              isError={activationrequesterror}
              isLoading={activationrequestloading}
            >
              {activationrequestdata &&
              activationrequestdata?.data?.results.length < 1 ? (
                <ModalEmpty title="no request found" />
              ) : (
                activationrequestdata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="Event id">
                      <StyledNavLink2
                        to={`/dashboard-admin-event/${e.event_uuid}/details`}
                      >
                        {e.event_uuid.substring(0, 5)}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="status">{e.activation_status}</td>
                    <td data-cell="created">{dateConverter(e.created_at)}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setRequestid(e.id);
                                openModalUpdateRequest();
                              }}
                              className="nav-link"
                            >
                              treat the request
                            </StyledNavLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {activationrequestdata &&
            activationrequestdata?.data.results?.length > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={pageNumber}
                totalCount={activationrequestdata?.data.count}
                pageSize={5}
                onPageChange={(page) => setPageNumber(page)}
              />
            )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
