import React, { useState } from "react";
import styled from "styled-components";
import ArrowClose from "./ArrowClose";
import ArrowOpen from "./ArrowOpen";

export const Accordion = ({ title, body }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <StyledAccordion>
      <div className="bg mt-4 py-3">
        <div className="title px-3 pt-2">
          <h6 className="header">{title}</h6>
          <div onClick={() => setIsActive(!isActive)}>
            <p>
              {isActive ? (
                <ArrowOpen className="cursor" />
              ) : (
                <ArrowClose className="cursor" />
              )}
            </p>
          </div>
        </div>

        {isActive && (
          <div className="body px-3">
            <p className="bodypart">{body}</p>
          </div>
        )}
      </div>
    </StyledAccordion>
  );
};

const StyledAccordion = styled.div`
  .bg {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }

  .cursor {
    cursor: pointer;
    margin-bottom: 5px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    font-size: 15px;
    color: #2c2c2c;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: #000;
    line-height: 152%;

  }
  .bodypart {
    color: rgba(60, 60, 67, 0.85);
    line-height: 22px;
    font-weight: 400;
    font-size: 13px;
    line-height: 152%;
    padding-right: 100px;
    font-family: "MatterNormal", sans-serif;
    margin-top: 10px;
  }
  .box {
    box-shadow: 0px 20px 40px 20px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 1300px) {
    .header {
      font-size: 17px;
    }

    .bodypart {
      font-size: 15px;
      padding-right: 50px;
    }
  }
  @media (max-width: 640px) {
    .header {
      font-size: 18px;
    }
    .bodypart {
      font-size: 16px;
      padding-right: 0px;
    }
  }
  @media (max-width: 480px) {
    .header {
      font-size: 17px;
    }
    .bodypart {
      font-size: 16px;
      padding-right: 0px;
    }
  }
`;
