import React from "react";
import styled from "styled-components";

function CheckBoxExtra({ data, selectedOptions, onOptionChange }) {
  const handleCheckChange = (value) => {
    let updatedOptions = [...selectedOptions];
    if (updatedOptions.includes(value)) {
      updatedOptions = updatedOptions.filter((option) => option !== value);
    } else {
      updatedOptions.push(value);
    }
    onOptionChange(updatedOptions); // Call the callback function with updated checked items
  };

  if (data.length === 0) {
    // Render alternative content when there are no options
    return <div></div>;
  }

  return (
    <CheckContainer>
      <h1 className="select">Select Extras/Combos</h1>
      <Check>
        {data &&
          data.map((item, index) => (
            <div key={index} style={{ marginBottom: "15px" }}>
              <CustomCheckbox
                type="checkbox"
                id={item.id}
                value={selectedOptions.includes(item.extra)}
                onChange={() => handleCheckChange(item.extra)}
              />
              <Label htmlFor={item.id}>{item.extra}</Label>
            </div>
          ))}
      </Check>
    </CheckContainer>
  );
}
const CheckContainer = styled.div`
  margin-top: 20px;
  .select {
    color: #383838;
    font-family: "MatterMedium", sans-serif;
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 640px) {
    .select {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 480px) {
    .select {
      font-size: 17px;
    }
  }
`;
const Check = styled.div`
  columns: 3;
  justify-content: center;
  align-items: center;
  font-family: "MatterNormal", sans-serif;


  @media (max-width: 320px) and (min-height: 658px) {
    columns: 2;
  }
  @media (max-width: 360px) and (min-height: 740px) {
    columns: 2;
  }
`;
const CustomCheckbox = styled.input`
  position: relative;
  /* top:5px; */
  width: 23px;
  height: 23px;
  appearance: none;
  border: 2px solid #ff5c00;
  /* border-radius: 5px;/ */
  margin-right: 10px; /* Add margin-right to create spacing between items */

  &:checked {
    background-color: #ff5c00;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 820px) {
    position: relative;
    top: 4px;
    width: 18px;
    height: 18px;
    appearance: none;
    border: 2px solid #ff5c00;
    border-radius: 5px;
    margin-right: 10px; /* Add margin-right to create spacing between items */
    /* margin-top: 5px; */
    &:checked {
      background-color: #ff5c00;
    }

    &:focus {
      outline: none;
    }
  }
  @media (max-width: 360px) and (min-height: 740px) {
    position: relative;
    top: 3px;
    width: 15px;
    height: 15px;
  }
  @media (max-width: 320px) and (min-height: 658px) {
    position: relative;
    top: 3px;
    width: 15px;
    height: 15px;
  }
`;

const Label = styled.label`
  font-family: "MatterMedium", sans-serif;
  font-size: 25px;
  margin-left: 10px;

  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
  @media screen and (max-width: 480px) {
    font-size: 15px;
    margin-left: 0px;
  }
  /* @media (max-width: 320px) and (min-height: 658px) {
    font-size: 12px;
    margin-left: 0px;
  } */
`;
export default CheckBoxExtra;
