import React from "react";
import styled from "styled-components";

export const StatBox = ({ title, figure, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <div className="stats">
        <div>
          <h1>{figure}</h1>
        </div>
        <div>
          <p>{title}</p>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border: 1px solid #d8dcdf;
  border-radius: 12px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & .stats {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 2rem;
    & h1 {
      font-size: 25px;
      font-weight: 500;
      font-family: "MatterMedium", sans-serif;
    }

    & p {
      color: #000;
      font-family: "MatterNormal", sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
    }
  }

  @media (max-width: 1200px) {
    padding: 10px 10px;
    width: 130px;
    & .stats {
      & h1 {
        font-size: 30px;
      }

      & p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 640px) {
    padding: 20px 30px;
    width: 100%;
    & .stats {
      & h1 {
        font-size: 23px;
      }

      & p {
        font-size: 17px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 15px 20px;
    width: 100%;
    & .stats {
      & h1 {
        font-size: 30px;
      }

      & p {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 350px) {
    padding: 15px 20px;
    width: 100%;
    & .stats {
      & h1 {
        font-size: 25px;
      }

      & p {
        font-size: 17px;
      }
    }
  }
`;
