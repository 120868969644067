import React from "react";
import styled from "styled-components";

export const Error = ({ title, children }) => {
  return (
    <StyledError>
      <div>
          <p className="title">{title}</p>
        </div>
        
    </StyledError>
  );
};

const StyledError = styled.div` 
font-family: "MatterMedium", sans-serif;
  @media screen and (max-width: 820px) {
    .title {
    font-size: 18px;
    font-weight: 500;
    color: #F25D5D;
  }
  }
 

  @media (max-width: 640px) {
    .title {
    font-size: 17px;
  }
  }

`;
