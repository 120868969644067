import React from "react";
import { BottomNav } from "../components/Bottom";

const withBottomNav = (WrappedComponent) => {
  return (props) => (
    <div className="screen-size-detect">
      <WrappedComponent {...props} />
      <BottomNav />
    </div>
  );
};

export default withBottomNav;
