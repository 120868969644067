import React from "react";
import styled from "styled-components";
import { StatBox } from "../StatBox";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const StatWrapper = ({
  figureTotal,
  figureOngoing,
  figureApproved,
  activation,
  figureActivationReuquest,
}) => {
  const tomorrow = addDays(new Date(), 1);

  return (
    <Statistics>
      <div className="stat">
        <div>
          <DatePicker
            popperPlacement="bottom-end"
            customInput={<img src="/images/cal.svg" />}
            minDate={tomorrow}
          />
        </div>
        <div>
          <StatBox title="Total Events" figure={figureTotal} />
        </div>

        <div>
          <StatBox title="Ongoing Event" figure={figureOngoing} />
        </div>
        <div>
          <StatBox title="Approved Events" figure={figureApproved} />
        </div>
        {activation && (
          <div>
            <StatBox
              title="Activation Request"
              figure={figureActivationReuquest}
            />
          </div>
        )}
      </div>
    </Statistics>
  );
};

const Statistics = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0rem;
  & img {
    width: auto;
    height: 45px;
    cursor: pointer;
  }

  @media (max-width: 640px) {
    & img {
      width: auto;
      height: 60px;
    }
    .stat {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 480px) {
    .stat {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
`;
