import React from "react";
import styled from "styled-components";
import { Button2 } from "../button/Button2";
import { NavLink } from "react-router-dom";

export const GetStarted = ({ onClickupload ,toeventOnboard}) => {
  return (
    <Started>
      <div className="leftdiv">
        <div className="subdiv">
          <h1 className="h1">
            Get Started
            <br />
            already
          </h1>
          <div onClick={onClickupload}>
            <p>Create manage, and promote events, all in one place</p>
          </div>
          <NavLink
            className="button"
            to={toeventOnboard}
            // onClick={eventOnboardOnclick}
          >
          <Button2 title="Create an Event" src="/images/addw.svg" alt="icon"/>
          </NavLink>

        </div>

        <div className="middiv bouncing-element">
          <img src="/images/dash1.png" className="img" alt="illustration-1"/>
        </div>
      </div>

      <div className="rightdiv bouncing-element">
        <img src="/images/dash2.png" className="img" alt="illustration-2"/>
      </div>
    </Started>
  );
};

const Started = styled.div`
  width: 95%;
  margin: 20px;
  border-radius: 25px;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  h1 {
    font-family: "MatterMedium", sans-serif;
    font-size: 24px;
    color: #000;
    font-style: normal;
    font-weight: 500;
  }

  p {
    color: #000;
    font-family: "MatterNormal", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-right: 180px;
  }
  .img {
    height: 250px;
    width: auto;
  }
  .leftdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    background-color: white;
    padding: 20px 30px;
  border-radius: 25px;

  }
  .rightdiv {
    width: 30%;
  }
  @media (max-width: 1200px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-right: 0px;
    }
    .leftdiv {
    
    }
    .rightdiv {
      
    }
    .img {
        height: 170px;
      width: auto;
    }
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;

    h1 {
      font-size: 23px;
    }
    p {
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-right: 100px;
    }
    .leftdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .rightdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img {
        height: 350px;
      width: auto;
      margin-top: 20px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    width: 90%;


    h1 {
      font-size: 25px;
    }
    p {
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-right: 0px;
    }
    .leftdiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .rightdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img {
      height: 230px;
      width: auto;
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }
`;
