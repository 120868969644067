import React from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { dateConverter } from "../../../../utils/dateTimeConverter";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { ModalDropDown } from "../../components/ModalBox/ModalDropDown";
import { ModalTextBox2 } from "../../components/ModalBox/ModalTextBox";
import { ModalUploadFile2 } from "../../components/ModalBox/ModalUploadFile";
import { ModalUpload2 } from "../../components/ModalBox/ModalUploadFile";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledLink,
  StyledNavLink,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/menustyles";
import useAdminMenu from "../../../../utils/apilogics/admin/overview/menuLogic";
import StatusHandler from "../../../../utils/statusHandler";

export const AdminMenu = () => {
  const {
    searchTerm,
    pageNumber,
    setPageNumber,
    menudata,
    menuloading,
    menuerror,
    handleSearch,
    formikUpdateMenu,
    formikMenuCreate,
    isModalEditMenuOpen,
    isModalDeleteMenuOpen,
    catid,
    isModalCreateMenuOpen,
    category,
    setSelectedCategory,
    setDropError,
    dropError,
    handleFileSelect,
    id,
    setOpenDropdownIndex,
    openDropdownIndex,
    openModalEditMenu,
    openModalDeleteMenu,
    openModalCreateMenu,
    closeModalEditMenu,
    closeModalDeleteMenu,
    closeModalCreateMenu,
    handleDropdownToggle,
    removeFromMenu,
    setMenuId,
    handleFileChange,
    selectedFile,
    backgroundImage,
    loading,
  } = useAdminMenu();

  return (
    <Wrapper>
      {isModalEditMenuOpen && (
        <form onSubmit={formikUpdateMenu.handleSubmit}>
          <Modal
            title="Edit Menu"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditMenu}
          >
            <div className="wrapper-div">
              <div className="mt-4">
                <div className="input-fle">
                  <div className="wrapper-child">
                    <Input
                      label="Menu Name"
                      type="text"
                      name="menu_name"
                      onChange={formikUpdateMenu.handleChange}
                      value={formikUpdateMenu.values.menu_name}
                      error={formikUpdateMenu.errors.menu_name}
                    />
                  </div>
                  <div className="wrapper-child">
                    <Input
                      label="Cater Quantity"
                      type="text"
                      name="cater_qty"
                      onChange={formikUpdateMenu.handleChange}
                      value={formikUpdateMenu.values.cater_qty}
                      error={formikUpdateMenu.errors.cater_qty}
                    />
                  </div>
                </div>
                <ModalTextBox2
                  name="description"
                  handleTextChange={formikUpdateMenu.handleChange}
                  value={formikUpdateMenu.values.description}
                  error={formikUpdateMenu.errors.description}
                />

                <ModalUpload2
                  selectedFile={selectedFile}
                  handleFileChange={handleFileChange}
                  content={backgroundImage}
                  title="Menu Avatar"
                />
              </div>
            </div>
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteMenuOpen && (
        <Modal
          title="Delete Call Reasons"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeleteMenu}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="delete"
              onClick={() => removeFromMenu(catid)} // Pass categoryIdToDelete here
            />
          </div>
        </Modal>
      )}
      {isModalCreateMenuOpen && (
        <form onSubmit={formikMenuCreate.handleSubmit}>
          <Modal
            title="Create Menu"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateMenu}
          >
            <div className="wrapper-div">
              <ModalDropDown
                options={category?.map((e, index) => ({
                  label: e.label,
                  value: e.value,
                }))}
                defaultOption={{ label: "Select Category", value: null }}
                onSelect={(selectedOption) => {
                  setSelectedCategory(selectedOption.value);
                  setDropError(null);
                }}
                error={dropError}
              />
              <div className="mt-4">
                <div className="input-fle">
                  <div className="wrapper-child">
                    <Input
                      label="Menu Name"
                      type="text"
                      name="menu_name"
                      onChange={formikMenuCreate.handleChange}
                      value={formikMenuCreate.values.menu_name}
                      error={formikMenuCreate.errors.menu_name}
                      placeholder="enter menu name"
                    />
                  </div>
                  <div className="wrapper-child">
                    <Input
                      label="Cater Quantity"
                      type="text"
                      name="cater_qty"
                      onChange={formikMenuCreate.handleChange}
                      value={formikMenuCreate.values.cater_qty}
                      error={formikMenuCreate.errors.cater_qty}
                      placeholder="enter cater quantity"
                    />
                  </div>
                </div>
                <ModalTextBox2
                  name="description"
                  handleTextChange={formikMenuCreate.handleChange}
                  value={formikMenuCreate.values.description}
                  error={formikMenuCreate.errors.description}
                />

                <ModalUploadFile2
                  label="Menu Avatar"
                  name="upload_menu"
                  onFileSelect={handleFileSelect}
                  error={Error}
                />
              </div>
            </div>
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick={`/dashboard-admin/${id}/menu-categories`}
            show
            title="create menu"
            onClick={openModalCreateMenu}
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Menus"
            onChange={handleSearch}
            placeholder="search menu"
            {...(!menuloading && {
              menu:
                menudata && menudata?.data?.results.length === 0
                  ? ""
                  : menudata,
            })}
          >
            <StatusHandler isError={menuerror} isLoading={menuloading}>
              {menudata && menudata?.data?.results.length < 1 ? (
                <ModalEmpty title="no menu found" />
              ) : (
                menudata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="menu name">{e.menu_name}</td>
                    <td data-cell="category">{e.category}</td>
                    <td data-cell="cater quanity">{e.cater_qty}</td>
                    <td data-cell="created">{dateConverter(e.created_at)}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setMenuId(e.id);
                                openModalEditMenu();
                              }}
                              className="nav-link"
                            >
                              edit menu
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setMenuId(e.id);
                              }}
                              to={`/dashboard-admin/${e.event_uuid}/menu/${e.id}/extras/`}
                            >
                              add menu extras
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setMenuId(e.id);
                                openModalDeleteMenu();
                              }}
                            >
                              delete menu
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {menudata && menudata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={menudata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
