import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const Loader = styled.div`
  img {
    width: 50px;
    height: auto;
  }
  animation: ${zoomIn} 1s infinite;

  &:before,
  &:after {
    content: "";
  }

  &:before {
    top: 0.063rem;
  }

  &:after {
    bottom: 0.063rem;
  }
`;

export function LoadingSpinner() {
  return (
    <Container>
      <Loader>
        <img src="/images/wi.png" alt="wiserve-logo"/>
      </Loader>
    </Container>
  );
}
