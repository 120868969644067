import React from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import StatusHandler from "../../../../utils/statusHandler";
import useAdminApprovedEvent from "../../../../utils/apilogics/admin/approvedLogic";
import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  StyledNavLink2,
} from "../../../../utils/styles/admin/approvedstyles.js";

export default function AdminApprovedEvent() {
  const {
    searchTerm,
    pageNumber,
    setPageNumber,
    allapprovedloading,
    allapproveddata,
    allapprovederror,
    handleSearch,
  } = useAdminApprovedEvent();

  return (
    <Wrapper>
      <MainContent>
        <CenteredContent>
          <DashHeader2 backOnclick="/dashboard-admin-overview" />
        </CenteredContent>
        <Table>
          <TableData
            headtitle="All Approved Event"
            onChange={handleSearch}
            placeholder="search approved"
            {...(!allapprovedloading && {
              others:
                allapproveddata && allapproveddata?.data?.results.length === 0
                  ? ""
                  : allapproveddata,
            })}
          >
            <StatusHandler
              isError={allapprovederror}
              isLoading={allapprovedloading}
            >
              {allapproveddata &&
              allapproveddata?.data?.results.length === 0 ? (
                <ModalEmpty title="no approved event found" />
              ) : (
                allapproveddata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="event id">
                      <StyledNavLink2
                        to={`/dashboard-admin-event/${e.event_uuid}/details`}
                      >
                        {e.event_uuid.substring(0, 5)}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="location">{e.city}</td>
                    <td data-cell="event type">{e.event_type}</td>
                    <td data-cell="date">{e.event_date}</td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {allapproveddata && allapproveddata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={allapproveddata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
}
