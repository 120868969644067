import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { dateConverter } from "../../../../utils/dateTimeConverter";

export const ModalUploadFile = ({ onFileSelect, error, disabled }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileSelect(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <WrappUload>
      <StyledUploadFile onClick={handleClick} disabled={disabled}>
        <div className="left">
          <img src="/images/mdselect.svg" className="select" alt="Select" />
          {selectedFile ? (
            <p>{selectedFile.name}</p>
          ) : (
            <p>Click to select file</p>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div>
          <img src="/images/mduploadblur.svg" className="blur" alt="Upload" />
        </div>
      </StyledUploadFile>
      {error && <p className="error">{error}</p>}
    </WrappUload>
  );
};

export const ModalUploadFile2 = ({ onFileSelect, error, disabled, label }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileSelect(file); // Pass the selected file to the parent component
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <WrappUload2>
      <label className="label mb-1">{label}</label>
      <StyledUploadFile2 onClick={handleClick} disabled={disabled}>
        <div className="left">
          <img src="/images/mdselect.svg" className="select" alt="Select" />
          {selectedFile ? (
            <p>{selectedFile.name}</p>
          ) : (
            <p>Click to select file</p>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div>
          <img src="/images/mduploadblur.svg" className="blur" alt="Upload" />
        </div>
      </StyledUploadFile2>
      {error && <p className="error">{error}</p>}
    </WrappUload2>
  );
};

const WrappUload = styled.div`
  & .error {
    font-size: 14px;
    font-weight: 400;
    color: rgba(242, 93, 93, 1);
    font-family: "MatterNormal", sans-serif;
    text-align: right;
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    & .error {
      font-size: 15px !important;
    }
  }
`;
const WrappUload2 = styled.div`
  margin-bottom: 15px;
  & .error {
    font-size: 14px;
    font-weight: 400;
    color: rgba(242, 93, 93, 1);
    font-family: "MatterNormal", sans-serif;
    text-align: right;
    margin-top: 10px;
  }
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }

  @media (max-width: 640px) {
    .label {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    & .error {
      font-size: 15px !important;
    }
    .label {
      font-size: 18px;
    }
  }
`;
const StyledUploadFile = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: rgba(247, 248, 255, 1);
  border: 1px dotted rgba(65, 130, 255, 1);
  border-radius: 12px;
  cursor: pointer;

  & .blur {
    width: auto;
    height: 2rem;
  }

  & .left {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & .select {
      width: auto;
      height: 1.6rem;
    }

    & p {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      font-family: "MatterNormal", sans-serif;
      color: rgba(62, 62, 62, 1);
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}

  @media (max-width: 480px) {
    & p {
      font-size: 15px !important;
    }
  }
`;
const StyledUploadFile2 = styled.div`
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border: 1px solid #000;

  border-radius: 12px;
  cursor: pointer;

  & .blur {
    width: auto;
    height: 2rem;
  }

  & .left {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & .select {
      width: auto;
      height: 1.6rem;
    }

    & p {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      font-family: "MatterNormal", sans-serif;
      color: rgba(62, 62, 62, 1);
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}

  @media (max-width: 480px) {
    & p {
      font-size: 15px !important;
    }
  }
`;

export const ModalUpload2 = ({
  content,
  title,
  disabled,
  handleFileChange,
  selectedFile,
}) => {
  let filename = "";

  if (content) {
    try {
      const url = new URL(content);
      filename = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    } catch (error) {}
  }

  const openFile = () => {
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      window.open(fileURL, "_blank");
    }
  };

  return (
    <BigWrapper>
      <Wrapper2>
        <label className="label mb-1">{title}</label>
      </Wrapper2>
      <StyledUplodedeMenuBox2 disabled={disabled}>
        <div className="flex">
          <div className="left">
            <img src="/images/mdt4.png" className="select" alt="calender" />
          </div>
          <div className="filename">
            {selectedFile ? (
              <a onClick={openFile} style={{ cursor: "pointer" }}>
                {selectedFile.name}
              </a>
            ) : (
              content &&
              filename && (
                <a href={content} target="_blank" rel="noopener noreferrer">
                  {filename}
                </a>
              )
            )}
          </div>
        </div>

        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <div>
          <img
            src="/images/mduploadblur.svg"
            className="blur"
            alt="upload_icon"
            onClick={() => document.getElementById("fileInput").click()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </StyledUplodedeMenuBox2>
    </BigWrapper>
  );
};
export const ModalUploadFileDisabled = ({
  content,
  title,
  disabled,
  dateupload,
  note,
}) => {
  let filename = "";

  if (content) {
    try {
      const url = new URL(content);
      filename = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    } catch (error) {}
  }

  return (
    <BigWrapper>
      <Wrapper>
        <h1>{title}</h1>
      </Wrapper>
      <StyledUplodedeMenuBox disabled={disabled}>
        <div className="left">
          <img src="images/mdt4.png" className="cal" alt="calender" />
        </div>
        <div className="filename">
          {content && filename && (
            <a href={content} target="_blank" rel="noopener noreferrer">
              {filename}
            </a>
          )}
        </div>
      </StyledUplodedeMenuBox>
      <ContentWrapper>
        <div className="scrollable-content">
          <p className="noteupload">{note}</p>
          <p className="date">{`uploaded at ${dateConverter(dateupload)}`}</p>
        </div>
      </ContentWrapper>
    </BigWrapper>
  );
};

const BigWrapper = styled.div``;
const ContentWrapper = styled.div`
  .noteupload {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(62, 62, 62, 1);
    text-transform: lowercase;
    margin: 10px 0px 5px 0px;
  }

  .date {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: #4182ff;
    text-transform: lowercase;
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  & h1 {
    font-size: 16px;
    font-weight: 400;
    font-family: "MatterMedium", sans-serif;
    color: rgba(62, 62, 62, 1);
  }
`;
const Wrapper2 = styled.div`
  margin-bottom: 10px;
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }
  @media (max-width: 640px) {
    .label {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    .label {
      font-size: 18px;
    }
  }
`;
const StyledUplodedeMenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px 12px;
  background-color: rgba(247, 248, 255, 1);
  border: 1px dotted rgba(65, 130, 255, 1);
  border-radius: 12px;
  cursor: pointer; /* Default cursor */

  & .filename {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}

  & a {
    font-size: 13px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(62, 62, 62, 1);
    text-transform: lowercase;
    word-break: break-word;
  }

  & .left {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & .cal {
      width: auto;
      height: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    & p {
      font-size: 15px !important;
    }
  }
`;
const StyledUplodedeMenuBox2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 45px;
  padding: 10px 12px;
  margin-bottom: 15px;
  border: 1px solid #000;
  border-radius: 12px;

  cursor: pointer;
  .blur {
    width: auto;
    height: 2rem;
  }

  & .select {
    width: auto;
    height: 1.8rem;
  }
  & .flex {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  & .filename {
    & a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & a {
    font-size: 14px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(62, 62, 62, 1);
    text-transform: lowercase;
    word-break: break-word;
  }

  & .left {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & .cal {
      width: auto;
      height: 1.5rem;
    }
  }
  @media (max-width: 640px) {
    & p {
      font-size: 15px !important;
    }
    & a {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    & p {
      font-size: 15px !important;
    }
    & a {
      font-size: 18px;
    }
  }
`;
