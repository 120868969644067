import React, { useState, useContext } from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../../../website/components/Button";
import ProgressBar2 from "../../../components/progressbar/Progress2";
import { ContextStore } from "../../../../../context/contextStore";
import {
  EventypeRadio,
  EventypeInput,
} from "../../../components/EventypeRadio";

export const OnboardEventType = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { eventType, setEventType } = useContext(ContextStore);
  console.log(eventType, "context value");

  const handleInputChange = (value) => {
    setSelectedOption(null);
    setInputValue(value);
    setEventType(value);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setInputValue("");
    setEventType(value);
  };

  const canProceed = selectedOption !== null || inputValue.trim() !== "";

  return (
    <StyledOnboardEventType>
      <div className="proskip">
        <ProgressBar2 start={20} end={40} />
        <div className="skip"></div>
      </div>
      <div className="parent">
        <div className="leftdiv">
          <div className="textdiv">
            <div className="headerdiv">
              <h1>What type of Event do you wish to Host?</h1>
            </div>
            <div className="line"></div>
          </div>
          <div className="radioboxdiv mar">
            <div className="radio">
              <EventypeRadio
                iconSrc="/images/birthday.svg"
                label="Birthday"
                altText="birthday-icon"
                checked={selectedOption === "birthday"}
                onChange={() => handleOptionChange("birthday")}
              />
            </div>
            <div className="radio">
              <EventypeRadio
                iconSrc="/images/wedding.svg"
                label="Wedding"
                altText="wedding-icon"
                checked={selectedOption === "wedding"}
                onChange={() => handleOptionChange("wedding")}
              />
            </div>
            <div className="radio">
              <EventypeRadio
                iconSrc="/images/seminar.svg"
                label="Seminar"
                altText="seminar-icon"
                checked={selectedOption === "seminar"}
                onChange={() => handleOptionChange("seminar")}
              />
            </div>
          </div>
          <div className="radioboxdiv">
            <div className="radio">
              <EventypeRadio
                iconSrc="/images/anniversary.svg"
                label="Anniversary"
                altText="anniversary-icon"
                checked={selectedOption === "anniversary"}
                onChange={() => handleOptionChange("anniversary")}
              />
            </div>
            <div className="radio">
              <EventypeRadio
                iconSrc="/images/funeral.svg"
                label="Funeral"
                altText="funeral-icon"
                checked={selectedOption === "funeral"}
                onChange={() => handleOptionChange("funeral")}
              />
            </div>
            <div className="radio">
              <EventypeInput
                value={inputValue}
                onChangeInput={(e) => handleInputChange(e.target.value)}
              />
            </div>
          </div>
          <div className="button">
            <PrimaryButton
              bg="#0D0D0D"
              title="Proceed"
              to={canProceed ? "/host-onboard-event-info" : "#"}
              disabled={!canProceed}
            />
          </div>
        </div>
      </div>
    </StyledOnboardEventType>
  );
};

const StyledOnboardEventType = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 20px 5%;
  overflow: hidden;
  & .proskip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -33px;

    & .progress {
      width: 100%;
    }
    & .skip {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      & .image2 {
        cursor: pointer;
        position: relative;
        right: -27px;
        top: 0px;
      }
    }
  }
  & .button {
    align-self: flex-end;
    margin-top: 30px;
  }
  & .parent {
    display: flex;

    & .leftdiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      & .mar {
        margin-top: 60px;
      }
      & .radioboxdiv {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10rem;
      }
      & .textdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .line {
          width: 100%;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.07);
          margin-top: 20px;
        }
        & .headerdiv {
          display: flex;
          align-items: center;
          gap: 1em;
          margin-top: 30px;
          & h1 {
            font-weight: 500;
            font-size: 25px;
            color: #000;
            line-height: 44px;
            font-family: "MatterMedium", sans-serif;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    justify-content: flex-start;
    & .proskip {
      top: 0px;

      & .progress {
        width: 100%;
      }
      & .skip {
        width: 50%;
        & .image2 {
          height: 60px;
        }
      }
    }
    & .parent {
      display: flex;
      & .leftdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & .mar {
          margin-top: 60px;
        }
        & .radioboxdiv {
          gap: 1rem;
          & .radio {
          }
        }
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .line {
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.07);
            margin-top: 20px;
          }
          & .headerdiv {
            display: flex;
            align-items: center;
            gap: 1em;
            margin-top: 30px;
            & h1 {
              font-weight: 500;
              font-size: 30px;
              color: #000;
              line-height: 44px;
              font-family: "MatterMedium", sans-serif;
            }
          }
        }
      }
    }
  }

  @media (max-width: 640px) {
    & .proskip {
      top: 0px;

      & .skip {
        & .image2 {
          height: 40px;
        }
      }
    }
    & .parent {
      display: flex;

      & .leftdiv {
        width: 100%;

        & .mar {
          margin-top: 0px;
        }
        & .radioboxdiv {
          gap: 1rem;
        }
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .line {
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.07);
            margin-top: 20px;
          }
          & .headerdiv {
            display: flex;
            align-items: center;
            gap: 1em;
            margin-top: 30px;
            & h1 {
              font-size: 23px;
              padding-right: 50px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    height: auto;
    & .proskip {
      top: 0px;

      & .progress {
        width: 100%;
      }
      & .skip {
        width: 50%;
        & .image2 {
          height: 40px;
        }
      }
    }

    & .parent {
      display: flex;

      & .leftdiv {
        width: 100%;
        & .button {
          margin-top: 10px;
        }
        & .mar {
          margin-top: 30px;
        }
        & .radioboxdiv {
          display: grid;
          grid-template-columns: 1fr;
        }
        & .textdiv {
          & .line {
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.07);
            margin-top: 20px;
          }
          & .headerdiv {
            margin-top: 20px;
            & h1 {
              font-size: 26px;
            }
          }
        }
      }
    }
  }
`;
