import React from "react";

function ArrowClose({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 7 12"
    >
      <path
        fill="#FF7300"
        stroke="#FF7300"
        strokeWidth="0.5"
        d="M4.241 6.082L.584 2.426A.74.74 0 111.61 1.361l.018.018 4.18 4.18a.739.739 0 010 1.045L1.63 10.782A.74.74 0 01.566 9.758l.018-.018 3.657-3.657z"
      ></path>
    </svg>
  );
}

export default ArrowClose;
