import React, { useEffect } from "react";
import HostApprovedEvent from "./dashboardhost/HostApprovedEvent";
import HostOnGoingEvent from "./dashboardhost/HostOngoingEvent";
import Dashboard from "./dashboard/Dasboard";
import { HostOverview } from "./dashboardhost/HostOverview";
import { HostEventDetails } from "./dashboardhost/HostEventDetails";
import { AdminOverview } from "./dashboardadmin/AdminOverview";
import AdminOnGoingEvent from "./dashboardadmin/AdminOngoingEvent";
import AdminApprovedEvent from "./dashboardadmin/AdminApprovedEvent";
import { Onboarding } from "./dashboardhost/onboardevent/Onboard";
import { OnboardEventType } from "./dashboardhost/onboardevent/OnboardEventType";
import { OnboardEventInfo } from "./dashboardhost/onboardevent/OnboardEventInfo";
import { OnboardEventMedia } from "./dashboardhost/onboardevent/OnboardEventMedia";
import { AdminMenuCategory } from "./dashboardadmin/AdminMenuCategory";
import { AdminMenu } from "./dashboardadmin/AdminMenu";
import { AdminVendor } from "./dashboardadmin/AdminVendor";
import { AdminCallReason } from "./dashboardadmin/AdminCallReason";
import { AdminMenuExtras } from "./dashboardadmin/AdminMenuExtras";
import { AdminRequest } from "./dashboardadmin/AdminRequest";
import { AdminEventDetails } from "./dashboardadmin/AdminEventDetails";
import { AdminTriviaResult } from "./dashboardadmin/AdminTriviaResult";
import { AdminTrivia } from "./dashboardadmin/AdminTrivia";
import { AdminOptical } from "./dashboardadmin/AdminOptical";

//Dashboard Host
export const HostDashboardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <HostOverview />
    </Dashboard>
  );
};

export const HostEventDetailsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <HostEventDetails />
    </Dashboard>
  );
};

export const HostOngoingEventPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <HostOnGoingEvent />
    </Dashboard>
  );
};

export const HostApprovedEventPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <HostApprovedEvent />
    </Dashboard>
  );
};

//Onboard New Event

export const HostOnboardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Onboarding />;
};

export const HostOnboardEventType = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <OnboardEventType />;
};

export const HostOnboardEventInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <OnboardEventInfo />;
};

export const HostOnboardEventMedia = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <OnboardEventMedia />;
};
//Dashboard Admin

export const AdminApprovedEventPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <AdminApprovedEvent />
    </Dashboard>
  );
};

export const AdminOngoingEventPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <AdminOnGoingEvent />
    </Dashboard>
  );
};

export const AdminActivationRequestPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      {/* <ActivationRequest /> */}
      <AdminRequest />
    </Dashboard>
  );
};

export const AdminDashboardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      {/* <Event /> */}
      <AdminOverview />
    </Dashboard>
  );
};

export const AdminEventDetailsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dashboard>
      <AdminEventDetails />
    </Dashboard>
  );
};

export const AdminMenuCategoriesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminMenuCategory />
    </Dashboard>
  );
};

export const AdminMenuPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminMenu />
    </Dashboard>
  );
};

export const AdminMenuExtrasPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminMenuExtras />
    </Dashboard>
  );
};
export const AdminVendorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminVendor />
    </Dashboard>
  );
};

export const AdminCallReasonPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminCallReason />
    </Dashboard>
  );
};

export const AdminTriviaResultPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminTriviaResult />
    </Dashboard>
  );
};
export const AdminTriviaPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminTrivia />
    </Dashboard>
  );
};
export const AdminOpticalPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dashboard>
      <AdminOptical />
    </Dashboard>
  );
};