import React from "react";
import styled from "styled-components";

export const Input = ({
  placeholder,
  label,
  forr,
  type,
  onChange,
  value,
  error,
  name,
  disabled,
  multiple,
}) => {
  const InputComponent = multiple ? "textarea" : "input";

  return (
    <StyledInput className="">
      <div className="parent">
        <label className="label mb-1" htmlFor={forr}>
          {label}
        </label>
        <InputComponent
          type={type}
          name={name}
          className={`form-control${multiple ? ' textarea' : ''}`}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
        {error && <p id="text-danger">{error}</p>}
      </div>
    </StyledInput>
  );
};

const StyledInput = styled.div`
  .parent {
    margin-bottom: 30px;
  }
  #text-danger {
    color: red;
    font-size: 0.8rem;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #999999;
    outline: -webkit-focus-ring-color auto 0px;
    background-color: rgb(250, 250, 250);
  }
  .form-control {
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    height: 45px; /* Default height for input */
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    font-family: "MatterNormal", sans-serif;
  }
  .textarea {
    height: 120px; /* Adjust the height for the textarea */
  }
  .label {
    color: #344054;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }

  @media (max-width: 1024px) {
    width: 100%;
    .form-control {
    font-size: 16px;
  
  }

  .label {
    font-size: 16px;
  }
    .form-control.textarea {
      height: 100px; /* Adjust the height for the textarea on smaller screens */
    }

  }

  @media (max-width: 640px) {
    width: 100%;
    .form-control {
    font-size: 16px;
  
  }

  .label {
    font-size: 16px;
  }
    .form-control.textarea {
      height: 80px; /* Adjust the height for the textarea on even smaller screens */
    }

  }
  @media (max-width: 480px) {
    width: 100%;
    .form-control {
    font-size: 18px;
  
  }

  .label {
    font-size: 18px;
  }
    .form-control.textarea {
      height: 80px; /* Adjust the height for the textarea on even smaller screens */
    }

  }
`;
