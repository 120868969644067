import "./App.css";
import Routing from "./routes/routes";
import FormDataProvider from "./context/contextData";
import { Routes,BrowserRouter } from "react-router-dom";

function App() {
  return (
    <FormDataProvider>
         <div className="app-container">
      <Routing />
         </div>
      
    </FormDataProvider>
  );
}

export default App;
