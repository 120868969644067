import { useState } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../request";

const useHostApprovedEvent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const fetchApprovedEvent = (page, searchTerm) => {
    let url = `/host/approved-event/get/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: hostapproveddata,

    isLoading: hoastapprovedloading,
    error: hostapprovederror,
  } = useQuery(
    ["host-approvedevent", pageNumber, searchTerm],
    () => fetchApprovedEvent(pageNumber, searchTerm),
    {
      enabled: true,
    }
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    hoastapprovedloading,
    hostapproveddata,
    hostapprovederror,
    handleSearch,
  };
};

export default useHostApprovedEvent;
