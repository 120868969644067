import React, { useState } from "react";
import styled from "styled-components";
import { SecondaryButton } from "../components/Button";
import "../../../App.css";
import { GENDER_OPTIONS } from "../components/data";
import { ModalDropDown3 } from "../../dashboard/components/ModalBox/ModalDropDown";
import Modal from "../../dashboard/components/ModalBox/Modal";
import { Button2 } from "../../dashboard/components/button/Button2";
import { PassWordInput2 } from "../../dashboard/components/input/PasswordInput";
import { Input2 } from "../../dashboard/components/input/Input";
import { CountryCodeInput2 } from "../components/Input/InputCountry";
import request from "../../../utils/request";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const Welcome = () => {
  const [loading, setLoading] = useState(false);
  const [isModalLoginOpen, setModalLoginOpen] = useState(false);
  const [isModalSignupOpen, setModalSignupOpen] = useState(false);
  const [isModalVerifyOpen, setModalVerifyOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [dropError, setDropError] = useState(null);
  const [verifyInfo, setVerifyInfo] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("234");

  const handleCountryChange = (countryCode) => {
    setSelectedCountryCode(countryCode);
  };
  const navigate = useNavigate();

  const openModalVerify = () => {
    setModalVerifyOpen(true);
  };

  const closeModalLogin = () => {
    setModalLoginOpen(false);
  };
  const closeModalSignup = () => {
    setModalSignupOpen(false);
  };

  const closeModalVerify = () => {
    setModalVerifyOpen(false);
  };

  const onSubmitSendOtp = async (email) => {
    const payload = {
      email: email, // Access email property from verifyInfo object
    };
    try {
      const response = await request.post("/otps/send/", payload);
      toast.success("email sent");
    } catch (error) {
      // console.log(error);
    }
  };

  const onSubmitLogin = async (values, { resetForm }) => {
    setLoading(true);
    setVerifyInfo(values.email);
    try {
      const response = await request.post("/auth/login/", values);
      const data = response?.data;
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("userdata", JSON.stringify(data.data.information));

      resetForm();

      if (data.data.information.is_host) {
        navigate("/dashboard-host-overview");
      } else if (data.data.information.is_superuser) {
        navigate("/dashboard-admin-overview");
      } else {
        closeModalLogin();
        setModalVerifyOpen(true);
      }
      setLoading(false);
      toast.success("login successfull !!");
    } catch (error) {
      // console.log(error)
      if (error.response && error.response.status === 403) {
        toast.error(error.response.data.errors);
        // Open modal for email verification
        closeModalLogin();
        onSubmitSendOtp(values.email);
        setTimeout(() => {
          setModalVerifyOpen(true);
        }, 2000);
      } else {
        toast.error(error.response.data.errors);
      }
      setLoading(false);
    }
  };

  const onSubmitSignup = async (values, { resetForm }) => {
    values.sex = selectedOption;
    values.mobile_number = selectedCountryCode + values.mobile_number;
    setVerifyInfo(values.email);
    setLoading(true);
    try {
      const response = await request.post("/auth/register/", values);
      const data = response?.data;
      const responses = response;
      toast.success("sign up successful !!");
      setLoading(false);
      resetForm();
      closeModalSignup();
      toast.success("an otp has been sent to your email");
      setTimeout(() => {
        openModalVerify();
      }, 1000);
    } catch (error) {
      if (!navigator.onLine) {
        toast.error("Network Error");
        setLoading(false);
      } else {
        toast.error("user with this email already exist");
        // resetForm();
        setLoading(false);
      }
    }
  };

  const onSubmitVerify = async (values) => {
    values.email = verifyInfo;
    setLoading(true);
    try {
      const response = await request.post("/otps/verify/", values);
      const data = response;
      // console.log(data);
      toast.success("email verification successful !!");
      setLoading(false);
      closeModalVerify();
      setTimeout(() => {
        openModalLogin();
      }, 1500);
    } catch (error) {
      toast.error(error.response.data.error);
      setLoading(false);
    }
  };

  const logininitialValues = {
    email: "",
    password: "",
  };
  const verifyinitialValues = {
    email: "",
    otp: "",
  };
  const signupinitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    mobile_number: "",
    company: "",
    password: "",
    sex: "",
  };
  const loginvalidationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("email is required"),
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .required("password is required"),
  });
  const verifyvalidationSchema = Yup.object({
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
  });
  const signupvalidationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("email is required"),
    firstname: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
      .required("firstname is required"),
    lastname: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
      .required("lastname is required"),
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .required("password is required"),
  });
  const formikLogin = useFormik({
    initialValues: logininitialValues,
    validationSchema: loginvalidationSchema,
    onSubmit: onSubmitLogin,
  });
  const formikVerify = useFormik({
    initialValues: verifyinitialValues,
    validationSchema: verifyvalidationSchema,
    onSubmit: onSubmitVerify,
  });
  const formikSignup = useFormik({
    initialValues: signupinitialValues,
    validationSchema: signupvalidationSchema,
    onSubmit: onSubmitSignup,
  });
  const openModalSignup = () => {
    setModalSignupOpen(true);
  };
  const openModalLogin = () => {
    setModalLoginOpen(true);
  };

  return (
    <>
      <Wrapper>
        {isModalLoginOpen && (
          <form onSubmit={formikLogin.handleSubmit}>
            <Modal
              imageSource="images/mdt3.png"
              title="Login"
              rightIconSrc="images/mdx.svg"
              onClose={closeModalLogin}
              showlogo
            >
              <div className="mt-5">
                <Input2
                  label="Email"
                  placeholder="Enter email..."
                  type="email"
                  name="email"
                  onChange={formikLogin.handleChange}
                  value={formikLogin.values.email}
                  error={formikLogin.errors.email}
                />
                <div className="mt-4">
                  <PassWordInput2
                    label="Password"
                    placeholder="Enter password..."
                    name="password"
                    onChange={formikLogin.handleChange}
                    value={formikLogin.values.password}
                    error={formikLogin.errors.password}
                  />
                </div>
              </div>
              <div className="align">
                <Button2
                  title="Sign in"
                  src="/images/arrow-right.svg"
                  backgroundColor={
                    loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                  }
                  alt="create"
                />
              </div>
            </Modal>
          </form>
        )}
        {isModalVerifyOpen && (
          <form onSubmit={formikVerify.handleSubmit}>
            <Modal
              imageSource="images/mdt3.png"
              title="Verify Otp"
              rightIconSrc="images/mdx.svg"
              onClose={closeModalVerify}
              showlogo
            >
              <div className="mt-5">
                <PassWordInput2
                  label="Otp"
                  placeholder="Enter otpcode..."
                  name="otp"
                  onChange={formikVerify.handleChange}
                  value={formikVerify.values.otp}
                  error={formikVerify.errors.otp}
                />
              </div>

              <div className="align">
                <Button2
                  title="Submit"
                  src="/images/arrow-right.svg"
                  backgroundColor={
                    loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                  }
                  alt="create"
                />
              </div>
            </Modal>
          </form>
        )}
        {isModalSignupOpen && (
          <form onSubmit={formikSignup.handleSubmit}>
            <Modal
              imageSource="images/mdt3.png"
              title="Login"
              rightIconSrc="images/mdx.svg"
              onClose={closeModalSignup}
              showlogo
            >
              <div className="mt-5">
                <Input2
                  label="First name"
                  placeholder="Enter your firstname..."
                  type="text"
                  name="firstname"
                  onChange={formikSignup.handleChange}
                  value={formikSignup.values.firstname}
                  error={formikSignup.errors.firstname}
                />
                <Input2
                  label="Last name"
                  placeholder="Enter your lastname..."
                  type="text"
                  name="lastname"
                  onChange={formikSignup.handleChange}
                  value={formikSignup.values.lastname}
                  error={formikSignup.errors.lastname}
                />
                <Input2
                  label="Email"
                  placeholder="Enter your email..."
                  type="email"
                  name="email"
                  onChange={formikSignup.handleChange}
                  value={formikSignup.values.email}
                  error={formikSignup.errors.email}
                />

                <ModalDropDown3
                  label="Gender"
                  options={GENDER_OPTIONS?.map((e, index) => ({
                    label: e.label,
                    value: e.value,
                  }))}
                  defaultOption={{ label: "Select an option", value: null }}
                  onSelect={(selectedOption) => {
                    setSelectedOption(selectedOption.value);
                    setDropError(null);
                  }}
                  // error={dropError}
                />
                <CountryCodeInput2
                  name="mobile_number"
                  type="number"
                  country={handleCountryChange}
                  onChange={formikSignup.handleChange}
                  value={formikSignup.values.mobile_number}
                  error={formikSignup.errors.mobile_number}
                />
                <Input2
                  label="Company (optional)"
                  placeholder="Enter your company..."
                  type="text"
                  name="company"
                  onChange={formikSignup.handleChange}
                  value={formikSignup.values.company}
                  error={formikSignup.errors.company}
                />
                <PassWordInput2
                  label="Password"
                  placeholder="Enter password..."
                  name="password"
                  onChange={formikSignup.handleChange}
                  value={formikSignup.values.password}
                  error={formikSignup.errors.password}
                />
              </div>
              <div className="align">
                <Button2
                  title="Create an account"
                  src="/images/arrow-right.svg"
                  backgroundColor={
                    loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                  }
                  alt="create"
                />
              </div>
            </Modal>
          </form>
        )}
        <StyledWelcomeSection>
          <div className="parent">
            <div className="textdiv">
              <h1 className="headerwelcome">
                A Food Culture that is
                <br />
                Revolutionising the Way
                <br />
                People Dine.
              </h1>
              <p className="short">
                Wiserve provides a seamless digital experience for
                <br />
                guests to order food at their comfort
              </p>
              <p className="mobileshort">
                Wiserve provides a seamless digital experience for guests to
                order food at their comfort
              </p>
              <ButtonGroup>
                <div>
                  <Button2
                    title="Get started now"
                    src="/images/arrow-right.svg"
                    backgroundColor="#FF5C00"
                    alt="create"
                    onClick={openModalSignup}
                  />
                </div>

                <div>
                  <SecondaryButton title="Sign in" onClick={openModalLogin} />
                </div>
              </ButtonGroup>
            </div>
            <div className="herodiv">
              <img
                src="images/hero.png"
                className="heroimage bouncing-element"
                alt="wiserve heroimage"
              />
            </div>
          </div>
        </StyledWelcomeSection>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  & .align {
    display: flex;
    justify-content: flex-end;
  }
`;
const StyledWelcomeSection = styled.div`
  font-family: "MatterNormal", sans-serif;

  height: 100vh;
  & .wrapper-div {
    width: 100%;
  }
  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5%;
  }
  .heroimage {
    width: auto;
    height: 600px;
  }

  .herodiv {
    display: flex;
    gap: 1rem;
  }

  .headerwelcome {
    font-weight: 400;
    font-size: 36px;
    color: #2c2c2c;
    line-height: 44px;
  }

  .short {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 152%;
    color: #737373;
    margin-top: 30px;
  }

  .mobileshort {
    display: none;
  }
  @media (max-width: 1200px) {
    height: 100vh;
    .headerwelcome {
      font-size: 30px;
      line-height: 30px;
    }

    .short {
      font-size: 17px;
    }

    .heroimage {
      width: auto;
      height: 450px;
    }

    .parent {
      display: flex;
    }
  }
  @media (max-width: 1024px) {
    height: 50vh;
    .headerwelcome {
      font-size: 30px;
      line-height: 30px;
    }

    .short {
      font-size: 17px;
    }

    .heroimage {
      width: auto;
      height: 360px;
    }

    .parent {
      display: flex;
    }
  }

  @media (max-width: 640px) {
    height: 100vh;

    .headerwelcome {
      font-size: 30px;
      line-height: 30px;
    }
    .herodiv {
      margin: auto;
    }
    .short {
      font-size: 16px;
    }

    .heroimage {
      width: auto;
      height: 400px;
    }

    .parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  @media (max-width: 480px) {
    height: 100vh;
    .headerwelcome {
      font-size: 28px;
      line-height: 35px;
      line-height: 130%;
    }
    .mobileshort {
      display: block;
      text-align: left;
      font-size: 18px;
      line-height: 26px;

      margin-top: 30px;
    }
    .herodiv {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .short {
      display: none;
    }
    .heroimage {
      width: auto;
      height: 350px;
    }

    .parent {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 350px) {
    height: 100vh;
    .headerwelcome {
      font-size: 25px;
      line-height: 130%;
    }
    .mobileshort {
      display: block;
      font-size: 17px;
      line-height: 26px;

      margin-top: 30px;
    }
    .herodiv {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .short {
      display: none;
    }
    .heroimage {
      width: auto;
      height: 350px;
    }

    .parent {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 3rem;
  margin-top: 40px;

  @media (max-width: 1024px) {
    gap: 2rem;
    margin-top: 30px;
  }

  @media (max-width: 640px) {
    gap: 5rem;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 2rem;
    margin-top: 20px !important;
  }
`;
