import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";


const useAdminReason = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalEditReasonOpen, setModalEditReasonOpen] = useState(false);
  const [isModalCreateReasonOpen, setModalCreateReasonOpen] = useState(false);
  const [isModalDeleteReasonOpen, setModalDeleteReasonOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dropError, setDropError] = useState(null);
  const { id } = useParams();
  const [catid, setCatId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [category, setCatgeory] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [reasonid, setReasonId] = useState("");

  useEffect(() => {
    if (catid) {
      axios
        .get(
          `${URL}superadmin/callreason/${catid}/rud/`,
          headers(localStorage.getItem("token"))
        )
        .then((response) => {
          setInitialValues(response.data);
          formikUpdateReason.setValues(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [catid]);
  const vendorUpdateinitialValue = {
    reason: initialValues?.menu_name || "",
  };

  const onSubmitUpdateVendor = async (values, { resetForm }) => {
    const payload = {
      reason: values.reason,
    };

    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}superadmin/callreason/${catid}/rud/`,
        payload,
        headers(localStorage.getItem("token"))
      );

      toast.success("call reason updated successful");
      resetForm();
      refetch();
      setLoading(false);
      setModalEditReasonOpen(false);
    } catch (error) {
      toast.error("call reason update failed");
      setLoading(false);
    }
  };
  const formikUpdateReason = useFormik({
    initialValues: vendorUpdateinitialValue,
    onSubmit: onSubmitUpdateVendor,
  });

  useEffect(() => {
    if (!category) {
      return;
    } else {
      mapData();
    }
  }, []);
  const mapData = async () => {
    try {
      const response = await axios.get(
        `${URL}superadmin/menucategory-no-pg/${id}/`,
        headers(localStorage.getItem("token"))
      );
      const st = response?.data?.map((e) => ({
        label: e.cat_name,
        value: e.id,
      }));
      setCatgeory(st);
    } catch (error) {
      setCatgeory([]);
    }
  };

  const fetchCallReason = (page, searchTerm) => {
    let url = `/superadmin/callreason/${id}/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: callreasondata,
    isLoading:callreasonloading,
    isError:callreasonerror,
    refetch,
  } = useQuery(["callreason", pageNumber, searchTerm], () =>
    fetchCallReason(pageNumber, searchTerm)
  );
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  const removeFromCallReason = async () => {
    try {
      const response = await axios.delete(
        `${URL}superadmin/callreason/${reasonid}/rud/`,

        headers(localStorage.getItem("token"))
      );
      const datas = response.data;
      toast.success("call reason deleted successful");
      refetch();
      setModalDeleteReasonOpen(false);
    } catch (error) {
      toast.error("call reason delete failed");
    }
  };

  const openModalEditReason = () => {
    setModalEditReasonOpen(true);
  };

  const openModalDeleteReason = () => {
    setModalDeleteReasonOpen(true);
  };
  const openModalCreateReason = () => {
    setModalCreateReasonOpen(true);
  };

  const closeModalEditReason = () => {
    setModalEditReasonOpen(false);
  };
  const closeModalDeleteVendor = () => {
    setModalDeleteReasonOpen(false);
  };
  const closeModalCreateReason = () => {
    setModalCreateReasonOpen(false);
  };
  const handleDropdownToggle = (index, id) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedEventId(id);
  };

  const createReasonvalidationSchema = Yup.object({
    reason: Yup.string().required("call reason is required"),
  });

  const createReasoninitialValues = {
    reason: "",
  };

  const onSubmitCreateReason = async (values) => {
    values.category_id = selectedCategory;
    values.event_uuid = id;
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}superadmin/${id}/call-waiter-reason/category/${selectedCategory}`,

        values,
        headers(localStorage.getItem("token"))
      );
      toast.success("call reason created successfully!");
      setCatgeory([]);
      mapData();
      setModalCreateReasonOpen(false);
      refetch();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("call reason creation failed");
      setLoading(false);
      setModalCreateReasonOpen(false);
    }
  };

  const formikReasonCreate = useFormik({
    initialValues: createReasoninitialValues,
    validationSchema: createReasonvalidationSchema,
    onSubmit: onSubmitCreateReason,
  });

  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    openDropdownIndex,
    loading,
    isModalEditReasonOpen,
    isModalCreateReasonOpen,
    isModalDeleteReasonOpen,
    selectedEventId,
    selectedCategory,
    dropError,
    catid,
    searchTerm,
    pageNumber,
    callreasondata,
    callreasonerror,
    callreasonloading,
    category,
    initialValues,
    id,
    removeFromCallReason,
    setSelectedCategory,
    reasonid,
    handleSearch,
    openModalEditReason,
    openModalCreateReason,
    openModalDeleteReason,
    closeModalEditReason,
    closeModalDeleteVendor,
    closeModalCreateReason,
    handleDropdownToggle,
    setReasonId,
    setCatId,
    setOpenDropdownIndex,
    setDropError,
    formikUpdateReason,
    formikReasonCreate
  };
};

export default useAdminReason;
