import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button2 } from "../button/Button2";
import { ModalDropDown } from "../ModalBox/ModalDropDown";
import { ModalDropDown2 } from "../ModalBox/ModalDropDown";
import Modal from "../ModalBox/Modal";
import { ModalUploadFile } from "../ModalBox/ModalUploadFile";
import { ModalTextBox } from "../ModalBox/ModalTextBox";
import request, { headers } from "../../../../utils/request";
import { useQuery } from "react-query";
import { ModalEmpty } from "../ModalBox/ModalEmpty";
import { URL } from "../../../../utils/url";
import { useFormik } from "formik";
import axios from "axios";
import greetMe from "../../../../utils/greet";
import { STATUS_OPTIONS } from "../../../website/components/data";
import toast from "react-hot-toast";

export const WelcomeHost = ({ onClickupload }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalUploadOpen, setModalUploadOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [dropError, setDropError] = useState(null);
  const [event, setEvent] = useState(null);

  const openModal = () => {
    setModalUploadOpen(true);
  };

  const closeModal = () => {
    setModalUploadOpen(false);
  };

  const multipartConfig = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onGetEvent = async () => {
    try {
      const response = await axios.get(
        `${URL}event`,
        headers(localStorage.getItem("token"))
      );
      setEvent(response?.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    onGetEvent();
  }, []);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setUploadError(null);
  };
  const onSubmitUploadMenu = async () => {
    const payload = {
      upload_menu: selectedFile,
      event_id: selectedEventId,
      upload_note: formik.values.upload_note,
    };

    if (!selectedFile) {
      setUploadError("Please select a file.");
      return;
    }

    if (!selectedEventId) {
      setDropError("Please select an event.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}post-upload-data/`,
        payload,
        multipartConfig
      );
      const data = response.data;
      toast.success("menu data upload successful");
      setModalUploadOpen(false);
      setLoading(false);
    } catch (error) {
      toast.error("menu data upload failed");
      setModalUploadOpen(false);
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      upload_note: "",
    },
    onSubmit: onSubmitUploadMenu,
  });

  return (
    <>
      {isModalUploadOpen && (
        <Modal
          imageSource="images/mdt1.png"
          title="Upload Menu"
          rightIconSrc="images/mdx.svg"
          onClose={closeModal}
        >
          <img src="images/mdmenuformat.png" className="format" alt="format" />
          <ModalUploadFile
            name="upload_menu"
            onFileSelect={handleFileSelect}
            error={uploadError}
          />
          {event && event?.length === 0 ? (
            <ModalEmpty>No event found</ModalEmpty>
          ) : (
            <ModalDropDown
              options={event?.map((e, index) => ({
                label: e.event_tag,
                value: e.id,
              }))}
              defaultOption={{ label: "Select Event", value: null }}
              onSelect={(selectedOption) => {
                setSelectedEventId(selectedOption.value);
                setDropError(null); // Reset dropError state when an option is selected
              }}
              error={dropError}
            />
          )}

          <ModalTextBox
            handleTextChange={formik.handleChange}
            name="upload_note"
            value={formik.values.upload_note}
          />
          <div className="align">
            <Button2
              title="Upload"
              src="/images/uploadd.svg"
              backgroundColor={
                loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
              }
              onClick={onSubmitUploadMenu}
              alt="upload"
            />
          </div>
        </Modal>
      )}

      <Wel>
        <div className="leftdiv">
          <div className="subdiv">
            <h1 className="h1">
              Welcome to your
              <br />
              Dashboard.
            </h1>
            <div>
              <p>It’s time to create the event of your dreams</p>
            </div>
            <Button2
              title="Upload Menu"
              src="/images/uploadd.svg"
              onClick={openModal}
            />
          </div>

          <div className="middiv bouncing-element">
            <img src="/images/format.png" className="img" alt="format-icon" />
          </div>
        </div>

        <div className="rightdiv bouncing-element">
          <img
            src="/images/dash2.png"
            className="img"
            alt="illustration-icon"
          />
        </div>
      </Wel>
    </>
  );
};

export const WelcomeAdmin = ({ onClickupload }) => {
  const [loading, setLoading] = useState(false);
  const [dropError, setDropError] = useState(null);
  const [event, setEvent] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [user, setUser] = useState([]);
  const [isModalUpdateRequestOpen, setModalUpdateRequestOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const openModalActivationEvent = () => {
    setModalUpdateRequestOpen(true);
  };

  const closeModalActivationEvent = () => {
    setModalUpdateRequestOpen(false);
  };

  const onGetEvent = async () => {
    try {
      const response = await axios.get(
        `${URL}superadmin/event/get/`,
        headers(localStorage.getItem("token"))
      );
      setEvent(response?.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    } else {
      mapData();
    }
  }, []);

  const mapData = async () => {
    const response = await axios.get(
      `${URL}superadmin/user/get/`,
      headers(localStorage.getItem("token"))
    );
    if (response?.data?.length) {
      const st = response?.data?.map((e) => ({
        label: e.email,
        value: e.id,
      }));

      setUser(st);
    } else {
      setUser([]);
    }
  };
  useEffect(() => {
    onGetEvent();
  }, []);

  const { data: userdata, refetch: userrefetch } = useQuery(
    "user",
    () => {
      return request.get("/user/", headers(localStorage.getItem("token")));
    },
    {
      enabled: true,
    }
  );

  const intialvaluesUpdateRequestActivation = {
    activation_status: "",
  };

  const fetchAllRequest = () => {
    let url = `all-request-activation-no-pg`;
    return request.get(url, headers(localStorage.getItem("token")));
  };

  const { data: activationrequestdata, refetch } = useQuery(
    ["activationrequest"],
    () => fetchAllRequest(),
    {
      enabled: true,
    }
  );

  const onSubmitActivationRequestUpdate = async (values, { resetForm }) => {
    if (!requestId || !selectedOption) {
      return;
    }
    values.activation_status = selectedOption;
    console.log(values, requestId);
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}request-activation-update/${requestId}/`,
        values,
        headers(localStorage.getItem("token"))
      );

      toast.success("status updated successfully!");
      resetForm();
      refetch();
      setLoading(false);
      setModalUpdateRequestOpen(false);
    } catch (error) {
      toast.error("activation request update failed");
      setLoading(false);
      setModalUpdateRequestOpen(false);
    }
  };

  const formikUpdateRequestActivation = useFormik({
    initialValues: intialvaluesUpdateRequestActivation,
    onSubmit: onSubmitActivationRequestUpdate,
  });

  return (
    <>
      {isModalUpdateRequestOpen && (
        <form onSubmit={formikUpdateRequestActivation.handleSubmit}>
          <Modal
            title="Treat Request"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalActivationEvent}
          >
            <div className="wrapper-div">
              <ModalDropDown2
                options={activationrequestdata?.data?.map((e, index) => ({
                  label: `event with id ${e.event}`,
                  value: e.id,
                }))}
                defaultOption={{ label: "Select Event", value: null }}
                onSelect={(selectedOption) => {
                  setRequestId(selectedOption.value);
                  setDropError(null);
                }}
                error={dropError}
                placeholder="Search by id...."
              />

              <ModalDropDown
                options={STATUS_OPTIONS?.map((e, index) => ({
                  label: e.label,
                  value: e.value,
                }))}
                error={dropError}
                defaultOption={{ label: "Select an option", value: null }}
                onSelect={(selectedOption) => {
                  setSelectedOption(selectedOption.value);
                  setDropError(null);
                }}
                // error={dropError}
              />
            </div>
            <div className="align mt-3">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      <Wel>
        <div className="leftdiv">
          <div className="subdiv">
            <h1 className="h1">
              {greetMe()}
              <br />
              {userdata?.data?.firstname + " " + userdata?.data?.lastname}
            </h1>
            <div>
              <p>Welcome to your Admin dashboard</p>
            </div>

            <Button2
              title="Approve Event"
              backgroundColor="rgba(0, 0, 0, 1)"
              // src="/images/addd.svg"
              src="/images/mdgreen.svg"
              onClick={openModalActivationEvent}
            />
          </div>

          <div className="middiv bouncing-element">
            <img src="/images/dash1.png" className="img" alt="icon" />
          </div>
        </div>

        <div className="rightdiv bouncing-element">
          <img src="/images/dash2.png" className="img" alt="icon" />
        </div>
      </Wel>
    </>
  );
};

const Wel = styled.div`
  width: 95%;
  margin: 20px;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  h1 {
    font-family: "MatterMedium", sans-serif;
    font-size: 25px;
    color: #000;
    font-style: normal;
    font-weight: 500;
  }

  p {
    color: #000;
    font-family: "MatterNormal", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .img {
    height: 250px;
    width: auto;
  }
  .leftdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    background-color: white;
    padding: 20px 30px;
    border-radius: 25px;
  }
  .rightdiv {
    width: 30%;
  }
  @media (max-width: 1200px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-right: 0px;
    }
    .leftdiv {
    }
    .rightdiv {
    }
    .img {
      height: 150px;
      width: auto;
    }
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;

    h1 {
      font-size: 23px;
    }
    p {
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .leftdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .rightdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img {
      height: 300px;
      width: auto;
      margin-top: 20px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    width: 100%;

    h1 {
      font-size: 25px;
    }
    p {
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .leftdiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .rightdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img {
      height: 230px;
      width: auto;
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 350px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    width: 100%;

    h1 {
      font-size: 25px;
    }
    p {
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .leftdiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .rightdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img {
      height: 180px;
      width: auto;
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }
`;
