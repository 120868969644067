import React, { useState, useEffect } from "react";
import { TableData } from "../../components/TableData";
import { useQuery } from "react-query";
import request from "../../../../utils/request";
import Pagination from "../../../../utils/pagination";
import { useParams } from "react-router-dom";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { URL } from "../../../../utils/url";
import { timeConverter2 } from "../../../../utils/dateTimeConverter";
import { headers } from "../../../../utils/request";
import { DashHeader2 } from "../../components/dashboardmain/Header";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
} from "../../../../utils/styles/admin/overview/triviaresultstyles";

export const AdminTriviaResult = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const { id } = useParams();
  const [questionsLength, setQuestionsLength] = useState(0);
 
  

  useEffect(() => {
   const storedQuestionsLength = localStorage.getItem("questions_length");
   if (storedQuestionsLength) {
     setQuestionsLength(Number(storedQuestionsLength));
   }
  }, []);

  const fetchTriviaData = (page, searchTerm) => {
    let url = `${URL}trivia-get/${id}/?page=${page}`;

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  
  const { data: triviadata } = useQuery(
    ["trivia", pageNumber, searchTerm],
    () => fetchTriviaData(pageNumber, searchTerm)
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  return (
    <Wrapper>
      <MainContent>
        <CenteredContent>
          <DashHeader2 admin title="Trivia" towhere={`/dashboard-admin/${id}/trivia`} />
        </CenteredContent>
        <CenteredContent>
          <Table className="mt-5">
            <TableData
              headtitle="All Trivia Results"
              onChange={handleSearch}
              placeholder="search name or score"
              trivia={
                triviadata && triviadata?.data?.results.length === 0
                  ? ""
                  : triviadata
              }
            >
              {triviadata && triviadata?.data?.results.length < 1 ? (
                <ModalEmpty title="no result found" />
              ) : (
                triviadata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="Name">{e.trivia_name}</td>
                    <td data-cell="Score">{e.trivia_score} /{questionsLength}</td>
                    <td data-cell="Time">{e.trivia_time} seconds</td>
                    {/* <td data-cell="time">{timeConverter2(e.created_at)}</td> */}
                  </tr>
                ))
              )}
            </TableData>
          </Table>
        </CenteredContent>
        <div className="pagination-container">
          {triviadata && triviadata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={triviadata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
