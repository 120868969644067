import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button1 } from "../button/Button1";
import Modal from "../ModalBox/Modal";
import { Button2 } from "../button/Button2";
import { Input } from "../../components/input/Input";
import axios from "axios";
import { URL } from "../../../../utils/url";
import { useFormik } from "formik";
import * as Yup from "yup";
import { headers } from "../../../../utils/request";
import { PassWordInput } from "../input/PasswordInput";
import toast from "react-hot-toast";

export const DashHeader = ({
  title,
  notificationOnclick,
  toeventOnboard,
  eventOnboardOnclick,
  titleButton,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalProfileOpen, setModalProfileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [isModalChangePassword, setModalChangePassword] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeModalProfile = () => {
    setModalProfileOpen(false);
  };

  const closeModalPassword = () => {
    setModalChangePassword(false);
  };

  const closeModalTimeout = () => {
    setTimeout(() => {
      setModalProfileOpen(false);
      setIsDropdownOpen(!isDropdownOpen);
    }, 1000); // 3 seconds
  };

  const openModalProfile = () => {
    setModalProfileOpen(true);
  };

  const openModalPassword = () => {
    setModalChangePassword(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${URL}user/`, headers(localStorage.getItem("token")))
      .then((response) => {
        setInitialValues(response.data);
        formikProfile.setValues(response.data);
      })
      .catch((error) => {});
  }, []);

  const updateProfilevalidationSchema = Yup.object({
    firstname: Yup.string().required("first name is required"),
    lastname: Yup.string().required("last name is required"),
    mobile_number: Yup.string()
      .required("mobile number is required")
      .min(9, "must be 9 characters or more"),
  });

  const changePasswordvalidationSchema = Yup.object({
    old_password: Yup.string().required("old password is required"),
    new_password: Yup.string()
      .required("new password is required")
      .min(8, "must be 8 characters or more"),
    confirm_password: Yup.string()
      .required("password confirmation is required")
      .min(8, "must be 8 characters or more")
      .oneOf([Yup.ref("new_password"), null], "passwords do not match"),
  });

  const initialValueUpdateProfile = {
    firstname: initialValues?.firstname || "",
    lastname: initialValues?.lastname || "",
    company: initialValues?.company || "",
    mobile_number: initialValues?.mobile_number || "",
  };

  const initialValuesChangePassword = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const onSubmitProfile = async (values) => {
    const payload = {
      firstname: values.firstname,
      lastname: values.lastname,
      company: values.company,
      mobile_number: values.mobile_number,
    };
    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}user/`,
        payload,
        headers(localStorage.getItem("token"))
      );
      toast.success("profile updated successful!");
      closeModalTimeout();
      setLoading(false);
    } catch (error) {
      toast.error("profile update failed");
      setLoading(false);
    }
  };

  const onSubmitChangePassword = async (values, { resetForm }) => {
    const payload = {
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    };
    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}change-password/`,
        payload,
        headers(localStorage.getItem("token"))
      );
      toast.success("Password changed successful");
      resetForm();
      setLoading(false);
    } catch (error) {
      toast.error("Old password is invalid");
      setLoading(false);
    }
  };

  const formikProfile = useFormik({
    initialValues: initialValueUpdateProfile,
    validationSchema: updateProfilevalidationSchema,
    onSubmit: onSubmitProfile,
  });

  const formikChangePassword = useFormik({
    initialValues: initialValuesChangePassword,
    validationSchema: changePasswordvalidationSchema,
    onSubmit: onSubmitChangePassword,
  });

  return (
    <StyledHeader>
      {isModalChangePassword && (
        <form onSubmit={formikChangePassword.handleSubmit}>
          <Modal
            imageSource="images/mdt5.png"
            title="Change Your Password"
            rightIconSrc="images/mdx.svg"
            onClose={closeModalPassword}
          >
            <div className="wrapper-div">
              <PassWordInput
                label="Old Password"
                placeholder="enter your old password"
                name="old_password"
                onChange={formikChangePassword.handleChange}
                value={formikChangePassword.values.old_password}
                error={formikChangePassword.errors.old_password}
              />
              <PassWordInput
                label="New Password"
                placeholder="enter your new password"
                name="new_password"
                onChange={formikChangePassword.handleChange}
                value={formikChangePassword.values.new_password}
                error={formikChangePassword.errors.new_password}
              />
              <PassWordInput
                label="Confirm Password"
                placeholder="enter your new password again"
                name="confirm_password"
                onChange={formikChangePassword.handleChange}
                value={formikChangePassword.values.confirm_password}
                error={formikChangePassword.errors.confirm_password}
              />
            </div>

            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="change_password"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalProfileOpen && (
        <form onSubmit={formikProfile.handleSubmit}>
          <Modal
            imageSource="images/mdt1.png"
            title="Edit Your Profile"
            rightIconSrc="images/mdx.svg"
            onClose={closeModalProfile}
          >
            <div className="wrapper-div">
              <Input
                // placeholder={initialValues?.email}
                value={initialValues?.email}
                label="Email"
                disabled
              />
              <div className="input-flex">
                <div className="wrapper-child">
                  <Input
                    // placeholder="Ajibola Oluwatobi"
                    onChange={formikProfile.handleChange}
                    value={formikProfile.values.firstname}
                    error={formikProfile.errors.firstname}
                    name="firstname"
                    label="First Name"
                  />
                </div>
                <div className="wrapper-child">
                  <Input
                    //  placeholder="Gureje"
                    label="Last Name"
                    name="lastname"
                    onChange={formikProfile.handleChange}
                    value={formikProfile.values.lastname}
                    error={formikProfile.errors.lastname}
                  />
                </div>
              </div>

              <Input
                label="Company"
                name="company"
                onChange={formikProfile.handleChange}
                value={formikProfile.values.company}
                error={formikProfile.errors.company}
              />
            </div>

            <Input
              label="Mobile Number"
              name="mobile_number"
              onChange={formikProfile.handleChange}
              value={formikProfile.values.mobile_number}
              error={formikProfile.errors.mobile_number}
            />

            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="upload"
              />
            </div>
          </Modal>
        </form>
      )}
      <div className="header-wrapper">
        <div className="header-title">
          <h1>{title}</h1>
        </div>
        <div className="action-items">
          <NavLink
            className="button"
            to={toeventOnboard}
            onClick={eventOnboardOnclick}
          >
            <Button1
              // title="Create New Event"
              title={titleButton}
              src="/images/addc.svg"
            />
          </NavLink>
          <div className="actions">
            <DropdownWrapper>
              <DropdownToggle onClick={toggleDropdown}>
                <img src="/images/settings.svg" className="settings" />
              </DropdownToggle>
              {isDropdownOpen && (
                <DropdownMenu
                  className={isDropdownOpen ? "open" : ""}
                  onMouseLeave={handleMouseLeave}
                >
                  <DropdownItem>
                    <StyledNavLink onClick={openModalProfile}>
                      edit your profile
                    </StyledNavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <StyledNavLink onClick={openModalPassword}>
                      change your password
                    </StyledNavLink>
                  </DropdownItem>
                </DropdownMenu>
              )}
            </DropdownWrapper>
            <div onClick={notificationOnclick}>
              <img
                src="/images/notification.svg"
                className="notification"
                alt="notification-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

export const DashHeader2 = ({
  title,
  settingsOnclick,
  notificationOnclick,
  towhere,
  host,
  admin,
}) => {
  return (
    <StyledHeader>
      <div className="header-wrapper">
        <NavLink to={towhere} className="header-title">
          <div>
            <img src="/images/arrow-left.svg" className="arrow" alt="arrow" />
          </div>
          <h1>{title}</h1>
        </NavLink>
        {admin && (
          <div className="action-items">
            {/* <NavLink className="button" to="/host-onboard-event-type">
    <Button1 title="Create New Event" src="/images/addc.svg" />
  </NavLink> */}
            <div className="actions">
              {/* <div onClick={settingsOnclick}>
      <img src="/images/settings.svg" className="settings" alt="settings-icon"/>
    </div> */}
              {/* <div onClick={notificationOnclick}>
                <img
                  src="/images/notification.svg"
                  className="notification"
                  alt="notification-icon"
                />
              </div> */}
            </div>
          </div>
        )}
        {host && (
          <>
            <div className="action-items">
              <NavLink className="button" to="/host-onboard-event-type">
                <Button1
                  title="Create New Event"
                  src="/images/addc.svg"
                  alt="icon"
                />
              </NavLink>
              <div className="actions">
                <div onClick={settingsOnclick}>
                  <img
                    src="/images/settings.svg"
                    className="settings"
                    alt="settings-icon"
                  />
                </div>
                <div onClick={notificationOnclick}>
                  <img
                    src="/images/notification.svg"
                    className="notification"
                    alt="notification-icon"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </StyledHeader>
  );
};

export const DashHeader3 = ({
  title,
  settingsOnclick,
  notificationOnclick,
  show,
}) => {
  return (
    <StyledHeader>
      <div className="header-wrapper">
        <div className="header-title">
          <h1>{title}</h1>
        </div>
        {show && (
          <div className="action-items">
            <div className="actions">
              <div onClick={notificationOnclick}>
                <img
                  src="/images/notification.svg"
                  className="notification"
                  alt="notification-icon"
                />
              </div>
              <div onClick={settingsOnclick}>
                <img
                  src="/images/settings.svg"
                  className="settings"
                  alt="settings-icon"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  width: 95%;

  .wrapper-div {
    margin-top: 30px;
  }

  .input-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    gap:1rem;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0rem;
    border-bottom: 1px solid #d8dcdf;
    & .header-title {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      cursor: pointer;
      & .arrow {
        margin-top: 6px;
        width: auto;
        height: 30px;
      }
    }
  }

  .action-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
  }

  .stroke {
    background-color: #d8dcdf;
    height: 20px;
    width: 1px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    background: none;
  }

  .notification {
      opacity:0.2;
    }
  h1 {
    font-family: "MatterMedium", sans-serif;
    font-size: 22px;
    color: #000;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media (max-width: 1024px) {
    .input-flex {
      display: flex;
      flex-direction: column;
    }
    & .wrapper-child {
      width: 100%;
    }
  }
  @media (max-width: 640px) {
    h1 {
      font-size: 23px;
    }
    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .action-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 0rem;
    }

    .action-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
    }
    .settings {
      height: 3rem;
      width: auto;
    }

    .notification {
      height: 3rem;
      width: auto;

    }
  }

  @media (max-width: 480px) {
    .input-flex {
      display: flex;
      flex-direction: column;
      & .wrapper-child {
        width: 100%;
      }
    }

    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 1rem;
      border-bottom: 1px solid #d8dcdf;
    }

    .action-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 0rem;
    }

    .actions {
      display: flex;
      justify-content: space-around;
      gap: 3rem;
      margin-top: 20px;
    }
    h3 {
      font-size: 15px;
    }

    h1 {
      font-size: px;
    }

    .settings {
      height: 3.2rem;
      width: auto;
    }

    .notification {
      height: 3.2rem;
      width: auto;
    }
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 50px;
  width: 200px;
  right: -3px;
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  display: none;

  &.open {
    display: block;
  }

  @media (max-width: 912px) {
    width: 270px;
  }
  @media (max-width: 640px) {
    width: 250px;
  }
  @media (max-width: 480px) {
    top: 65px;
    left: -50px;
    width: 240px;
  }

  @media (max-width: 320px) {
    width: 180px;
  }
`;

const DropdownToggle = styled.span`
  cursor: pointer;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
  color: rgba(69, 90, 100, 1);
  text-decoration: none;
  font-family: "MatterNormal", sans-serif;
  font-size: 14px;

  &:hover {
    color: rgba(255, 92, 0, 1);
  }
  @media (max-width: 480px) {
    font-size: 17px;
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    list-style: none;
    padding: 10px;
    margin: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #fff;
    display: none;

    &.open {
      display: block;
    }

    @media (max-width: 912px) {
      width: 270px;
    }
    @media (max-width: 640px) {
      width: 250px;
    }
    @media (max-width: 480px) {
      width: 250px;
    }

    @media (max-width: 320px) {
      width: 180px;
    }
  }
`;
