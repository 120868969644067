import React from "react";
import { Toaster } from "react-hot-toast";

const Toast = ({ children }) => {

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            background: "black",
            color: "#fff",
            fontSize: "1rem",
            fontFamily: "MatterNormal, sans-serif",
          },
        }}
      />
      {children}
    </>
  );
};

export default Toast;
