import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import { addDays } from "date-fns";
import * as Yup from "yup";


const tomorrow = addDays(new Date(), 1);
const defaultTime = new Date();
const formatTime = (date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

const useAdminOverview = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalMenuUploadedOpen, setModalMenuUploadedOpen] = useState(false);
    const [isModalEditEventOpen, setModalEditEventOpen] = useState(false);
    const [isModalDeleteEventOpen, setModalDeleteEventOpen] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [Error, setError] = useState(null);
    const [dropError, setDropError] = useState(null);
    const [uploads, setUploads] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [selectedDate, setSelectedDate] = useState(tomorrow);
    const [selectedTime, setSelectedTime] = useState(defaultTime);
    const [backgroundImage, setBackgroundImage] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [user, setUser] = useState([]);
    const [useruuid, setUseruuid] = useState([]);
    const [profile, setProfile] = useState([]);

    console.log(profile,"details")

    const [isModalProfileOpen, setModalProfileOpen] = useState(false);
    const [isModalCreateEventBehalfOpen, setModalCreateEventBehalfOpen] =
      useState(false);
  
    useEffect(() => {
      if (useruuid) {
        axios
          .get(
            `${URL}superadmin/user/${useruuid}/rud/`,
            headers(localStorage.getItem("token"))
          )
          .then((response) => {
            setProfile(response.data);
          })
          .catch((error) => {
            // console.log(error.response);
          });
      }
      if (selectedEventId) {
        axios
          .get(
            `${URL}superadmin/event/${selectedEventId}/`,
            headers(localStorage.getItem("token"))
          )
          .then((response) => {
            setInitialValues(response.data);
            setSelectedDate(new Date(response.data.event_date));
            const parsedTime = new Date(`2000-01-01T${response.data.event_time}`);
            setSelectedTime(parsedTime);
            formikEventUpdate.setValues(response.data);
            setBackgroundImage(response.data.even_background);
          })
          .catch((error) => {
            // console.log(error.response);
          });
      }
    }, [selectedEventId,useruuid]);
  
    const initialValueEventUpdate = {
      street_address: initialValues?.street_address || "",
      state: initialValues?.state || "",
      city: initialValues?.city || "",
      country: initialValues?.country || "",
      event_guest: initialValues?.event_guest || "",
      event_type: initialValues?.event_type || "",
      event_tag: initialValues?.event_tag || "",
      event_toast: initialValues?.event_toast || "",
      event_code: initialValues?.event_code || "",
    };
  
    const validationSchema = Yup.object({
      street_address: Yup.string().required("Street Address is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string().required("Country is required"),
      event_guest: Yup.string().required("Event Guest is required"),
      event_type: Yup.mixed().required("Event Type is required"),
      event_tag: Yup.string().required("Event Tag is required"),
      event_toast: Yup.string().required("Event Toast is required"),
    });
  
    const multipartConfigs = {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };
  
    const onSubmiEventUpdate = async (values) => {
      const payload = {
        street_address: values?.street_address,
        state: values?.state,
        city: values?.city,
        country: values?.country,
        event_date: selectedDate.toISOString().split("T")[0],
        event_time: formatTime(selectedTime), 
        event_guest: values?.event_guest,
        event_tag: values?.event_tag,
        event_toast: values?.event_toast,
        event_type: values?.event_type,
        event_code: values?.event_code, 
        even_background: selectedFile,
      };
  
      setLoading(true);
      try {
        const response = await axios.patch(
          `${URL}superadmin/event/${selectedEventId}/`,
          payload,
          multipartConfigs
        );
  
        toast.success("event updated successfully !!");
        setLoading(false);
        setModalEditEventOpen(false);
        refetch();
      } catch (error) {
        console.error(error);
        toast.error("event update failed");
        setLoading(false);
      }
    };
  
    const formikEventUpdate = useFormik({
      initialValues: initialValueEventUpdate,
      validationSchema,
      onSubmit: onSubmiEventUpdate,
    });
    const openModalProfile = () => {
      setModalProfileOpen(true);
    };

    const openModalEditEvent = () => {
      setModalEditEventOpen(true);
    };
    const openModalMenuUploaded = () => {
      setModalMenuUploadedOpen(true);
    };
  
    const openModalEventBehalf = () => {
      setModalCreateEventBehalfOpen(true);
    };
  
    const closeModalEventBehalf = () => {
      setModalCreateEventBehalfOpen(false);
    };
  
    const openModalEventDelete = () => {
      setModalDeleteEventOpen(true);
    };
  
    const closeModalEventDelete = () => {
      setModalDeleteEventOpen(false);
    };
    const closeModalMenuUploaded = () => {
      setModalMenuUploadedOpen(false);
    };
  
    const closeModalEditEvent = () => {
      setModalEditEventOpen(false);
    };
 const closeModalProfile = () => {
    setModalProfileOpen(false);
  };

    const fetchEvent = (page, searchTerm) => {
      let url = `/superadmin/event/get/?page=${page}`;
      if (searchTerm) {
        url += `&search=${searchTerm}`;
      }
  
      return request.get(url, headers(localStorage.getItem("token")));
    };


    const {
      data: eventdata,
      isLoading: eventloading,
        error: eventerror,
      refetch,
    } = useQuery(["adminevent", pageNumber, searchTerm], () =>
      fetchEvent(pageNumber, searchTerm)
    );
  
    // console.log(eventdata.questions);
    const {
      data: approvedeventddata,
    } = useQuery(
      "approvedevent",
      () => {
        return request.get(
          "superadmin/approved-event/get/-no-pg/",
          headers(localStorage.getItem("token"))
        );
      },
      {
        enabled: true,
      }
    );
  
    const { data: ongoingeventddata } = useQuery(
      "ongoing-event",
      () => {
        return request.get(
          "superadmin/ongoing-event/get/-no-pg/",
          headers(localStorage.getItem("token"))
        );
      },
      {
        enabled: true,
      }
    );
  
    const { data: activationrequestddata } = useQuery(
      "activationrequest",
      () => {
        return request.get(
          "all-request-activation-no-pg",
          headers(localStorage.getItem("token"))
        );
      },
      {
        enabled: true,
      }
    );
  
    const handleSearch = (event) => {
      const searchTerm = event.target.value;
      setSearchTerm(searchTerm);
      setPageNumber(1);
    };
  
    const handleDropdownToggle = (index, eventId) => {
      setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
      setSelectedEventId(eventId);
    };
  
    const removeFromEvent = async () => {
      try {
        const response = await axios.delete(
          `${URL}superadmin/event/${selectedEventId}/`,
          headers(localStorage.getItem("token"))
        );
        const datas = response.data;
        toast.success("Event deleted successfully!");
        setModalDeleteEventOpen(false);
        refetch();
      } catch (error) {
        toast.error("Event delete failed");
      }
    };
  
    const handleFileSelect = (file) => {
      setSelectedFile(file);
      setError(null);
    };
  
    const onSubmitUploadedMenus = async () => {
      try {
        const response = await axios.get(
          `${URL}uploads/${selectedEventId}/`,
          headers(localStorage.getItem("token"))
        );
        setUploads(response?.data.data);
      } catch (error) {}
    };
  
    useEffect(() => {
      if (selectedEventId) {
        onSubmitUploadedMenus();
      }
    }, [selectedEventId]);
  
    const handleDateSelect = (date) => {
      setSelectedDate(date);
      setError(null);
    };
  
    const handleTimeSelect = (time) => {
      setSelectedTime(time);
      setError(null);
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };
  
    useEffect(() => {
      if (!user) {
        return;
      } else {
        mapData();
      }
    }, []);
  
    const mapData = async () => {
      const response = await axios.get(
        `${URL}superadmin/user/get/`,
        headers(localStorage.getItem("token"))
      );
      if (response?.data?.length) {
        const st = response?.data?.map((e) => ({
          label: e.email,
          value: e.id,
        }));
  
        setUser(st);
      } else {
        setUser([]);
      }
    };
  
    const intialvaluesEventBehalf = {
      user_id: "",
      street_address: "",
      state: "",
      city: "",
      country: "",
      event_date: "",
      event_time: "",
      event_guest: "",
      event_type: "",
      event_tag: "",
      event_toast: "",
      even_background: null,
    };
  
    const validationEventBehalfSchema = Yup.object({
      street_address: Yup.string().required("street address is required"),
      state: Yup.string().required("state is required"),
      city: Yup.string().required("city is required"),
      country: Yup.string().required("country is required"),
      event_guest: Yup.string().required("event guest is required"),
      event_type: Yup.mixed().required("event type is required"),
      event_tag: Yup.string().required("event tag is required"),
      event_toast: Yup.string().required("event toast is required"),
    });
  
    const onSubmitCreateEventBehalf = async (values, { resetForm }) => {
      values.user_id = selectedUser;
      values.event_date = selectedDate.toISOString().split("T")[0];
      values.event_time = selectedTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      values.even_background = selectedFile;
      setLoading(true);
      try {
        const response = await axios.post(
          `${URL}event/`,
          values,
          multipartConfigs,
          headers(localStorage.getItem("token"))
        );
        toast.success("event behalf created successfully!");
        resetForm();
        setModalCreateEventBehalfOpen(false);
        refetch();
        setLoading(false);
      } catch (error) {
        toast.error("event behalf creation failed");
        setLoading(false);
        setModalCreateEventBehalfOpen(false);
      }
    };
  
    const formikCreateEventBehalf = useFormik({
      initialValues: intialvaluesEventBehalf,
      validationSchema: validationEventBehalfSchema,
      onSubmit: onSubmitCreateEventBehalf,
    });
  

  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    openDropdownIndex,
    setOpenDropdownIndex,
    setUseruuid,
    loading,
    selectedFile,
    isModalMenuUploadedOpen,
    isModalEditEventOpen,
    isModalDeleteEventOpen,
    isModalProfileOpen,
    selectedEventId,
    Error,
    dropError,
    uploads,
    initialValues,
    selectedDate,
    selectedTime,
    backgroundImage,
    selectedUser,
    user,
    isModalCreateEventBehalfOpen,
    eventloading,
    eventerror,
    eventdata, 
    profile,
    approvedeventddata,
    ongoingeventddata,
    activationrequestddata,
    formikEventUpdate,
    formikCreateEventBehalf,
    setDropError,
    setSelectedUser,
    setSelectedEventId,
    fetchEvent,
    handleSearch,
    handleDropdownToggle,
    removeFromEvent,
    handleFileSelect,
    handleDateSelect,
    handleTimeSelect,
    handleFileChange,
    onSubmitUploadedMenus,
    openModalProfile,
    openModalEditEvent,
    openModalMenuUploaded,
    openModalEventBehalf,
    closeModalEventBehalf,
    openModalEventDelete,
    closeModalEventDelete,
    closeModalMenuUploaded,
    closeModalEditEvent,
    closeModalProfile
    
  };
};

export default useAdminOverview;
