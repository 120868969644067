import React from "react";
import { TableData } from "../../components/TableData";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { dateConverter } from "../../../../utils/dateTimeConverter";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledLink,
  StyledNavLink,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/extrastyles";
import StatusHandler from "../../../../utils/statusHandler";
import useAdminExtras from "../../../../utils/apilogics/admin/overview/extraLogic";
export const AdminMenuExtras = () => {
  const {
    openDropdownIndex,
    loading,
    isModalEditMenuextraOpen,
    isModalCreateMenuextraOpen,
    isModalDeleteMenuextraOpen,
    Error,
    id1,
    id2,
    catid,
    formikUpdateMenuextra,
    menuextradata,
    menuextraloading,
    menuextraerror,
    refetch,
    setMenuExtraid,
    removeFromMenuExtra,
    openModalEditMenuextra,
    openModalDeleteMenuextra,
    openModalCreateMenuextra,
    closeModalEditMenuextra,
    closeModalDeletemenuextra,
    closeModalCreateMenuextra,
    handleDropdownToggle,
    setOpenDropdownIndex,
    formikMenuextraCreate,
  } = useAdminExtras();

  return (
    <Wrapper>
      {isModalEditMenuextraOpen && (
        <form onSubmit={formikUpdateMenuextra.handleSubmit}>
          <Modal
            title="Edit Menu Extra"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditMenuextra}
          >
            <div className="mt-4">
              <Input
                label="Extra Name"
                type="text"
                name="extra"
                onChange={formikUpdateMenuextra.handleChange}
                value={formikUpdateMenuextra.values.extra}
                error={formikUpdateMenuextra.errors.extra}
              />
            </div>
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteMenuextraOpen && (
        <Modal
          title="Delete Menu Extra"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeletemenuextra}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="delete"
              onClick={() => removeFromMenuExtra(catid)}
            />
          </div>
        </Modal>
      )}
      {isModalCreateMenuextraOpen && (
        <form onSubmit={formikMenuextraCreate.handleSubmit}>
          <Modal
            title="Create Menu Extra"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateMenuextra}
          >
            <div className="mt-4">
              <Input
                label="Extra Name"
                type="text"
                name="extra"
                onChange={formikMenuextraCreate.handleChange}
                value={formikMenuextraCreate.values.extra}
                error={formikMenuextraCreate.errors.extra}
                placeholder="enter extra name"
              />
            </div>
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick={`/dashboard-admin/${id1}/menu`}
            show
            title="create menu extra"
            onClick={openModalCreateMenuextra}
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Menu Extras"
            showInput
            {...(!menuextraloading && {
              menuextra:
                menuextradata && menuextradata?.data?.length === 0
                  ? ""
                  : menuextradata,
            })}
          >
            <StatusHandler
              isError={menuextraerror}
              isLoading={menuextraloading}
            >
              {menuextradata && menuextradata?.data?.length < 1 ? (
                <ModalEmpty title="no menu extras found" />
              ) : (
                menuextradata?.data?.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="extra">{e.extra}</td>
                    <td data-cell="menu name">{e.menu_name}</td>
                    <td data-cell="created">{dateConverter(e.created_at)}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id1)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setMenuExtraid(e.id);
                                openModalEditMenuextra();
                              }}
                              className="nav-link"
                            >
                              edit menu extra
                            </StyledNavLink>
                          </DropdownItem>

                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setMenuExtraid(e.id);
                                openModalDeleteMenuextra();
                              }}
                              className="nav-link"
                            >
                              delete menu extra
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
      </MainContent>
    </Wrapper>
  );
};
