import React from "react";

function ArrowOpen({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 11 8"
    >
      <path
        fill="#FF7300"
        stroke="#FF7300"
        strokeWidth="0.5"
        d="M5.176 5.132l3.657-3.657a.739.739 0 111.063 1.026l-.018.018-4.18 4.18a.739.739 0 01-1.044 0L.474 2.52a.739.739 0 111.028-1.063l.018.018 3.656 3.657z"
      ></path>
    </svg>
  );
}

export default ArrowOpen;
