import React, { useState, useEffect, useContext } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import {
  MdRestaurant,
  MdOutlineShoppingCart,
  MdOutlineCall,
  MdOutlineCampaign,
  MdGames
} from "react-icons/md";
import { useParams } from "react-router-dom";
import "../components/css/bottom.css";
import "../../../App.css";
import styled from "styled-components";
import { ContextStore } from "../../../context/contextStore";
import axios from "axios";
import { URL } from "../../../utils/url";

const CartCount = styled.div`
  font-family: "MatterMedium", sans-serif;
  background-color: #ff5c00;
  color: white;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -48px;
  right: -20px;
`;

const BottomTabLabel = styled.div`
  font-family: "MatterMedium", sans-serif;
  font-size: 15px;
  color: black;
  margin-top: 8px;
  &.active {
    color: #ff5c00;
  }
`;

const BouncingElement = styled.div`
  &.bouncing-element {
    animation: bounce 3s infinite;
  }
  @keyframes bounce {
    0%, 20%, 50%, 100% {
      transform: translateY(-5px);
    }
    10% {
      transform: translateY(5px);
    }
    40% {
      transform: translateY(-5px);
    }
  }
`;

export function BottomNav() {
  const { token } = useParams();
  const { cartInfo } = useContext(ContextStore);
  const [eventId, setEventId] = useState("");
  const [bottomLabel, setBottomLabel] = useState("");
  const [adsActive, setAdsActive] = useState(false);
  const [triviaActive, setTriviaActive] = useState(false);
  const [opticalActive, setOpticalActive] = useState(false);
  
  const cartCount = cartInfo ? cartInfo.length : 0;

  useEffect(() => {
    const storedEventId = localStorage.getItem("eventId");
    if (storedEventId) {
      setEventId(storedEventId);
    }

    const fetchEventStatus = async () => {
      try {
        const response = await axios.get(`${URL}trivia/get-event/${eventId}`);
        const { data } = response;
        const { trivia_active, ads_active, optical_active } = data;
        
        setAdsActive(ads_active);
        setTriviaActive(trivia_active);
        setOpticalActive(optical_active);
        
        if (ads_active) {
          setBottomLabel("Ads");
        } else if (trivia_active || optical_active) {
          setBottomLabel("Trivia");
        }
      } catch (error) {
        console.error("Error fetching event status:", error);
      }
    };

    if (eventId) {
      fetchEventStatus();
    }
  }, [eventId]);

  const getBottomIcon = () => {
    if (adsActive) {
      return <MdOutlineCampaign size={22} />;
    }
    if (triviaActive || opticalActive) {
      return <MdGames size={22} />;
    }
    return null;
  };

  return (
    <nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
      <Nav className="w-100 navv">
        <div className="d-flex flex-row justify-content-around w-100">
          <NavItem>
            <NavLink
              to={`/${token}/food-menu`}
              className="nav-link bottom-nav-link"
              activeClassName="active"
            >
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "-5px" }}>
                <BouncingElement className={window.location.pathname === `/${token}/food-menu` ? "bouncing-element" : ""}>
                  <MdRestaurant size={22} />
                </BouncingElement>
                <BottomTabLabel className={window.location.pathname === `/${token}/food-menu` ? "bottom-tab-label active" : "bottom-tab-label"}>Menu</BottomTabLabel>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={`/${token}/food-cart`}
              className="nav-link bottom-nav-link"
              activeClassName="active"
            >
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "-5px" }}>
                <BouncingElement className={window.location.pathname === `/${token}/food-cart` ? "bouncing-element" : ""}>
                  <MdOutlineShoppingCart size={22} />
                </BouncingElement>
                <BottomTabLabel className={window.location.pathname === `/${token}/food-cart` ? "bottom-tab-label active" : "bottom-tab-label"}>Cart</BottomTabLabel>
                {cartCount > 0 && <CartCount>{cartCount}</CartCount>}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={`/${token}/call-waiter`}
              className="nav-link bottom-nav-link"
              activeClassName="active"
            >
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "-5px" }}>
                <BouncingElement className={window.location.pathname === `/${token}/call-waiter` ? "bouncing-element" : ""}>
                  <MdOutlineCall size={22} />
                </BouncingElement>
                <BottomTabLabel className={window.location.pathname === `/${token}/call-waiter` ? "bottom-tab-label active" : "bottom-tab-label"}>Waiter</BottomTabLabel>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={`/${token}/ads-trivia`}
              className="nav-link bottom-nav-link"
              activeClassName="active"
            >
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "-5px" }}>
                <BouncingElement className={window.location.pathname === `/${token}/ads-trivia` ? "bouncing-element" : ""}>
                  {getBottomIcon()}
                </BouncingElement>
                <BottomTabLabel className={window.location.pathname === `/${token}/ads-trivia` ? "bottom-tab-label active" : "bottom-tab-label"}>{bottomLabel}</BottomTabLabel>
              </div>
            </NavLink>
          </NavItem>
        </div>
      </Nav>
    </nav>
  );
}
