import React from "react";
import styled from "styled-components";
import { useParams,useNavigate } from "react-router-dom";
import {MdKeyboardBackspace } from 'react-icons/md';

export default function FoodEmpty({ alt, isDarkMode }) {
  const navigate = useNavigate();
  const { token } = useParams();

  const goBack = () => {
    navigate(`/${token}/food-menu`);
  };

  return (
    <Parent isDarkMode={isDarkMode}>
      <div className="parent">
      <div  onClick={goBack}>
      <MdKeyboardBackspace size={30} color={isDarkMode?"white":"#383838"}className="arrow"/>
        {/* <img src="/images/backarrow.png" alt="arrow" className="arrow"/> */}
      </div>
        <div className="bouncing-element">
          <img src="/images/emptycart.png" alt={alt} className="emptycart" />
        </div>
        <div>
          <h1>Cart Empty</h1>
        </div>
        <div>
          <p className="ptag">
            Looks like you haven’t added
            <br/>
             any food item. Please Check
          </p>
        </div>
      </div>
    </Parent>
  );
}

const Parent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};

  .parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
  }

 

  @media screen and (max-width: 820px) {
    .emptycart {
      width: auto;
      height: 18rem;
      border: ${(props) => (props.isDarkMode ? "1px solid white" : "none")};
      border-radius: 50%;
    }
    .arrow {
        /* width:40px;
        height:auto; */
        position: absolute;
        left: 30px;
        top: 27px;
  }

    h1 {
      color: ${(props) => (props.isDarkMode ? "white" : "#1d1d1d")};
      text-align: center;
      font-size: 45px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-family: "MatterMedium", sans-serif;

    }

    .ptag {
      color: #969696;
      text-align: center;
      font-family: "MatterNormal", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      /* padding: 10px 250px; */
    }
  }

  @media screen and (max-width: 640px) {
    .emptycart {
      width: auto;
      height: 12rem;
      border: ${(props) => (props.isDarkMode ? "2px solid white" : "none")};

    }
    .arrow {
        /* width:25px;
        height:auto; */
        position: absolute;
        left: 30px;
        top: 26px;
  }
    h1 {
      font-size: 37px;
    }
    .ptag {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 640px) {
    .emptycart {
      width: auto;
      height: 200px;
      border: ${(props) => (props.isDarkMode ? "2px solid white" : "none")};
    }
    .arrow {
        /* width:20px;
        height:auto; */
        position: absolute;
        left: 30px;
        top: 27px;
  }
    h1 {
      font-size: 30px;
    }
    .ptag {
      font-size: 17px;
      /* padding: 5px 90px; */
    }
  }
`;
