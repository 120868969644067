import React from "react";
import styled from "styled-components";
import { Butto } from "../Butt";
import { NavLink } from "react-router-dom";

export const DashHeader2 = ({ createOnclick, src,backOnclick, title, show,onClick,menuOnclick,vendorOnclick,othersOnclick,reasonOnclick,menu,others,vendor,reason,titleMenu,titleOthers }) => {
  return (
    <StyledHeader>
      <div className="header-wrapper">
        <NavLink className="header-title" to={backOnclick}>
          <div>
            <h1>Back</h1>
          </div>
          <div>
            <img 
            src="/images/arrow-left.svg"
             className="arrow" />
          </div>
        </NavLink>
        <div className="action-items">
            {menu && (
            <NavLink className="button" to={menuOnclick}>
              <Butto title={titleMenu} 
              // src="/images/adds.svg" 
              src={src}
              onClick={onClick} />
            </NavLink>
          )}
              
              {others && (
            <NavLink className="button" to={othersOnclick}>
              <Butto title={titleOthers} src="/images/arrow-right.svg" onClick={onClick} />
            </NavLink>
          )}
               {vendor && (
            <NavLink className="button" to={vendorOnclick}>
              <Butto title={title} src="/images/adds.svg" onClick={onClick} />
            </NavLink>
          )}
               {reason && (
            <NavLink className="button" to={reasonOnclick}>
              <Butto title={title} src="/images/adds.svg" onClick={onClick} />
            </NavLink>
          )}
          {show && (
            <NavLink className="button" to={createOnclick}>
              <Butto title={title} src="/images/adds.svg" onClick={onClick} />
            </NavLink>
          )}
        </div>
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  width: 95%;
  .header-title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    /* justify-content: center; */
  }
  .arrow {
    margin-top: 5px;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 1rem;
    border-bottom: 1px solid #d8dcdf;
  }
  .action-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .button {
    /* border-right: 1px solid #d8dcdf; */
    /* padding: 5px; */
  }

  .stroke {
    background-color: #d8dcdf;
    height: 20px;
    width: 1px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  h1 {
    font-family: "GilroyMedium", sans-serif;
    font-size: 24px;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 1024px) and (max-height: 600px) {
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
  }

  @media (max-width: 912px) {
  }

  @media (max-width: 640px) {
    .action-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
    h3 {
      font-size: 13px;
    }

    h1 {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 480px) {
    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 1rem;
      border-bottom: 1px solid #d8dcdf;
    }
    .action-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }

    .stroke {
      background-color: #d8dcdf;
      height: 20px;
      width: 1px;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2.2rem;
    }
    h3 {
      font-size: 15px;
    }

    h1 {
      font-size: 24px;
    }
    .stroke {
      display: none;
    }

    .settings {
      height: 1.8rem;
      width: auto;
    }
    .notification {
      height: 1.8rem;
      width: auto;
    }
  }
`;
