import React from "react";
import styled from "styled-components";

export const Butto = ({ title, onClick, src, alt }) => {
  return (
    <Button
    // className="Xaxis"
      style={{
        border: "none",
        color: "white",
      }}
      onClick={onClick}
    >
         <div>
      {title}
      </div>
      <div>
      <img src={src} alt={alt}/>
      </div>
    </Button>
  );
};

const Button = styled.button`
background-color:  #2C2C2C;
background-color: #FF5C00;
 font-family: 'GilroySemiBold', sans-serif;
  border-radius: 12px;
  height: 40px;
padding: 0px 20px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 912px) {
    height: 40px;
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    height: 40px;
    font-size: 12px;
padding: 0px 15px;
margin-top:10px;

  }
  
  @media screen and (max-width: 480px) {
    height: 40px;
    font-size: 14px;
padding: 0px 20px;
margin-top:10px;
  }
`;
