import React, { useState, useContext } from "react";
import styled from "styled-components";
import { PrimaryButton2 } from "../../../../website/components/Button";
import ProgressBar2 from "../../../components/progressbar/Progress2";
import { Input } from "../../../components/input/Input";
import { DatePick } from "../../../components/input/DateTime";
import { Time } from "../../../components/input/DateTime";
import { NavLink, useNavigate } from "react-router-dom";
import { ContextStore } from "../../../../../context/contextStore";
import * as Yup from "yup";
import { useFormik } from "formik";

export const OnboardEventInfo = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const {
    setEventToast,
    setEventTag,
    setEventNumber,
    setEventCountry,
    setEventState,
    setEventCity,
    setEventAddress,
    setEventDate,
    setEventTime,
  } = useContext(ContextStore);


  const intialvaluesCreateEvent = {
    street_address: "",
    state: "",
    city: "",
    country: "",
    event_guest: "",
    event_tag: "",
    event_toast: "",
  };

  
  const validationCreateEventSchema = Yup.object({
    street_address: Yup.string().required("street address is required"),
    state: Yup.string().required("state is required"),
    city: Yup.string().required("city is required"),
    country: Yup.string().required("country is required"),
    event_guest: Yup.string().required("event guest is required"),
    event_tag: Yup.string().required("event tag is required"),
    event_toast: Yup.string().required("event toast is required"),
  });

  const onSubmitCreateEvent = (values) => {
    setEventToast(values.event_toast);
    setEventTag(values.event_tag);
    setEventNumber(values.event_guest);
    setEventAddress(values.street_address);
    setEventCountry(values.country);
    setEventState(values.state);
    setEventCity(values.city);
    setEventDate(selectedDate);
    setEventTime(selectedTime);

    navigate("/host-onboard-event-media");
  };
  const formikCreateEvent = useFormik({
    initialValues: intialvaluesCreateEvent,
    validationSchema: validationCreateEventSchema,
    onSubmit: onSubmitCreateEvent,
  });

  return (
    <StyledOnboardEventInfo>
      <form onSubmit={formikCreateEvent.handleSubmit}>
        <div className="proskip">
          <ProgressBar2 start={40} end={60} />
        </div>
        <NavLink id="curs" exact={true} to="/host-onboard-event-type">
          <img src="/images/backk.svg" className="backk" alt="back" />
        </NavLink>
        <div className="div1">
          <div className="leftdiv">
            <div className="textdiv">
              <div className="headerdiv">
                <h1>Event Info</h1>
              </div>
              <div className="subdiv">
                <div>
                  <p>
                    Enter more information about your event. Personalize event
                    to your unique taste.
                  </p>
                </div>
                <div>
                  <img src="/images/magicpen.svg" className="magic-pen" alt="magic-pen"/>
                </div>
              </div>
            </div>
            <div className="wrapper-event-info">
              <Input
                placeholder="Example: ToForever"
                label="Toast"
                type="text"
                name="event_toast"
                onChange={formikCreateEvent.handleChange}
                value={formikCreateEvent.values.event_toast}
                error={formikCreateEvent.errors.event_toast}
              />
              <Input
                placeholder="Example: #TOCHI2023"
                label="Tag"
                type="text"
                name="event_tag"
                onChange={formikCreateEvent.handleChange}
                value={formikCreateEvent.values.event_tag}
                error={formikCreateEvent.errors.event_tag}
              />
              <Input
                placeholder="Example: 300"
                label="Guest Number"
                type="number"
                name="event_guest"
                onChange={formikCreateEvent.handleChange}
                value={formikCreateEvent.values.event_guest}
                error={formikCreateEvent.errors.event_guest}
              />
              <div className="wrapper"></div>
            </div>
          </div>
          <div className="rightdiv">
            <img src="/images/info.png" className="onboard" alt="info" />
          </div>
        </div>
        <div className="div1">
          <div className="leftdiv">
            <div className="textdiv">
              <div className="headerdiv">
                <h1>Event Date & Time</h1>
              </div>
              <div className="subdiv">
                <div>
                  <p>
                    Enter more information about your event. Personalize event
                    to your unique taste.
                  </p>
                </div>
                <div>
                  <img src="/images/magicpen.svg" alt="magic-pen" />
                </div>
              </div>
            </div>
            <div className="wrapper-event-info">
              <div className="wrapper-flex">
                <div className="wrapper-child">
                  <DatePick
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                  />
                </div>
                <div className="wrapper-child">
                  <Time
                    selectedTime={selectedTime}
                    handleTimeChange={handleTimeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div1">
          <div className="leftdiv" id="special">
            <div className="textdiv">
              <div className="headerdiv">
                <h1>Event Venue </h1>
              </div>
              <div className="subdiv">
                <div>
                  <p>
                    Enter more information about your event. Personalize event
                    to your unique taste.
                  </p>
                </div>
                <div>
                  <img src="/images/magicpen.svg" alt="img" />
                </div>
              </div>
            </div>
            <div className="wrapper-event-info">
              <Input
                placeholder="Example: Nigeria"
                label="Country"
                type="text"
                name="country"
                onChange={formikCreateEvent.handleChange}
                value={formikCreateEvent.values.country}
                error={formikCreateEvent.errors.country}
              />
              <div className="wrapper-flex">
                <div className="wrapper-child">
                  <Input
                    placeholder="Example: Lagos"
                    label="State"
                    name="state"
                    type="text"
                    onChange={formikCreateEvent.handleChange}
                    value={formikCreateEvent.values.state}
                    error={formikCreateEvent.errors.state}
                  />
                </div>
                <div className="wrapper-child">
                  <Input
                    placeholder="Example: Ikoyi"
                    label="City"
                    name="city"
                    type="text"
                    onChange={formikCreateEvent.handleChange}
                    value={formikCreateEvent.values.city}
                    error={formikCreateEvent.errors.city}
                  />
                </div>
              </div>
              <Input
                placeholder="Example: 1b Namtl Waterplace Osborne"
                label="Street Address"
                type="text"
                name="street_address"
                onChange={formikCreateEvent.handleChange}
                value={formikCreateEvent.values.street_address}
                error={formikCreateEvent.errors.street_address}
              />
            </div>
          </div>
          <div className="rightdiv2">
            <div className="button">
              <p>Nice, nearly there!</p>
              <PrimaryButton2 bg="#0D0D0D" title="Proceed" />
            </div>
          </div>
        </div>
      </form>
    </StyledOnboardEventInfo>
  );
};

const StyledOnboardEventInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 5%;
  & .backk {
    cursor: pointer;
    width: auto;
    height: 30px;
    margin-top: 40px;
  }
  & .proskip {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  & .div1 {
    display: flex;
    justify-content: space-between;

    & .leftdiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      & .wrapp {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      & .wrapper-event-info {
        width: 480px;
        margin-top: 20px;
        & .wrapper-flex {
          display: flex;
          justify-content: space-between;
          & .wrapper-child {
            width: 45%;
          }
        }
      }
      & .textdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .headerdiv {
          display: flex;
          align-items: center;
          gap: 1em;
          margin-top: 30px;

          & h1 {
            font-weight: 500;
            font-size: 25px;
            color: #000;
            line-height: 44px;
            font-family: "MatterMedium", sans-serif;
          }
          & img {
            width: auto;
            height: 70px;
          }
        }
        & .subdiv {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          margin-top: 20px;
          width: 100%;
          & img {
            position: relative;
            left: 0px;
          }
          & p {
            text-align: left;
            padding-right: 0px;
            line-height: 1.5rem;
            font-size: 12px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: #2d2d2d;
          }
        }
      }
    }
    & .rightdiv2 {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 25px;
      & p {
        text-align: center;
        line-height: 1.5rem;
        font-size: 16px;
        font-weight: 400;
        font-family: "MatterNormal", sans-serif;
        color: #2d2d2d;
        margin-bottom: 10px;
      }
    }
    & .rightdiv {
      & .onboard {
        width: auto;
        position: relative;
        right: -60px;
        height: 600px;
      }
    }
  }

  @media (max-width: 1200px) {
    & .proskip {
      display: flex;
      & .image2 {
        width: auto;
        height: 60px;
      }
    }
    & .div1 {
      display: flex;
      & #special {
        width: 50%;
      }
      & .leftdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .wrapper-event-info {
          width: 100%;
          margin-top: 20px;
          & .wrapper-flex {
            display: flex;
            flex-direction: column;

            & .wrapper-child {
              width: 100%;
            }
          }
        }
        & .button {
          align-self: center;
        }
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .headerdiv {
            margin-top: 15px;
            & h1 {
              font-size: 30px;
            }
            & img {
              width: auto;
              height: 60px;
            }
          }
          & .subdiv {
            margin-bottom: 10px;
            margin-top: 10px;
            & img {
              position: relative;
              left: 0px;
            }
            & p {
              font-size: 18px;
              line-height: 1.5rem;
              padding: 0px;
            }
          }
        }
      }
      & .rightdiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & .onboard {
          width: auto;
          height: 500px;
          right: -40px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    height: auto;
    & .proskip {
      display: flex;
      flex-direction: column;
    }
    & .div1 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      & #special {
        width: 100%;
      }
      & .leftdiv {
        width: 100%;

        & .wrapp {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        & .wrapper-event-info {
          width: 100%;
          & .wrapper-flex {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            & .wrapper-child {
              width: 100%;
            }
          }
        }
        & .button {
          align-self: center;
        }
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .headerdiv {
            margin-top: 15px;
            & h1 {
              font-size: 23px;
              line-height: 2.3rem;
            }
          }
          & .subdiv {
            margin-bottom: 10px;
            margin-top: 10px;
            & img {
              position: relative;
              left: 0px;
            }
            & p {
              font-size: 17px;
              line-height: 1.5rem;
              padding: 0px;
            }
          }
        }
      }
      & .rightdiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & .onboard {
          width: auto;
          height: 600px;
          left: 0px;
        }
      }
    }
  }
  @media (max-width: 480px) {
    height: auto;
    & .proskip {
      display: flex;
      flex-direction: column;
    }
    & .div1 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      & .leftdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .wrapp {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        & .wrapper-event-info {
          width: 100%;
          & .wrapper-flex {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            & .wrapper-child {
              width: 100%;
            }
          }
        }
        & .button {
          align-self: center;
        }
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .headerdiv {
            margin-top: 15px;
            & h1 {
              font-size: 30px;
              text-align: left;
              line-height: 3rem;
            }
          }
          & .subdiv {
            margin-bottom: 10px;
            margin-top: 10px;
            & img {
              position: relative;
              left: 0px;
            }
            & p {
              font-size: 18px;
              line-height: 1.5rem;
              padding: 0px;
            }
          }
        }
      }
      & .rightdiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & .onboard {
          width: auto;
          height: 500px;
          left: 0px;
        }
      }
    }
  }
`;
