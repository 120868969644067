import React, { useState } from "react";
import styled, { css } from "styled-components";
import { TiLockClosed, TiLockOpen } from "react-icons/ti";

export const PassWordInput = ({
  placeholder,
  label,
  forr,
  onChange,
  name,
  value,
  error,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledPasswordInput>
      <label className="label mb-1" htmlFor={forr}>
        {label}
      </label>
      <WrapInput disabled={disabled}>
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
        <div
          className="password-icon"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <TiLockOpen size={20} color="#2C2C2C" />
          ) : (
            <TiLockClosed size={20} color="#2C2C2C" />
          )}
        </div>
      </WrapInput>
      {error && <p id="text-danger">{error}</p>}
    </StyledPasswordInput>
  );
};
export const PassWordInput2 = ({
  placeholder,
  label,
  forr,
  onChange,
  name,
  value,
  error,
  disabled,
  borderBottom,
  borderRadius,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledPasswordInput2>
      <label className="label mb-1" htmlFor={forr}>
        {label}
      </label>
      <WrapInput disabled={disabled}>
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
        <div
          className="password-icon"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <TiLockOpen size={20} color="#2C2C2C" />
          ) : (
            <TiLockClosed size={20} color="#2C2C2C" />
          )}
        </div>
      </WrapInput>
      {error && <p id="text-danger">{error}</p>}
    </StyledPasswordInput2>
  );
};
const WrapInput = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}
  position:relative;
`;

const StyledPasswordInput = styled.div`
  width: 100%;
  margin-bottom: 20px;

  #text-danger {
    color: red;
    font-size: 12px;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border: 1px solid rgba(255, 92, 0, 1);
    background-color: white;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid #000;
    border-radius: 8px;
    border: 1px solid #000;
    height: 42px;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
    font-family: "MatterNormal", sans-serif;
  }

  .password-icon {
    position: absolute;
    top: 9px;

    right: 15px;
    cursor: pointer;
  }

  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }

  @media (max-width: 1024px) {
    width: 100%;
    .form-control {
      font-size: 16px;
    }

    .label {
      font-size: 16px;
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    .form-control {
      font-size: 16px;
    }
    .label {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    width: auto;
    .form-control {
      font-size: 17px;
      height: 40px;
    }

    .label {
      font-size: 17px;
    }
  }
`;

const StyledPasswordInput2 = styled.div`
  width: 100%;
  margin-bottom: 20px;
  #text-danger {
    color: red;
    font-size: 12px;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #999999;
    outline: -webkit-focus-ring-color auto 0px;

    outline: none;
    border: 1px solid rgba(255, 92, 0, 1);
    background-color: white;
  }

  .form-control {
    border: 1px solid #000;
    border-radius: 8px;
    border: 1px solid #000;
    height: 35px;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
    font-family: "MatterNormal", sans-serif;
  }

  .password-icon {
    position: absolute;
    top: 9px;
    right: 15px;
    cursor: pointer;
  }

  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }

  @media (max-width: 1024px) {
    width: 100%;
    .form-control {
      font-size: 16px;
    }

    .label {
      font-size: 16px;
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    .form-control {
      font-size: 16px;
    }
    .label {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    width: auto;
    .form-control {
      font-size: 17px;
      height: 40px;
    }

    .label {
      font-size: 17px;
    }
  }
`;
