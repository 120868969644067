import React from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { DashHeader } from "../../components/dashboardmain/Header";
import { StatWrapper } from "../../components/dashboardmain/Statistics";
import Modal from "../../components/ModalBox/Modal";
import { Button2 } from "../../components/button/Button2";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { ModalUploadFileDisabled } from "../../components/ModalBox/ModalUploadFile";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DatePick } from "../../components/input/DateTime";
import { Time } from "../../components/input/DateTime";
import { ModalUpload2 } from "../../components/ModalBox/ModalUploadFile";
import { WelcomeAdmin } from "../../components/dashboardmain/Welcome";
import { ModalDropDown2 } from "../../components/ModalBox/ModalDropDown";
import { ModalUploadFile2 } from "../../components/ModalBox/ModalUploadFile";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  StyledNavLink2,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledNavLink,
  StyledLink,
  StyledLink2,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/overviewstyles.js";
import useAdminOverview from "../../../../utils/apilogics/admin/overview/overviewLogic.js";
import StatusHandler from "../../../../utils/statusHandler.js";

export const AdminOverview = () => {
  const {
    pageNumber,
    setPageNumber,
    openDropdownIndex,
    setOpenDropdownIndex,
    setUseruuid,
    loading,
    selectedFile,
    isModalMenuUploadedOpen,
    isModalEditEventOpen,
    isModalDeleteEventOpen,
    isModalProfileOpen,
    selectedEventId,
    Error,
    dropError,
    uploads,
    selectedDate,
    selectedTime,
    backgroundImage,
    user,
    isModalCreateEventBehalfOpen,
    eventloading,
    eventerror,
    eventdata,
    profile,
    approvedeventddata,
    ongoingeventddata,
    activationrequestddata,
    formikEventUpdate,
    formikCreateEventBehalf,
    setDropError,
    setSelectedUser,
    setSelectedEventId,
    handleSearch,
    handleDropdownToggle,
    removeFromEvent,
    handleFileSelect,
    handleDateSelect,
    handleTimeSelect,
    handleFileChange,
    openModalProfile,
    openModalEditEvent,
    openModalMenuUploaded,
    openModalEventBehalf,
    closeModalEventBehalf,
    openModalEventDelete,
    closeModalEventDelete,
    closeModalMenuUploaded,
    closeModalEditEvent,
    closeModalProfile,
  } = useAdminOverview();

console.log(eventdata)

  return (
    <Wrapper>
      {isModalCreateEventBehalfOpen && (
        <form onSubmit={formikCreateEventBehalf.handleSubmit}>
          <Modal
            title="Create on Behalf"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEventBehalf}
            scroll
          >
            <div className="wrapper-div">
              <ModalDropDown2
                options={user?.map((e, index) => ({
                  label: e.label,
                  value: e.value,
                }))}
                defaultOption={{
                  label: "select host /  event planner",
                  value: null,
                }}
                onSelect={(selectedOption) => {
                  setSelectedUser(selectedOption.value);
                  setDropError(null);
                }}
                error={dropError}
                placeholder="Search by email..."
              />
              <Input
                label="Street Address"
                type="text"
                name="street_address"
                placeholder="enter event street address.."
                onChange={formikCreateEventBehalf.handleChange}
                value={formikCreateEventBehalf.values.street_address}
                error={formikCreateEventBehalf.errors.street_address}
              />
              <Input
                name="country"
                label="Country"
                type="text"
                onChange={formikCreateEventBehalf.handleChange}
                value={formikCreateEventBehalf.values.country}
                error={formikCreateEventBehalf.errors.country}
                placeholder="enter country"
              />
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <Input
                  name="state"
                  label="State"
                  type="text"
                  onChange={formikCreateEventBehalf.handleChange}
                  value={formikCreateEventBehalf.values.state}
                  error={formikCreateEventBehalf.errors.state}
                  placeholder="enter state"
                />
              </div>
              <div className="wrapper-child">
                <Input
                  name="city"
                  label="City"
                  type="text"
                  onChange={formikCreateEventBehalf.handleChange}
                  value={formikCreateEventBehalf.values.city}
                  error={formikCreateEventBehalf.errors.city}
                  placeholder="enter city"
                />
              </div>
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <Input
                  label="Guest Number"
                  type="number"
                  name="event_guest"
                  onChange={formikCreateEventBehalf.handleChange}
                  value={formikCreateEventBehalf.values.event_guest}
                  error={formikCreateEventBehalf.errors.event_guest}
                  placeholder="enter guest number"
                />
              </div>
              <div className="wrapper-child">
                <Input
                  label="Event Type"
                  name="event_type"
                  type="text"
                  onChange={formikCreateEventBehalf.handleChange}
                  value={formikCreateEventBehalf.values.event_type}
                  error={formikCreateEventBehalf.errors.event_type}
                  placeholder="enter type"
                />
              </div>
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <DatePick
                  handleDateChange={handleDateSelect}
                  selectedDate={selectedDate}
                  error={Error}
                />
              </div>
              <div className="wrapper-child">
                <Time
                  handleTimeChange={handleTimeSelect}
                  selectedTime={selectedTime}
                  error={Error}
                />
              </div>
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <Input
                  label="Event #Tag"
                  type="text"
                  name="event_tag"
                  onChange={formikCreateEventBehalf.handleChange}
                  value={formikCreateEventBehalf.values.event_tag}
                  error={formikCreateEventBehalf.errors.event_tag}
                  placeholder="enter #tag"
                />
              </div>
              <div className="wrapper-child">
                <Input
                  label="Event Toast"
                  type="text"
                  name="event_toast"
                  onChange={formikCreateEventBehalf.handleChange}
                  value={formikCreateEventBehalf.values.event_toast}
                  error={formikCreateEventBehalf.errors.event_toast}
                  placeholder="enter toast"
                />
              </div>
            </div>
            <ModalUploadFile2
              label="Event Background"
              name="upload_menu"
              onFileSelect={handleFileSelect}
              error={Error}
            />
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalMenuUploadedOpen && (
        <Modal
          imageSource="images/mdt3.png"
          title="Uploaded Menu"
          borderColor="0.5px solid rgba(0, 160, 64, 1)"
          backgroundColor="rgba(237, 255, 244, 1)"
          textColor="rgba(0, 160, 64, 1)"
          rightIconSrc="images/mdx.svg"
          onClose={closeModalMenuUploaded}
          scroll
        >
          {uploads && uploads?.length < 1 ? (
            <ModalEmpty title="No data uploaded yet!!!" />
          ) : (
            uploads?.map((e) => (
              <ModalUploadFileDisabled
                key={e.id}
                name="upload_menu"
                dateupload={e.created_at}
                content={e.upload_menu}
                note={e.upload_note || "No note added"}
              />
            ))
          )}
        </Modal>
      )}
      {isModalEditEventOpen && (
        <form onSubmit={formikEventUpdate.handleSubmit}>
          <Modal
            title="Edit Event Information"
            imageSource="images/mdt1.png"
            rightIconSrc="images/mdx.svg"
            onClose={closeModalEditEvent}
            scroll
          >
            <div className="wrapper-div">
              <Input
                label="Street Address"
                type="text"
                name="street_address"
                onChange={formikEventUpdate.handleChange}
                value={formikEventUpdate.values.street_address}
                error={formikEventUpdate.errors.street_address}
              />
              <Input
                name="country"
                label="Country"
                type="text"
                onChange={formikEventUpdate.handleChange}
                value={formikEventUpdate.values.country}
                error={formikEventUpdate.errors.country}
              />
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <Input
                  name="state"
                  label="State"
                  type="text"
                  onChange={formikEventUpdate.handleChange}
                  value={formikEventUpdate.values.state}
                  error={formikEventUpdate.errors.state}
                />
              </div>
              <div className="wrapper-child">
                <Input
                  name="city"
                  label="City"
                  type="text"
                  onChange={formikEventUpdate.handleChange}
                  value={formikEventUpdate.values.city}
                  error={formikEventUpdate.errors.city}
                />
              </div>
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <Input
                  label="Guest Number"
                  type="number"
                  name="event_guest"
                  onChange={formikEventUpdate.handleChange}
                  value={formikEventUpdate.values.event_guest}
                  error={formikEventUpdate.errors.event_guest}
                />
              </div>
              <div className="wrapper-child">
                <Input
                  label="Event Type"
                  name="event_type"
                  type="text"
                  onChange={formikEventUpdate.handleChange}
                  value={formikEventUpdate.values.event_type}
                  error={formikEventUpdate.errors.event_type}
                />
              </div>
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <DatePick
                  handleDateChange={handleDateSelect}
                  selectedDate={selectedDate}
                  error={Error}
                />
              </div>
              <div className="wrapper-child">
                <Time
                  handleTimeChange={handleTimeSelect}
                  selectedTime={selectedTime}
                  error={Error}
                />
              </div>
            </div>
            <div className="input-fle">
              <div className="wrapper-child">
                <Input
                  label="Event #Tag"
                  type="text"
                  name="event_tag"
                  onChange={formikEventUpdate.handleChange}
                  value={formikEventUpdate.values.event_tag}
                  // error={formikEventUpdate.errors.event_tag}
                />
              </div>
              <div className="wrapper-child">
                <Input
                  label="Event Toast"
                  type="text"
                  name="event_toast"
                  onChange={formikEventUpdate.handleChange}
                  value={formikEventUpdate.values.event_toast}
                  // error={formikEventUpdate.errors.event_toast}
                />
              </div>
            </div>
            <Input
              label="Event code"
              type="text"
              name="event_code"
              onChange={formikEventUpdate.handleChange}
              value={formikEventUpdate.values.event_code}
              error={formikEventUpdate.errors.event_code}
            />

            <ModalUpload2
              selectedFile={selectedFile}
              handleFileChange={handleFileChange}
              content={backgroundImage}
              title="Event Background"
            />

            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteEventOpen && (
        <Modal
          title="Delete Event"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalEventDelete}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="delete"
              onClick={() => removeFromEvent(selectedEventId)}
            />
          </div>
        </Modal>
      )}

      {isModalProfileOpen && (
        <Modal
          imageSource="images/mdt1.png"
          title="User Profile"
          rightIconSrc="images/mdx.svg"
          onClose={closeModalProfile}
        >
          <div className="wrapper-div">
            <Input value={profile.email} label="Email" disabled />
            <div className="input-flex">
              <div className="wrapper-child">
                <Input value={profile.firstname} label="First Name" disabled />
              </div>
              <div className="wrapper-child">
                <Input label="Last Name" value={profile.lastname} disabled />
              </div>
            </div>
            <div className="input-flex">
              <div className="wrapper-child">
                <Input value={profile.sex} label="Sex" disabled />
              </div>
              <div className="wrapper-child">
                <Input
                  label="Mobile Number"
                  value={profile.mobile_number}
                  disabled
                />
              </div>
            </div>
            <Input label="Company" value={profile.company} disabled />
          </div>
        </Modal>
      )}
      <MainContent>
        <CenteredContent>
          <DashHeader
            title={`Welcome Admin`}
            eventOnboardOnclick={openModalEventBehalf}
            titleButton="Create On Behalf"
          />
        </CenteredContent>
        <CenteredContent>
          <WelcomeAdmin />
        </CenteredContent>
        <StatWrapper
          activation
          figureTotal={eventdata ? eventdata?.data.count : 0}
          figureOngoing={ongoingeventddata ? ongoingeventddata?.data.length : 0}
          figureApproved={
            approvedeventddata ? approvedeventddata?.data.length : 0
          }
          figureActivationReuquest={
            activationrequestddata ? activationrequestddata?.data.length : 0
          }
        />
        <Table>
          <TableData
            headtitle="All Created Event"
            onChange={handleSearch}
            placeholder="search event"
            {...(!eventloading && {
              admin:
                eventdata && eventdata?.data?.results.length === 0
                  ? ""
                  : eventdata,
            })}
          >
            <StatusHandler isError={eventerror} isLoading={eventloading}>
              {eventdata && eventdata?.data?.results.length === 0 ? (
                <ModalEmpty title="no event found" />
              ) : (
                eventdata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="host / event planner">
                      <StyledNavLink2
                        // to={`/dashboard-admin-event/${e.event_uuid}/details`}
                        onClick={() => {
                          setUseruuid(e.user_id);
                          openModalProfile();
                        }}
                      >
                        <div className="mb-2">
                          <p>{`${e.firstname} ${e.lastname}`}</p>
                        </div>
                        <small>{e.email}</small>
                      </StyledNavLink2>
                    </td>
                    <td data-cell="event id">
                      <StyledNavLink2
                        to={`/dashboard-admin-event/${e.event_uuid}/details`}
                      >
                        {e.event_uuid.substring(0, 5)}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="guest number">{e.event_guest}</td>
                    <td data-cell="event type">{e.event_type}</td>
                    <td data-cell="location">{e.city}</td>
                    <td data-cell="date">{e.event_date}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() =>
                            handleDropdownToggle(index, e.event_uuid)
                          }
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin-event/${e.event_uuid}/details`}
                              className="nav-link"
                            >
                              view event details
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink onClick={openModalMenuUploaded}>
                              view uploaded menu
                            </StyledLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink onClick={openModalEditEvent}>
                              edit event information
                            </StyledLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin/${e.event_uuid}/menu-categories`}
                              className="nav-link"
                            >
                              add menu category
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink2> add ads details</StyledLink2>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin/${e.event_uuid}/trivia`}
                              className="nav-link"
                              
                              onClick={() => {
                                localStorage.setItem('questions_length', e.questions.length);
                              }}
                            >
                              add trivia details
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin/${e.event_uuid}/optical`}
                              className="nav-link"
                            >
                              add optical details
                            </StyledNavLink>
                          </DropdownItem>

                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setSelectedEventId(e.id);
                                openModalEventDelete();
                              }}
                            >
                              delete event
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {eventdata && eventdata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={eventdata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
