import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
    #text-danger {
    color: red;
    margin-top:5px;
    text-align: right;
    font-size: 12px;
    letter-spacing:1px;
    font-family: "GilroyRegular", sans-serif;
  }
`;

const StyledInput = styled.input`
  background-color: rgba(248, 248, 248, 1);
  border-radius: 5px;
  border:none;
  font-size: 16px;
  color: rgba(56, 56, 56, 1);
  font-family: "MatterMedium", sans-serif;
  padding-left:17px;
  height:60px;
  font-size: 17px;  
  &:focus {
    outline: none; /* Remove default outline */
    border: 0.5px solid #FF5C00; /* Add the orange border */
  }

  

  @media screen and (max-width: 640px) {
    padding-left:18px;
    height:55px;
    font-size: 17px;
  }

  @media screen and (max-width: 480px) {
    padding-left:18px;
    height:50px;
    font-size: 17px;
    #text-danger {
    color: red;
    font-size: 17px;
  }
  
}
`;

function CartBoxInput({value, onChange,placeholder,type,name,disabled,error}) {

  return (
    <InputContainer className='mt-2'>
      <StyledInput
        type={type}
        id="myInput"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
      />
          {error && <p id="text-danger">{error}</p>}
    </InputContainer>
  );
}

export default CartBoxInput;
