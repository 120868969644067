import React from "react";
import styled from "styled-components";
import { LoadingSpinner } from "./loader";

const StatusHandler = ({ isLoading, isError, errorMessage, children }) => {
  if (isLoading) {
    return (
      <LoaderContainer>
        <LoadingSpinner />
      </LoaderContainer>
    );
  }

  if (isError) {
    return (
      <ErrorContainer>
        <p>Error loading data. Please try again later</p>
      </ErrorContainer>
    );
  }

  return <>{children}</>;
};

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  color: red;
  font-family: "MatterNormal", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 640px) {
    font-size: 17px;
  }
`;

export default StatusHandler;
