import React from "react";
import { NavBar } from "../sections/NavBar";
import { Welcome } from "../sections/Welcome";
import { WhyWiserve } from "../sections/WhyWiserve";
import { HowItWorks } from "../sections/HowItWorks";
import { Faqs } from "../sections/Faqs";
// import { Pricing } from "../sections/Pricing";
import { Contact } from "../sections/Contact";
import { Footer } from "../sections/Footer";
import BackToTop from "../components/BackToTop";

export const HomePage = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <NavBar />
      <Welcome />
      <WhyWiserve />
      <HowItWorks />
      {/* <Pricing /> */}
      <Faqs />
      <Contact />
      <Footer/>
      <BackToTop />
    </div>
  );
};
