import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Trivia from "./Trivia";
import Ads from "./Ads";
import {Optical} from "./Optical";
import axios from "axios";
import { URL } from "../../../../utils/url";

export default function IndexOthers() {
  const [state, setState] = useState({
    isDarkMode: false,
    eventId: "",
    triviaActive: false,
    adsActive: false,
    opticalActive: false,
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      const storedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
      const storedEventId = localStorage.getItem("eventId");

      let triviaActive = false;
      let adsActive = false;
      let opticalActive = false;

      if (storedEventId) {
        try {
          const response = await axios.get(`${URL}trivia/get-event/${storedEventId}`);
          triviaActive = response.data.trivia_active;
          adsActive = response.data.ads_active;
          opticalActive = response.data.optical_active;
        } catch (error) {
        }
      }

      setState({
        isDarkMode: storedDarkMode !== null ? storedDarkMode : false,
        eventId: storedEventId || "",
        triviaActive,
        adsActive,
        opticalActive,
      });
    };

    fetchInitialData();
  }, []);

  const { isDarkMode, triviaActive, adsActive, opticalActive } = state;

  return (
    <StyledIndex isDarkMode={isDarkMode}>
      {triviaActive && <Trivia />}
      {adsActive && <Ads />}
      {opticalActive && <Optical />}
    </StyledIndex>
  );
}

const StyledIndex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};

  @media screen and (max-width: 912px) {
    img {
      width: 200px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 150px;
      height: auto;
    }
  }
`;
