import React from 'react';
import styled from 'styled-components';

const CheckOutInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  
  @media (max-width: 820px) {
    #text-danger {
    color: red;
    margin-top:5px;
    text-align: right;
    font-size: 18px;
    /* letter-spacing:1px; */
    font-family: "MatterNormal", sans-serif;

  }
  }

  @media (max-width: 640px) {
    #text-danger {
    font-size: 14px;
  }
  }

  @media (max-width: 480px) {
    #text-danger {
    font-size: 17px;
  }
  }
`;

const StyledInput = styled.input`
  background-color:#F3F2F2;
  border: none;
  border-bottom: 1px solid #1D1D1D; /* Set bottom border */
  font-size: 20px;
  color: rgba(56, 56, 56, 1);
  font-family: "MatterMedium", sans-serif;
  padding-left: 17px;
  height: 60px;

  &:focus {
    outline: none;
    border-bottom: 1px solid #FF5C00; /* Change bottom border on focus */
  }

  @media screen and (max-width: 640px) {
    padding-left: 18px;
    height: 50px;
    font-size: 17px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 18px;
    height: 50px;
    font-size: 17px;
    /* Modify the error text styles for smaller screens */
    #text-danger {
      color: red;
      font-size: 17px;
    }
  }
`;

function CheckOutInput({ value, onChange, placeholder, type, name, disabled, error }) {
  return (
    <CheckOutInputContainer>
      <StyledInput
        type={type}
        id="myInput"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
      />
      {error && <p id="text-danger">{error}</p>}
    </CheckOutInputContainer>
  );
}

export default CheckOutInput;
