import React from "react";
import styled from "styled-components";

export const ModalEmpty = ({ title }) => {
  return (
    <StyledEmpty>
      <h1>{title}</h1>
    </StyledEmpty>
  );
};

const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;

  h1 {
    font-size: 15px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(255, 92, 0, 1);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    display: flex;
    justify-content: flex-start;
    h1 {
      font-size: 17px;
    }
  }
`;
