import React, { useState, useEffect, useContext } from "react";
import { Head } from "../../components/Header";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import CartBox from "../../components/CartB";
import { ContextStore } from "../../../../context/contextStore";
import { NotiError } from "../../components/Notification";
import FoodEmpty from "../../components/FoodEmpty";
import axios from "axios";
import { useFormik } from "formik";
import CheckOutInput from "../../components/CheckOutInput";
import { PrimaryButton2 } from "../../components/Button2";
import { URL } from "../../../../utils/url";
import { cartvalidationSchema } from "../../../../utils/validation";
import { cartinitialValues } from "../../../../utils/initialvalues";

export default function FoodCart() {
  const { token } = useParams();
  const [selectedItemName, setSelectedItemName] = useState("");
  const [removefromcart, setRemoveFromCart] = useState(false);
  const { cartInfo, setCartInfo } = useContext(ContextStore);
  const { AddedItem, setAddedItem } = useContext(ContextStore);
  const [modalVisible, setModalVisible] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventid, setEventId] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(`/${token}/food-menu`);
  };

  useEffect(() => {
    const storedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode);
    }
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAddedItem("");
  };

  const setBack = () => {
    setTimeout(() => {
      setRemoveFromCart(false);
    }, 2000);
  };

  const setCartEmpty = () => {
    setTimeout(() => {
      setCartInfo([]);
    }, 4000);
  };

  const timeBeforeRedirectOrderDone = () => {
    setTimeout(() => {
      navigate(`/${token}/order-confirmation`);
    }, 2000);
  };

  const removeFromCart = (food, name) => {
    let cart_remained = cartInfo.filter((c) => c.id !== food);
    setRemoveFromCart(true);
    setSelectedItemName(name);
    setCartInfo(cart_remained);
    setBack();
  };

  const onChangeNote = (e, i) => {
    const updatedCartItem = {
      ...cartInfo[i],
      note: e.target.value,
    };

    const updatedCart = [...cartInfo];
    updatedCart[i] = updatedCartItem;
    setCartInfo(updatedCart);
  };

  const handleOptionChange = (selectedOptions, i) => {
    const updatedCartItem = {
      ...cartInfo[i],
      extras: selectedOptions,
    };

    const updatedCart = [...cartInfo];
    updatedCart[i] = updatedCartItem;
    setCartInfo(updatedCart);
  };

  const scrollToCardByName = (menuName) => {
    const element = document.getElementById(menuName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const FindAndCompareItem = cartInfo.find(
      (cartItem) => cartItem.menu_name === AddedItem.menu_name
    );

    if (FindAndCompareItem) {
      setModalVisible(true);
      scrollToCardByName(FindAndCompareItem.menu_name);
    }
    onGetCategory();
  }, [AddedItem]);

  const onGetCategory = async () => {
    try {
      const response = await axios.get(`${URL}category/${token}/`);
      const eventidValue = response.data.data[0].eventid;
      setEventId(eventidValue);
    } catch (error) {
      console.log(error.response, "Error fetching category");
    }
  };

  // Function to reset the button state back to its original state
  const resetButtonState = () => {
    setTimeout(() => {
      setAccepted(false);
      setRejected(false);
      setLoading(false);
      setErrorMessage([]);  // Clear error messages as well
    }, 2000);  // Adjust the delay as needed
  };

  const onSubmit = async (values, { resetForm }) => {
    const cartPayload = cartInfo.map((v) => ({
      event_id: eventid,
      cat_id: v.cat_id,
      menu_id: v.id,
      seat: values.seat,
      table: values.table,
      note: v.note || "No note left!",
      extras: v.extras || "No combo added!",
    }));

    setLoading(true);
    try {
      const response = await axios.post(`${URL}post_order/`, cartPayload);
      setAccepted(true);
      setBack();
      timeBeforeRedirectOrderDone();
      resetForm();
      setCartEmpty();
      setLoading(false);
      resetButtonState(); // Reset button state after order is accepted
    } catch (error) {
      setRejected(true);
      setLoading(false);

      // Check if the error response has 'invalid_orders'
      if (error.response?.data?.invalid_orders) {
        const invalidOrders = error.response.data.invalid_orders;
        const formattedErrors = invalidOrders.map(
          (item) => `${item.error}`
        );
        setErrorMessage(formattedErrors);
      } else {
        setErrorMessage([error.response?.data?.message || "An error occurred"]);
      }

      setBack();
      resetButtonState(); // Reset button state after rejection
    }
  };

  const formik = useFormik({
    initialValues: cartinitialValues,
    onSubmit,
    validationSchema: cartvalidationSchema,
  });

  return (
    <Parent isDarkMode={isDarkMode}>
      <div className="parent">
        {modalVisible && (
          <Modal>
            <ModalContent>
              <div className="flex">
                <div>
                  <h1 className="mh">Extras</h1>
                </div>
                <div>
                  <img src="/images/star.png" className="imgg" alt="star" />
                </div>
              </div>
              <p className="mp mt-2">Add desired combos to your meal.</p>
              <div className="mt-3">
                <img src="/images/extrass.png" className="image" alt="extras" />
              </div>
              <div className="x" onClick={closeModal}>
                <img src="/images/xx.png" className="imgg" alt="close" />
              </div>
            </ModalContent>
          </Modal>
        )}
        {cartInfo && cartInfo.length > 0 ? (
          <>
            <Head
              title="Complete Order"
              isDarkMode={isDarkMode}
              onClick={goBack}
            />
            <div className="cont">
              {removefromcart && (
                <NotiError title={`${selectedItemName} removed from food cart`} />
              )}

              {errorMessage && <NotiError title={errorMessage} />}
            </div>
          </>
        ) : null}

        <div>
          <form onSubmit={formik.handleSubmit}>
            <CartBoxContainer>
              {cartInfo && cartInfo.length > 0 ? (
                cartInfo?.map((item, index) => (
                  <CartBox
                    key={index}
                    id={item.menu_name}
                    src={item.avatar}
                    foodname={item.menu_name}
                    fooddescrip={item.description}
                    data={item.menu_extras}
                    selectedOptions={item.extras}
                    onOptionChange={(selectedOptions) =>
                      handleOptionChange(selectedOptions, index)
                    }
                    onClick={() => removeFromCart(item.id, item.menu_name)}
                    value={item.note}
                    name={item.menu_name}
                    onChange={(e) => onChangeNote(e, index)}
                  />
                ))
              ) : (
                <FoodEmpty isDarkMode={isDarkMode} />
              )}
              {cartInfo && cartInfo.length > 0 ? (
                <>
                  <div>
                    <CheckOutInput
                      placeholder="Enter Table number"
                      type="number"
                      name="table"
                      onChange={formik.handleChange}
                      value={formik.values.table}
                      error={formik.errors.table}
                    />
                    <CheckOutInput
                      placeholder="Enter Seat number or your Name"
                      type="text"
                      name="seat"
                      onChange={formik.handleChange}
                      value={formik.values.seat}
                      error={formik.errors.seat}
                    />
                  </div>
                  <div className="button">
                    {!accepted && !rejected && (
                      <PrimaryButton2
                        title={loading ? "loading..." : "Place Order"}
                        onClick={formik.handleSubmit}
                        backgroundColor="#FF5C00"
                        type="submit"
                      />
                    )}

                    {accepted && (
                      <PrimaryButton2
                        title="Placed"
                        backgroundColor="#098449"
                      />
                    )}
                    {rejected && (
                      <PrimaryButton2
                        title="Rejected"
                        backgroundColor="#C84129"
                      />
                    )}
                  </div>
                </>
              ) : null}
            </CartBoxContainer>
          </form>
        </div>
      </div>
    </Parent>
  );
}


const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ModalContent = styled.div`
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  .flex {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.5rem;
    margin-top: 10px;
  }
  @media screen and (max-width: 812px) {
    width: 350px;

    .x {
      position: absolute;
      right: 20px;
      top: 15px;
    }
    .image {
      width: auto;
      height: 95px;
    }
    .imgg {
      width: auto;
      height: 30px;
    }
    .mh {
      color: #1d1d1d;
      font-family: "MatterMedium";
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
    .mp {
      color: #383838;
      font-family: "MatterNormal";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      margin-top: 5px;
      /* margin-bottom: 30px; */
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 640px) {
    width: 350px;
    padding: 10px 20px;
    margin: 0 auto;
    .mh {
      font-size: 25px;
    }
    .mp {
      font-size: 18px;
    }
    .imgg {
      width: auto;
      height: 20px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 250px;
    padding: 25px 15px;
    .image {
      width: auto;
      height: 67px;
    }
    .mh {
      font-size: 20px;
    }
    .mp {
      font-size: 13px;
    }
  }
`;
const CartBoxContainer = styled.div`
  height: 80vh;
  width: 100%;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Parent = styled.div`
  height: 100vh;
  padding: 0px 30px;
  width: 100%;
  overflow-x: hidden;

  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};
  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .cont {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 10px;
  }

`;
