import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const useAdminCategory = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalEditCategoryOpen, setModalEditCategoryOpen] = useState(false);
  const [isModalCreateCategoryOpen, setModalCreateCategoryOpen] =
    useState(false);
  const [isModalDeleteCategoryOpen, setModalDeleteCategoryOpen] =
    useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [Error, setError] = useState(null);
  const { id } = useParams();
  const [catid, setCatId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    axios
      .get(
        `${URL}superadmin/${id}/menucategory/${catid}/`,
        headers(localStorage.getItem("token"))
      )
      .then((response) => {
        setInitialValues(response.data);
        formikUpdateCategory.setValues(response.data);
      })
      .catch((error) => {});
  }, [id, catid]);

  const initialValue = {
    cat_name: initialValues?.cat_name || "",
  };

  const validationSchema = Yup.object({
    cat_name: Yup.string().required("Category is required"),
  });

  const onSubmitUpdateCategory = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}superadmin/${id}/menucategory/${catid}/`,
        values,
        headers(localStorage.getItem("token"))
      );

      toast.success("category updated successfully!");
      refetch();
      setLoading(false);
      setModalEditCategoryOpen(false);
    } catch (error) {
      toast.error("category update failed");
      setLoading(false);
    }
  };

  const formikUpdateCategory = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: onSubmitUpdateCategory,
  });

  const fetchMenuCategory = (page, searchTerm) => {
    let url = `/superadmin/menucategory/${id}/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: menucatdata,
    isLoading: menucatloading,
    error: menucaterror,
    refetch,
  } = useQuery(["menucategory", pageNumber, searchTerm], () =>
    fetchMenuCategory(pageNumber, searchTerm)
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1); 
  };

  const removeFromCategory = async (categoryId) => {
    setLoading(true);

    try {
      const response = await axios.delete(
        `${URL}superadmin/${id}/menucategory/${categoryId}/`,
        headers(localStorage.getItem("token"))
      );
      const datas = response.data;
      toast.success("category deleted successfully");
      refetch();
      setLoading(false);

      setModalDeleteCategoryOpen(false);
    } catch (error) {
      toast.error("category deletion failed");
      setLoading(true);
    }
  };

  const openModalEditCategory = () => {
    setModalEditCategoryOpen(true);
  };

  const openModalDeleteCategory = () => {
    setModalDeleteCategoryOpen(true);
  };
  const openModalCreateCategory = () => {
    setModalCreateCategoryOpen(true);
  };

  const closeModalEditCategory = () => {
    setModalEditCategoryOpen(false);
  };
  const closeModalDeleteCategory = () => {
    setModalDeleteCategoryOpen(false);
  };
  const closeModalCreateCategory = () => {
    setModalCreateCategoryOpen(false);
  };
  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const createCategoryvalidationSchema = Yup.object({
    cat_name: Yup.string().required("category is required"),
  });

  const createCategoryinitialValues = {
    cat_name: "",
  };

  const onSubmitCreateCategory = async (values, { resetForm }) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${URL}superadmin/${id}/menucategory/`,
        values,
        headers(localStorage.getItem("token"))
      );

      toast.success("category created successfully");
      setModalCreateCategoryOpen(false);
      resetForm();
      refetch();
      setLoading(false);
    } catch (error) {
      toast.error("category creation failed");
      setLoading(false);
      setModalCreateCategoryOpen(false);
    }
  };

  const formikCategoryCreate = useFormik({
    initialValues: createCategoryinitialValues,
    validationSchema: createCategoryvalidationSchema,
    onSubmit: onSubmitCreateCategory,
  });
  return {
    openDropdownIndex,
    setOpenDropdownIndex,
    loading,
    isModalEditCategoryOpen,
    isModalCreateCategoryOpen,
    isModalDeleteCategoryOpen,
    initialValues,
    Error,
    catid,
    id,
    setCatId,
    searchTerm,
    setSearchTerm,
    pageNumber,
    setPageNumber,
    menucatdata,
    menucatloading,
    menucaterror,
    formikUpdateCategory,
    formikCategoryCreate,
    handleSearch,
    removeFromCategory,
    openModalEditCategory,
    openModalDeleteCategory,
    openModalCreateCategory,
    closeModalEditCategory,
    closeModalDeleteCategory,
    closeModalCreateCategory,
    handleDropdownToggle,
  };
};

export default useAdminCategory;
