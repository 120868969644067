import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import {
  Overview,
  Ongoing,
  Request,
  Approved,
  Chat,
  Logout,
} from "../../components/SideBarSvg";

const Sidebars = ({ handleTabClick, content }) => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const item = localStorage.getItem("userdata");
    if (item) {
      const parsedItem = JSON.parse(item);
      setdata(parsedItem);
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  function logout() {
    localStorage.removeItem("token");
    localStorage.setItem("userdata", "");
    toast.success("logged out successfully !!");
    navigate("/");
  }

  return (
    <SidebarWrapper>
      <div className="logo-div">
        <img src="/images/wiserve_logo.svg" alt="wiserve-logo" />
      </div>
      {data.is_host ? (
        <>
          <ul className="menu">
            <li
              className={
                location.pathname === "/dashboard-host-overview" ? "active" : ""
              }
            >
              <NavLink to="/dashboard-host-overview">
                <Overview
                  color={
                    location.pathname === "/dashboard-host-overview"
                      ? "#FF5C00"
                      : "black"
                  }
                  hoverColor="#FF5C00"
                />
                <span>My Overview</span>
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/dashboard-host-ongoing-event"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/dashboard-host-ongoing-event">
                <Ongoing
                  color={
                    location.pathname === "/dashboard-host-ongoing-event"
                      ? "#FF5C00"
                      : "black"
                  }
                />
                <span>Ongoing Event</span>
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/dashboard-host-approved-event"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/dashboard-host-approved-event">
                <Approved
                  color={
                    location.pathname === "/dashboard-host-approved-event"
                      ? "#FF5C00"
                      : "black"
                  }
                />
                <span>Approved Event</span>
              </NavLink>
            </li>

            <li className="chat">
              <NavLink>
                <Chat color="black" />

                <span>Chat admin</span>
              </NavLink>
            </li>
            <li className="logout" onClick={() => logout()}>
              <NavLink>
                <Logout color="black" />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </>
      ) : null}
      {data.is_superuser ? (
        <>
          <ul className="menu">
            <li
              className={
                location.pathname === "/dashboard-admin-overview"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/dashboard-admin-overview">
                <Overview
                  color={
                    location.pathname === "/dashboard-admin-overview"
                      ? "#FF5C00"
                      : "black"
                  }
                  hoverColor="#FF5C00"
                />
                <span>My Overview</span>
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/dashboard-admin-ongoing-event"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/dashboard-admin-ongoing-event">
                <Ongoing
                  color={
                    location.pathname === "/dashboard-admin-ongoing-event"
                      ? "#FF5C00"
                      : "black"
                  }
                />
                <span>Ongoing Event</span>
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/dashboard-admin-approved-event"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/dashboard-admin-approved-event">
                <Approved
                  color={
                    location.pathname === "/dashboard-admin-approved-event"
                      ? "#FF5C00"
                      : "black"
                  }
                />
                <span>Approved Event</span>
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/dashboard-admin-activation-request"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/dashboard-admin-activation-request">
                <Request
                  color={
                    location.pathname === "/dashboard-admin-activation-request"
                      ? "#FF5C00"
                      : "black"
                  }
                />
                <span>Activation Requests</span>
              </NavLink>
            </li>

            <li className="logout" onClick={() => logout()}>
              <NavLink>
                <Logout color="black" />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </>
      ) : null}
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  height: 100vh;
  color: #fff;
  padding: 0 1rem;
  overflow: hidden;
  background-color: white;
  transition: all 1s linear;
  z-index: 1;

  .logo-div {
    position: relative;
    top: 0px;
    top: 50px;
    left: 0;
    width: 100%;
    border-radius: 15px;
  }

  .menu {
    height: 90%;
    position: relative;
    list-style: none;
    padding: 0;
    margin-top: 90px;
  }

  .menu li {
    padding: 0.5rem 0.8rem;
    margin: 30px 0;
    transition: all 0.5s ease-in-out;
  }

  .menu li:hover,
  .menu li.active {
    background-color: rgba(0, 0, 0, 1);
    border-radius: 8px;
  }

  .menu li:hover a,
  .menu li.active a {
    color: #fff;
  }
  .menu a {
    line-height: 1.5rem;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: #000000;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 2s ease, transform 0.3s ease;
  }

  .menu a span {
    overflow: hidden;
  }

  .chat {
    position: absolute;
    bottom: 110px;
    left: 0;
    width: 100%;
    opacity: 0.2;
  }

  .logout {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
  }

  @media (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8%;
    height: 100vh;
    color: #fff;
    padding: 0 0.9rem;
    overflow: hidden;
    background-color: white;
    transition: all 1s linear;

    &:hover {
      width: 180px;
      transition: 1.5s;
      .menu li {
        padding: 0.3rem 10%;
        transition: all 0.5s ease-in-out;
      }
    }

    .menu li {
      padding: 0.1rem 25%;
      transition: all 0.5s ease-in-out;
    }

    .menu a {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 640px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 12.5%;
    height: 100vh;
    color: #fff;
    padding: 0 0.9rem;
    overflow: hidden;
    background-color: white;
    transition: all 1s linear;

    &:hover {
      width: 180px;
      transition: 1.5s;
      .menu li {
        padding: 0.3rem 10%;
        transition: all 0.5s ease-in-out;
      }
    }
    .menu li {
      padding: 0.1rem 23%;
      transition: all 0.5s ease-in-out;
    }

    .menu a {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 480px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 18%;
    height: 100vh;
    color: #fff;
    overflow: hidden;
    background-color: white;
    transition: all 1s linear;

    .chat {
    position: absolute;
    bottom: 165px;
    left: 0;

  }

  .logout {
    bottom: 100px;
    left: 0;

  }
    .menu a {
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .menu li {
      padding: 0.1rem 23%;
      transition: all 0.5s ease-in-out;
    }

    .menu li:hover a,
    .menu li.active a {
      color: #fff;
    }
    &:hover {
      width: 250px;
      transition: 1.5s;
      .menu li {
        padding: 0.3rem 10%;
        transition: all 0.5s ease-in-out;
      }
    }
    .logo-div {
      position: relative;
      top: 50px;
    }
  }

  @media (max-width: 350px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 18%;
    height: 100vh;
    color: #fff;
    padding: 0 0.5rem;
    overflow: hidden;
    background-color: white;
    transition: all 1s linear;

    .menu a {
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    .menu li {
      padding: 0.1rem 27%;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      width: 200px;
      transition: 1.5s;
      .menu li {
        padding: 0.3rem 10%;
        transition: all 0.5s ease-in-out;
      }
    }

    .logo-div {
      position: relative;
      top: 50px;
      left: 15%;
    }
  }
`;

export default Sidebars;
