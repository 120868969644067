import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const useAdminExtras = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalEditMenuextraOpen, setModalEditMenuextraOpen] = useState(false);
  const [isModalCreateMenuextraOpen, setModalCreateMenuextraOpen] =
    useState(false);
  const [isModalDeleteMenuextraOpen, setModalDeleteMenuextraOpen] =
    useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [Error, setError] = useState(null);
  const { id1, id2 } = useParams();
  const [category, setCatgeory] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [menuextraid, setMenuExtraid] = useState(null);

  const updateMenuexravalidationSchema = Yup.object({
    extra: Yup.string().required("menu name is required"),
  });

  useEffect(() => {
    if (menuextraid) {
      axios
        .get(
          `${URL}superadmin/menuextra/${menuextraid}/rud/`,
          headers(localStorage.getItem("token"))
        )
        .then((response) => {
          setInitialValues(response?.data);
          formikUpdateMenuextra.setValues(response?.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [menuextraid]);

  const menuextraUpdateinitialValue = {
    extra: initialValues?.extra || "",
  };

  const multipartConfig = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onSubmitUpdateMenuextra = async (values, { resetForm }) => {
    const payload = {
      extra: values.extra,
    };

    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}superadmin/menuextra/${menuextraid}/rud/`,
        payload,
        multipartConfig,
        headers(localStorage.getItem("token"))
      );

      toast.success("menu extra updated successfully!");
      resetForm();
      refetch();
      setLoading(false);
      setModalEditMenuextraOpen(false);
    } catch (error) {
      console.log(error);
      toast.error("menu extra update failed");
      setLoading(false);
    }
  };
  const formikUpdateMenuextra = useFormik({
    validationSchema: updateMenuexravalidationSchema,
    initialValues: menuextraUpdateinitialValue,
    onSubmit: onSubmitUpdateMenuextra,
  });

  const {
    data: menuextradata,
    isError: menuextraerror,
    isLoading: menuextraloading,
    refetch,
  } = useQuery(["menuextra", id2], () => {
    return request.get(
      `${URL}superadmin/${id2}/menuextra/`,
      headers(localStorage.getItem("token"))
    );
  });

  const removeFromMenuExtra = async () => {
    try {
      const response = await axios.delete(
        `${URL}superadmin/menuextra/${menuextraid}/rud/`,
        headers(localStorage.getItem("token"))
      );
      toast.success("menu extra  deleted successfully!");
      refetch();
      setModalDeleteMenuextraOpen(false);
    } catch (error) {
      console.log(error);
      toast.error("menu extra delete failed");
    }
  };

  const openModalEditMenuextra = () => {
    setModalEditMenuextraOpen(true);
  };

  const openModalDeleteMenuextra = () => {
    setModalDeleteMenuextraOpen(true);
  };
  const openModalCreateMenuextra = () => {
    setModalCreateMenuextraOpen(true);
  };

  const closeModalEditMenuextra = () => {
    setModalEditMenuextraOpen(false);
  };
  const closeModalDeletemenuextra = () => {
    setModalDeleteMenuextraOpen(false);
  };
  const closeModalCreateMenuextra = () => {
    setModalCreateMenuextraOpen(false);
  };
  const handleDropdownToggle = (index, id1) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedEventId(id1);
  };

  const createMenuextrainitialValues = {
    extra: "",
  };

  const createMenuextravalidationSchema = Yup.object({
    extra: Yup.string().required("menu extra is required"),
  });

  const onSubmitCreateMenuextra = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}superadmin/${id1}/menuextra/menu/${id2}/`,
        values,
        headers(localStorage.getItem("token"))
      );
      toast.success("menu extra created successfully!");
      setCatgeory([]);
      resetForm();
      setModalCreateMenuextraOpen(false);
      refetch();
      setLoading(false);
    } catch (error) {
      toast.error("menu extra creation failed");
      setLoading(false);
      setModalCreateMenuextraOpen(false);
    }
  };

  const formikMenuextraCreate = useFormik({
    initialValues: createMenuextrainitialValues,
    validationSchema: createMenuextravalidationSchema,
    onSubmit: onSubmitCreateMenuextra,
  });

  return {
    openDropdownIndex,
    loading,
    isModalEditMenuextraOpen,
    isModalCreateMenuextraOpen,
    isModalDeleteMenuextraOpen,
    selectedEventId,
    Error,
    id1,
    id2,
    category,
    initialValues,
    menuextraid,
    formikUpdateMenuextra,
    menuextraUpdateinitialValue,
    menuextradata,
    menuextraloading,
    menuextraerror,
    setOpenDropdownIndex,
    refetch,
    setMenuExtraid,
    removeFromMenuExtra,
    openModalEditMenuextra,
    openModalDeleteMenuextra,
    openModalCreateMenuextra,
    closeModalEditMenuextra,
    closeModalDeletemenuextra,
    closeModalCreateMenuextra,
    handleDropdownToggle,
    createMenuextrainitialValues,
    formikMenuextraCreate,
  };
};

export default useAdminExtras;
