import styled from "styled-components";
import React  from "react";

export default function Ads() {

  return (
    <StyledAds>
      <div className="Xaxis">
        <img src="/images/comingsoon.png" className="" alt="coming-soon-icon" />
      </div>
    </StyledAds>
  );
}

const StyledAds = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;


  @media screen and (max-width: 912px) {
    img {
      width: 200px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 150px;
      height: auto;
    }
  }
`;
