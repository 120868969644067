import React, { useState } from "react";
import styled from "styled-components";
import "../../../App.css";
import { WorksButton } from "../components/WorksButton";
import { WorksSteps } from "../components/data/Steps";
import WorksModal from "../components/ModalBox/WorksModal";

export const HowItWorks = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <StyledHowItWorks>
      <div className="workdiv" id="how_it_works">
        <div className="textcontentdiv">
          <h1>How Wiserve Works</h1>
          <div className="childiv">
            <div>
              <p>As a Host/Event Planner</p>
            </div>
            <div>
              <img src="images/host_event_planner.png" className="host bouncing-element" />
            </div>
          </div>
          <div className="stepsdiv">
            {WorksSteps.map((step, index) => (
              <div key={index} className="steps">
                <div>
                  <img src={step.image} alt={`Step ${index + 1}`} />
                </div>
                <div>
                  <p>{step.text}</p>
                </div>
              </div>
            ))}
          </div>
          <WorksButton title="Play Prototype" onClick={openModal} />
        </div>
        <div className="workvideodiv">
          <img src="/images/how_it_works.png" className="howvideo bouncing-element" />
        </div>
      </div>
      {isModalOpen && <WorksModal onClose={closeModal} />}
    </StyledHowItWorks>
  );
};

const StyledHowItWorks = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5%;
  .workdiv {
    background-color: white;
    box-shadow: 0px 9px 58.568px 0px rgba(0, 0, 0, 0.05);
    border-radius: 35px;
    padding: 10px 8%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .textcontentdiv {
      margin: auto;
      & h1 {
        font-weight: 400;
        font-size: 36px;
        color: #2c2c2c;
        line-height: 44px;
        font-family: "MatterNormal", sans-serif;
      }

      & .childiv {
        display: flex;
        justify-items: center;
        align-items: center;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 10px;
        & p {
          text-align: left;
          font-size: 15px;
          font-weight: 400;
          font-family: "MatterNormal", sans-serif;
          color: #000;
        }
        & .host {
          width: auto;
          height: 120px;
          position: absolute;
          top: -42px;
          right: 120px;
        }
      }
      & .stepsdiv {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        & .steps {
          display: flex;
          /* justify-content: center; */
          align-items: center;

          gap: 1rem;
          margin-top: 10px;
        }
        & p {
          text-align: left;
          font-size: 15px;
          font-weight: 400;
          font-family: "MatterNormal", sans-serif;
          color: #000;
        }
        & img {
          width: auto;
          height: 20px;
          position: relative;
          top: 2px;
        }
      }
    }
    & .workvideodiv {
      & .howvideo {
        width: auto;
        height: 550px;
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    height: 50vh;
    /* margin-top: 25%; */

    .workdiv {
      & .textcontentdiv {
        & h1 {
          font-size: 30px;
        }

        & .childiv {
          & p {
            font-size: 16px;
          }
          & .host {
            width: auto;
            height: 120px;
            position: absolute;
            top: -42px;
            right: 85px;
          }
        }
        & .stepsdiv {
          & .steps {
            gap: 1.2rem;
          }
          & p {
            font-size: 16px;
          }
          & img {
            width: auto;
            height: 20px;
            position: relative;
            top: 2px;
          }
        }
      }
      & .workvideodiv {
        margin-top: 20px;
        & .howvideo {
          width: auto;
          height: 350px;
        }
      }
    }
  }
  @media (max-width: 640px) {
    height: 100vh;
    margin-top: 25%;

    .workdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .textcontentdiv {
        & h1 {
          font-size: 30px;
        }

        & .childiv {
          & p {
            font-size: 16px;
          }
          & .host {
            width: auto;
            height: 120px;
            position: absolute;
            top: -42px;
            right: 115px;
          }
        }
        & .stepsdiv {
          & .steps {
            gap: 1.2rem;
          }
          & p {
            font-size: 16px;
          }
          & img {
            width: auto;
            height: 20px;
            position: relative;
            top: 2px;
          }
        }
      }
      & .workvideodiv {
        margin-top: 20px;
        & .howvideo {
          width: auto;
        }
      }
    }
  }

  @media (max-width: 480px) {
    height: 100vh;
    margin-top: 35%;
    .workdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .textcontentdiv {
        & h1 {
          font-size: 30px;
        }

        & .childiv {
          & p {
            font-size: 18px;
          }
          & .host {
            width: auto;
            height: 120px;
            position: absolute;
            top: -42px;
            right: -30px;
          }
        }
        & .stepsdiv {
          & .steps {
            gap: 1.2rem;
          }
          & p {
            font-size: 17px;
            line-height: 150%;
            
          }
          & img {
            width: auto;
            height: 20px;
            position: relative;
            top: 2px;
          }
        }
      }
      & .workvideodiv {
        margin-top: 20px;
        & .howvideo {
          width: auto;
          height: 450px;
        }
      }
    }
  }

  @media (max-width: 350px) {
    height: 100vh;
    margin-top: 25%;
    .workdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .textcontentdiv {
        & h1 {
          font-size: 25px;
        }

        & .childiv {
          & p {
            font-size: 17px;
          }
          & .host {
            width: auto;
            height: 80px;
            position: absolute;
            top: -24px;
            right: -30px;
          }
        }
        & .stepsdiv {
          & .steps {
            gap: 1.2rem;
          }
          & p {
            font-size: 17px;
            line-height: 150%;
            
          }
          & img {
            width: auto;
            height: 20px;
            position: relative;
            top: 2px;
          }
        }
      }
      & .workvideodiv {
        margin-top: 20px;
        & .howvideo {
          width: auto;
          height: 450px;
        }
      }
    }
  }
`;
