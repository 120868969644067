import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../url";


const useHostOverview = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalUploadOpen, setModalUploadOpen] = useState(false);
  const [isModalActivationOpen, setModalActivationOpen] = useState(false);
  const [isModalMenuUploadedOpen, setModalMenuUploadedOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [uuiId, setSelectedUuid] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploads, setUploads] = useState(null);

  const openModal = () => {
    setModalUploadOpen(true);
  };

  const openModalMenuUploaded = () => {
    setModalMenuUploadedOpen(true);
  };
  const openModalRequest = () => {
    setModalActivationOpen(true);
  };

  const closeModal = () => {
    setModalUploadOpen(false);
  };

  const closeModalRequest = () => {
    setModalActivationOpen(false);
  };
  const closeModalMenuUploaded = () => {
    setModalMenuUploadedOpen(false);
  };
  const fetchHostEvent = (page, searchTerm) => {
    let url = `/host/event/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: hostdata,
    isLoading: hostloading,
    error: hosterror,
  } = useQuery(
    ["hostevent", pageNumber, searchTerm],
    () => fetchHostEvent(pageNumber, searchTerm),
    {
      enabled: true,
    }
  );

  const { data: userdata } = useQuery(
    "user",
    () => {
      return request.get("/user/", headers(localStorage.getItem("token")));
    },
    {
      enabled: true,
    }
  );

  const { data: approvedeventddata } = useQuery(
    "hostapprovedevent",
    () => {
      return request.get(
        "/host/approved-event/get/",
        headers(localStorage.getItem("token"))
      );
    },
    {
      enabled: true,
    }
  );

  const { data: ongoingeventddata } = useQuery(
    "host-ongoing-event",
    () => {
      return request.get(
        "/host/ongoing-event/get/",
        headers(localStorage.getItem("token"))
      );
    },
    {
      enabled: true,
    }
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  const handleDropdownToggle = (index, eventId, eventUuid, eventType) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedEventId(eventId);
    setSelectedEventType(eventType);
    setSelectedUuid(eventUuid);
  };

  const onSubmitRequestActivation = async (values) => {
    const payload = {
      event: selectedEventId,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}hostevent-request-activation/`,
        payload,
        headers(localStorage.getItem("token"))
      );
      toast.success("request sent !!! please wait for approval.");
      setModalActivationOpen(false);
      setLoading(false);
    } catch (error) {
      toast.error("you have requested. please wait for approval.");
      setLoading(false);
      setModalActivationOpen(false);
    }
  };

  const multipartConfig = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onSubmitUploadMenu = async () => {
    const payload = {
      upload_menu: selectedFile,
      event_id: selectedEventId,
      upload_note: formik.values.upload_note,
    };

    if (!selectedFile) {
      setUploadError("please select a file.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}post-upload-data/`,
        payload,
        multipartConfig
      );
      toast.success("menu data upload successful!");
      setModalUploadOpen(false);
      setLoading(false);
    } catch (error) {
      toast.error("menu data upload failed. Please try again later.");
      setModalUploadOpen(false);
      setLoading(false);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setUploadError(null);
  };

  const onSubmitUploadedMenus = async () => {
    try {
      const response = await axios.get(
        `${URL}uploads/${selectedEventId}/`,
        headers(localStorage.getItem("token"))
      );
      setUploads(response?.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedEventId) {
      onSubmitUploadedMenus();
    }
  }, [selectedEventId]);

  const formik = useFormik({
    initialValues: {
      upload_note: "",
    },
    onSubmit: onSubmitUploadMenu,
  });

  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    openDropdownIndex,
    loading,
    selectedFile,
    isModalUploadOpen,
    isModalActivationOpen,
    isModalMenuUploadedOpen,
    selectedEventId,
    uuiId,
    selectedEventType,
    uploadError,
    uploads,
    hostdata,
    hostloading,
    hosterror,
    userdata,
    approvedeventddata,
    ongoingeventddata,
    openModal,
    openModalMenuUploaded,
    openModalRequest,
    closeModal,
    closeModalRequest,
    closeModalMenuUploaded,
    handleSearch,
    handleDropdownToggle,
    onSubmitRequestActivation,
    onSubmitUploadMenu,
    handleFileSelect,
    openDropdownIndex,
    setOpenDropdownIndex,
    formik,
  };
};

export default useHostOverview;
