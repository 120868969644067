import React from "react";
import styled from "styled-components";

export const ContactButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <p>Send message</p>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ff5c00;
  background-color: #ff5c00;
  & p {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: #fff;
    line-height: 152%;
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 640px) {
    & p {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    padding: 5px;
    margin-bottom: 80px;
    & p {
      font-size: 18px;
    }
  }
`;
