import React, { useState } from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  padding: 0px 0px;
  width: 450px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  max-height: 100vh; /* Added maximum height to prevent modal from becoming too large */
  overflow-y: auto; /* Added overflow-y to enable scrolling when content exceeds max height */
  @media (max-width: 640px) {
    width: 70%;
    padding: 15px 15px;
  }
  @media (max-width: 480px) {
    width: 95%;
    padding: 0px 0px;
  }
  @media (max-width: 320px) {
    width: 97%;
    padding: 15px 10px;
  }
`;

const CloseButton = styled.button`
  position: relative;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const YoutubeIframe = styled.iframe`
  width: 100%;
  height: 300px;
  @media (max-width: 480px) {
    width: 100%;
  height: 300px;
  }
`;

const WorksModal = ({ onClose }) => {
  return (
    <ModalWrapper onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {/* <CloseButton onClick={onClose}>&times;</CloseButton> */}
        <YoutubeIframe
           src="https://www.youtube.com/embed/ulHSACpSUIA?si=ewgif-PGElCsI-GY"
          title="YouTube Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></YoutubeIframe>
      </ModalContent>
    </ModalWrapper>
  );
};

export default WorksModal;
