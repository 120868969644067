import React from "react";
import styled from "styled-components";

export const ModalTitle = ({
  backgroundColor,
  borderColor,
  textColor,
  imageSource,
  title,
}) => {
  return (
    <StyledTitle
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
    >
      <div>
        <img src={imageSource} alt="modal-icon" />
      </div>
      <div>
        <h1 className="hone">{title}</h1>
      </div>
    </StyledTitle>
  );
};

const StyledTitle = styled.div`
  padding: 4px 12px;
  border-radius: 41px;
  background-color: ${(props) => props.backgroundColor || "#fff5f0"};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => props.borderColor || "0.5px solid rgba(255, 98, 9, 1)"};
  gap: 1rem;
  .hone {
    font-size: 14px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: ${(props) => props.textColor || "rgba(255, 92, 0, 1)"};
  }
  img {
    width: auto;
    height: 1.4rem;
  }
`;
