import React from "react";
import styled from "styled-components";
import "../../../App.css";

export const WorksButton = ({ title, onClick }) => {
  return (
    <WorkButton
      // className="Xaxis"
      style={{
        border: "none",
        color: "white",
      }}
      onClick={onClick}
    >
      <div>{title}</div>
      <div>
        <img src="images/play.svg" alt="play icon" />
      </div>
    </WorkButton>
  );
};

const WorkButton = styled.button`
  margin-top: 30px;
  background-color: #2c2c2c;
  font-family: "MatterNormal", sans-serif;
  border-radius: 12px;
  height: 40px;
  width: 200px;
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (max-width: 912px) {
    height: 40px;
    font-size: 14px;
    margin-bottom: 15px;

  }

  @media screen and (max-width: 480px) {
    height: 50px;
    width: 240px;
    font-size: 17px;
    margin-top: 30px;
    
  }
`;
