import React from "react";
import styled, { css } from "styled-components";

export const ModalContentBox = ({ Content, title, disabled }) => {
  return (
    <>
      <Wrapper>
        <h1>{title}</h1>
      </Wrapper>
      <StyledContentBox disabled={disabled}>
        <div className="left">
          <img src="images/mdcal.svg" className="cal" alt="calender" />
        </div>
        <div>
          <p>{Content}</p>
        </div>
      </StyledContentBox>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  & h1 {
    font-size: 16px;
    font-weight: 400;
    font-family: "MatterMedium", sans-serif;
    color: rgba(62, 62, 62, 1);
  }
`;

const StyledContentBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px 20px;
  background-color: rgba(247, 248, 255, 1);
  border: 1px dotted rgba(65, 130, 255, 1);
  border-radius: 12px;
  cursor: pointer; /* Default cursor */

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5; /* Dim the content when disabled */
      pointer-events: none; /* Disable pointer events */
      cursor: not-allowed; /* Change cursor to not-allowed */
    `}

  & p {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(62, 62, 62, 1);
    text-transform: lowercase;
  }

  & .left {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & .cal {
      width: auto;
      height: 1.8rem;
    }
  }

  @media (max-width: 480px) {
    & p {
      font-size: 15px !important;
    }
  }
`;
