import React from "react";
import styled from "styled-components";
import "../../../App.css";

export const BigText = ({ title }) => {
  return (
    <StyledBigText>
      <h4 className="text">{title}</h4>
    </StyledBigText>
  );
};

const StyledBigText = styled.div`
  color: #000000;
  .text {
    font-weight: 400;
        font-size: 36px;
        color: #2c2c2c;
        line-height: 44px;
        font-family: "MatterNormal", sans-serif;
  }




  @media (max-width: 1200px) {
    .text {
      font-size: 30px;
    }
  }
`;
