import React from "react";
import styled from "styled-components";
import { Accordion } from "../components/Accordion";
import { BigText } from "../components/BigText";

export const Faqs = () => {
  return (
    <StyledFaqs className="mt-2" id="faqs">
      <div>
        <div className="d-flex flex-column align-items-center">
          <BigText title="FAQ's" />
          <div className="mt-5">
            <h6 className="mt-2 subheading">
              Most common questions customers might have and is useful to
              customers at all stages of the customer journey
            </h6>
          </div>
        </div>
        <Accordion
          title="How long will it take for my guest to receive their orders?"
          body="It takes approximately 5-10 minutes for the order to be delivered."
        />
        {/* <Accordion2/> */}
        <Accordion
          title="How long does it take for my account to be activated?"
          body="The first step of the activation process is receiving a onboarding email from the Wiserve team which takes approximately 24-48 hours after which payment should be made. Your account becomes fully activated after payment has been confirmed."
        />
        <Accordion
          title="What if my event is not in Lagos, can i still use Wiserve?"
          body="Yes of course, Wiserve can be integrated outside of Lagos. However, additional charges will be incurred. Kindly reach out to the team via emali or call to discuss your concerns"
        />
        <Accordion
          title="Is barcode the only access to the food menu?"
          body="No, Wiserve allows the scanning of barcode and inputting authorised short codes to access the platform. Please note that Wiserve is all about personalization therefore as a host or event planner, you can customize the features on Wiserve to better suit your guests' needs."
        />
      </div>
    </StyledFaqs>
  );
};

const StyledFaqs = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 5%;

  .subheading {
    padding: 0% 20%;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: #000;
    line-height: 152%;
  }

  @media (max-width: 1024px) {
    height: 50vh;

    .subheading {
      font-size: 16px;
      padding: 0% 18%;
    }
  }

  @media (max-width: 640px) {
    height: 100vh;
    .subheading {
      font-size: 16px;
      padding: 0% 12%;
    }
  }

  @media (max-width: 480px) {
    
    .subheading {
      font-size: 17px;
      padding: 0% 5%;
    }
  }
  @media (max-width: 350px) {
    margin-top: 180px!important;

    .subheading {
      font-size: 17px;
      padding: 0% 5%;
    }
  }
`;
