import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 820px) {
    #text-danger {
    color: red;
    margin-top:5px;
    text-align: right;
    font-size: 18px;
    /* letter-spacing:1px; */
    font-family: "MatterNormal", sans-serif;

  }
  }

  @media (max-width: 640px) {
    #text-danger {
    font-size: 17px;
  }
  }

  @media (max-width: 480px) {
    #text-danger {
    font-size: 17px;
  }
  }
`;

const StyledInput = styled.input`
  background-color: #e9e9e9;
  border-radius: 10px;
  border:none;
  font-size: 20px;
  color:#969696;
  font-family: "MatterNormal", sans-serif;
  padding-left:17px;
  height:60px;
  &:focus {
    outline: none; /* Remove default outline */
    border: 0.5px solid #FF5C00; /* Add the orange border */
  }

 

 

  @media screen and (max-width: 640px) {
    padding-left:18px;
    height:50px!important;
    font-size: 17px;
    #text-danger {
    color: red;
    font-size: 17px;
  }
  
}
`;

function Input({value, onChange,placeholder,type,name,disabled,error}) {

  return (
    <InputContainer>
      <StyledInput
        type={type}
        id="myInput"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
      />
          {error && <p id="text-danger">{error}</p>}
    </InputContainer>
  );
}

export default Input;
