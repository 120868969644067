import React from "react";
import styled from "styled-components";

const Card = ({ name, img, onClick, addToCart, finished, opacity }) => {
  return (
    <CardContainer
      style={{
        opacity: opacity,
        backgroundImage: `url(${img})`,
      }}
    >
      <p className="fin">{finished}</p>

      <div className="div2">
        <div onClick={onClick}>
          <p className="foodname">{name}</p>
        </div>
        <div className="button" onClick={addToCart}>
          <img src="/images/addcart.png" className="add" />
        </div>
      </div>
    </CardContainer>
  );
};

export { Card };

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  .div2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #fff;
    opacity: 0.9;
    padding: 7px 15px;
    gap: 1rem;
    position: relative;
  }

  .foodname {
    font-family: "MatterNormal", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .fin {
    color: red;
    font-family: "MatterMedium", sans-serif;
    margin: auto;
  }
  @media screen and (max-width: 812px) {
    width: 230px;
    height: 240px;
    .foodname {
      font-size: 20px;
    }
    .add {
      width: auto;
      height: 2.5rem;
    }

    .fin {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 130px;
    height: 135px;
    .foodname {
      font-size: 12px;
    }
    .fin {
      font-size: 12px;
    }
    .add {
      width: auto;
      height: 1.2rem;
    }
  }

  @media screen and (max-width: 640px) {
    width: 155px;
    height: 160px;
    .foodname {
      font-size: 17px;
    }
    .fin {
      font-size: 17px;
    }
    .add {
      width: auto;
      height: 2rem;
    }
  }
`;
