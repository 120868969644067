import React from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { dateConverter } from "../../../../utils/dateTimeConverter";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { ModalDropDown } from "../../components/ModalBox/ModalDropDown";
import useAdminVendor from "../../../../utils/apilogics/admin/overview/vendorLogic";
import StatusHandler from "../../../../utils/statusHandler";
import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledNavLink,
  StyledLink,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/vendorstyles";

export const AdminVendor = () => {
  const {
    pageNumber,
    setPageNumber,
    openDropdownIndex,
    loading,
    isModalEditVendorOpen,
    isModalCreateVendorOpen,
    isModalDeleteVendorOpen,
    dropError,
    catid,
    category,
    vendordata,
    vendorloading,
    vendorerror,
    handleSearch,
    openModalEditVendor,
    openModalCreateVendor,
    openModalDeleteVendor,
    closeModalEditVendor,
    closeModalDeleteVendor,
    closeModalCreateCategory,
    handleDropdownToggle,
    removeFromVendor,
    setSelectedCategory,
    setOpenDropdownIndex,
    id,
    setCatId,
    setVendorId,
    setDropError,
    formikUpdateVendor,
    formikVendorCreate,
  } = useAdminVendor();
  return (
    <Wrapper>
      {isModalEditVendorOpen && (
        <form onSubmit={formikUpdateVendor.handleSubmit}>
          <Modal
            title="Edit Category"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditVendor}
          >
            <div className="wrapper-div">
              <Input
                label="Vendor Name"
                type="text"
                name="vendor_name"
                onChange={formikUpdateVendor.handleChange}
                value={formikUpdateVendor.values.vendor_name}
                error={formikUpdateVendor.errors.vendor_name}
              />
            </div>
            <Input
              label="Vendor code"
              type="text"
              name="passcode"
              onChange={formikUpdateVendor.handleChange}
              value={formikUpdateVendor.values.passcode}
              error={formikUpdateVendor.errors.passcode}
            />
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteVendorOpen && (
        <Modal
          title="Delete Vendor"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeleteVendor}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="update"
              onClick={() => removeFromVendor(catid)} // Pass categoryIdToDelete here
            />
          </div>
        </Modal>
      )}
      {isModalCreateVendorOpen && (
        <form onSubmit={formikVendorCreate.handleSubmit}>
          <Modal
            title="Create Vendors"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateCategory}
          >
            <div className="wrapper-div">
              <ModalDropDown
                options={category?.map((e, index) => ({
                  label: e.label,
                  value: e.value,
                }))}
                //   options={category}
                defaultOption={{ label: "Select Vendor", value: null }}
                onSelect={(selectedOption) => {
                  setSelectedCategory(selectedOption.value);
                  setDropError(null);
                }}
                error={dropError}
              />
              <div className="mt-4">
                <Input
                  label="Vendor Name"
                  type="text"
                  name="vendor_name"
                  onChange={formikVendorCreate.handleChange}
                  value={formikVendorCreate.values.vendor_name}
                  error={formikVendorCreate.errors.vendor_name}
                  placeholder="enter vendor name"
                />
              </div>
            </div>
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick={`/dashboard-admin/${id}/menu-categories`}
            show
            title="create vendor"
            onClick={openModalCreateVendor}
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Vendors"
            onChange={handleSearch}
            placeholder="search vendor"
            {...(!vendorloading && {
              vendor:
                vendordata && vendordata?.data?.results.length === 0
                  ? ""
                  : vendordata,
            })}
          >
            <StatusHandler isError={vendorerror} isLoading={vendorloading}>
              {vendordata && vendordata?.data?.results.length < 1 ? (
                <ModalEmpty title="no vendor found" />
              ) : (
                vendordata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="category name">{e.vendor_name}</td>
                    <td data-cell="category">{e.cat_name}</td>
                    <td data-cell="vendor code">{e.passcode}</td>
                    <td data-cell="updated">{dateConverter(e.updated_at)}</td>
                    <td data-cell="created">{dateConverter(e.created_at)}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setCatId(e.id);
                                openModalEditVendor();
                              }}
                              className="nav-link"
                            >
                              edit vendor
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setVendorId(e.id);
                                openModalDeleteVendor();
                              }}
                            >
                              delete vendor
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {vendordata && vendordata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={vendordata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
