import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { URL } from '../../url';

const useTriviaQ = () => {
  const [showQuiz, setShowQuiz] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(120);
  const [secondsUsed, setSecondsUsed] = useState(0);
  const [eventId, setEventId] = useState("");
  const [active, setActive] = useState(false);
  const [questions, setQuestions] = useState([]);

// console.log(questions,"myquest")
  useEffect(() => {
    const storedEventId = localStorage.getItem("eventId");
    if (storedEventId) {
      setEventId(storedEventId);
    }
  }, []);

  useEffect(() => {
    const fetchTriviaEvent = async () => {
      try {
        const response = await axios.get(`${URL}trivia/get-event/${eventId}`);
        setActive(response.data.trivia_active);
      } catch (error) {
        console.error(error);
      }
    };

    if (eventId) {
      fetchTriviaEvent();
    }
  }, [eventId]);

  useEffect(() => {
    let countdownInterval;
    if (showQuiz && !showScore) {
      countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
        setSecondsUsed((prevSecondsUsed) => prevSecondsUsed + 1);
      }, 1000);
    }
    return () => clearInterval(countdownInterval);
  }, [showQuiz, showScore]);

  const postScoreToEndpoint = useCallback((score) => {
    const data = {
      trivia_name: playerName,
      trivia_score: score,
      trivia_time: secondsUsed,
    };
    axios
      .post(`${URL}trivia-post/${eventId}/`, data)
      .then((response) => {
        toast.success("trivia result posted successfully!!!");
      })
      .catch((error) => {
        console.error("Error sending score:", error);
      });
  }, [playerName, secondsUsed, eventId]);

  useEffect(() => {
    if (timer === 0) {
      setShowScore(true);
      postScoreToEndpoint(score);
    }
  }, [timer, postScoreToEndpoint, score]);

  const handleStart = async () => {
    try {
      const response = await axios.get(`${URL}trivia-questions/${eventId}/`);
    //   console.log(response.data);
      setQuestions(response.data);
      setShowNameInput(true);
    } catch (error) {
    //   console.error(error);
    }
  };

  const handleNameSubmit = (name) => {
    setPlayerName(name);
    setShowQuiz(true);
  };

  const handleAnswerOptionClick = (isCorrect) => {
    const newScore = isCorrect ? score + 1 : score;
    setScore(newScore);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
      postScoreToEndpoint(newScore);
    }
  };

  return {
    showQuiz,
    showNameInput,
    showScore,
    questions,
    currentQuestion,
    playerName,
    score,
    timer,
    active,
    secondsUsed,
    handleStart,
    handleNameSubmit,
    handleAnswerOptionClick,
  };
};

export default useTriviaQ;
