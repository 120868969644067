import React, { useState } from "react";
import styled from "styled-components";

export const ModalNote = ({ note }) => {
  return (
    <StyledNote>
      <img src="/images/nt.png" alt="icon" />
      <p>{note}</p>
    </StyledNote>
  );
};

const StyledNote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 25px;
  margin-top: 10px;
  margin-bottom: 20px;

  height: 110px;
  border-radius: 12px;
  border: 1px solid rgba(239, 239, 239, 1);
  position: relative;
  & img {
    width: auto;
    height: 25px;
    position: absolute;
    left: 5px;
    top: 5px;
  }
  & p {
    font-size: 12px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(144, 155, 182, 1);
    line-height: 19.5px;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 640px) {
    height: 150px;
  }
  @media (max-width: 480px) {
    height: 160px;
    & p {
      font-size: 15px;
    }
  }
`;
