import React, { useState } from "react";
import styled from "styled-components";

export const ModalDropDown = ({ options, defaultOption, onSelect, error }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsActive(false);
  };

  return (
    <WrapperDrop>
      <StyledDrop onClick={() => setIsActive(!isActive)} isActive={isActive}>
        <div className="left">
          <p>{selectedOption.label}</p>
        </div>
        <div>
          <img
            src={isActive ? "/images/mddropup.svg" : "/images/mddropdown.svg"}
            className="dropd"
            alt="dropdown"
          />
        </div>
      </StyledDrop>
      {error && <p className="error">{error}</p>}
      <DropWrapper isActive={isActive}>
        {options?.map((option, index) => (
          <p key={index} onClick={() => handleOptionSelect(option)}>
            {option.label}
          </p>
        ))}
      </DropWrapper>
    </WrapperDrop>
  );
};

export const ModalDropDown2 = ({
  options,
  defaultOption,
  onSelect,
  error,
  placeholder,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [searchTerm, setSearchTerm] = useState("");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsActive(false);
  };

  const filteredOptions = options
    .filter((option) => {
      const label = option.label.toString(); // Convert label to string
      return label.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .slice(0, 5);
  return (
    <WrapperDrop className="mb-4">
      <StyledDrop>
        {isActive ? (
          <SearchInput
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : (
          <div className="left">
            <p>{selectedOption.label}</p>
          </div>
        )}

        <div onClick={() => setIsActive(!isActive)} isActive={isActive}>
          <img
            src={isActive ? "/images/mddropup.svg" : "/images/mddropdown.svg"}
            className="dropd"
            alt="dropdown"
          />
        </div>
      </StyledDrop>
      {error && <p className="error">{error}</p>}
      <DropWrapper isActive={isActive}>
        {filteredOptions.map((option, index) => (
          <p key={index} onClick={() => handleOptionSelect(option)}>
            {option.label}
          </p>
        ))}
      </DropWrapper>
    </WrapperDrop>
  );
};

export const ModalDropDown3 = ({
  options,
  defaultOption,
  onSelect,
  error,
  label,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsActive(false);
  };

  return (
    <WrapperDrop2>
      <label className="label mb-2">{label}</label>
      <StyledDrop2 onClick={() => setIsActive(!isActive)} isActive={isActive}>
        <div className="left">
          <p>{selectedOption.label}</p>
        </div>
        <div>
          <img
            src={isActive ? "/images/mddropup.svg" : "/images/mddropdown.svg"}
            className="dropd"
            alt="dropdown"
          />
        </div>
      </StyledDrop2>
      {error && <p className="error">{error}</p>}
      <DropWrapper isActive={isActive}>
        {options?.map((option, index) => (
          <p key={index} onClick={() => handleOptionSelect(option)}>
            {option.label}
          </p>
        ))}
      </DropWrapper>
    </WrapperDrop2>
  );
};

const WrapperDrop = styled.div`
  & .error {
    font-size: 14px;
    font-weight: 400;
    color: rgba(242, 93, 93, 1);
    font-family: "MatterNormal", sans-serif;
    text-align: right;
    margin-top: 10px;
  }
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }
  @media (max-width: 640px) {
    width: 100%;
    .label {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    & .error {
      font-size: 15px !important;
    }

    .label {
      font-size: 18px;
    }
  }
`;
const WrapperDrop2 = styled.div`
  margin-bottom: 10px;
  & .error {
    font-size: 14px;
    font-weight: 400;
    color: rgba(242, 93, 93, 1);
    font-family: "MatterNormal", sans-serif;
    text-align: right;
    margin-top: 10px;
  }
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }
  @media (max-width: 640px) {
    width: 100%;
    .label {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    & .error {
      font-size: 15px !important;
    }

    .label {
      font-size: 17px;
    }
  }
`;
const DropWrapper = styled.div`
  overflow: hidden;
  max-height: ${({ isActive }) => (isActive ? "400px" : "0px")};
  transition: max-height 1s ease-in-out;
  margin-top: 10px;
  padding: 0px 20px;

  & p {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: rgba(62, 62, 62, 1);
    margin: 0;
    padding: 5px 0px;
    cursor: pointer;
    &:hover {
      color: #ff5c00;
    }
  }
  @media (max-width: 640px) {
    p {
      font-size: 17px;
    }
  }
`;

const StyledDrop = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 12px;
  cursor: pointer;

  & .dropd {
    width: auto;
    height: 2rem;
    transition: transform 0.3s ease-in-out;
    transform: ${({ isActive }) =>
      isActive ? "rotate(180deg)" : "rotate(0deg)"};
  }

  & .left {
    display: flex;
    align-items: center;

    & select {
      width: auto;
      height: 1.6rem;
      margin-left: 10px;
    }

    & p {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      font-family: "MatterNormal", sans-serif;
      color: rgba(62, 62, 62, 1);
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    & p {
      font-size: 15px !important;
    }
  }
`;

const StyledDrop2 = styled.div`
  /* margin-top: 25px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 12px;
  cursor: pointer;

  & .dropd {
    width: auto;
    height: 2rem;
    transition: transform 0.3s ease-in-out;
    transform: ${({ isActive }) =>
      isActive ? "rotate(180deg)" : "rotate(0deg)"};
  }

  & .left {
    display: flex;
    align-items: center;

    & select {
      width: auto;
      height: 1.6rem;
      margin-left: 10px;
    }

    & p {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      font-family: "MatterNormal", sans-serif;
      color: rgba(62, 62, 62, 1);
      margin: 0;
    }
  }

  @media (max-width: 640px) {
    & .dropd {
      height: 27px;
    }
    & p {
      font-size: 17px !important;
    }
  }
`;

const SearchInput = styled.input`
  width: 60%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: "MatterNormal", sans-serif;

  &:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 92, 0, 1);
  }

  /* Cut off other sides */
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
  }
`;
