import styled from "styled-components";
import { NavLink,Link } from "react-router-dom";

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 100vh;

  & .format {
    width: auto;
    height: 195px;
    margin-top: 20px;
  }
  & .wrapper-div {
    margin-top: 30px;
  }

  & .input-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    gap:1rem;
  }
  & .input-fle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    gap: 1.5rem;
    & .wrapper-child {
      width: 100%;
    }
  }
  & .align {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 640px) {
    & .format {
      width: auto;
      height: 175px;
    }
    .input-fle {
      display: flex;
      flex-direction: column;
      & .wrapper-child {
        width: 100%;
      }
    }
  }
  @media (max-width: 480px) {
    .input-fle {
      display: flex;
      flex-direction: column;
      & .wrapper-child {
        width: 100%;
      }
    }
    & .format {
      width: auto;
      height: 130px;
    }
  }
  @media (max-width: 320px) {
    & .format {
      width: auto;
      height: 95px;
    }
  }
`;
export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1170px) {
  }
`;

export const MainContent = styled.div`
  width: 100%;
  background-color: #f0f4f7;
  padding-bottom: 40px;
  height: 100%;
  overflow-y: auto;
  
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .pagination-bar {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  .more {
    display: inline-block;
    vertical-align: middle;
  }
  .stat {
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  @media (max-width: 1400px) {
    .more {
      margin-top: -8px;
    }
  }
  @media (max-width: 540px) {
    .stat {
      margin-bottom: 20px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .more {
      margin-top: -8px;
    }
  }
  @media (max-width: 480px) {
    .stat {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .more {
      margin-top: -8px;
    }
  }
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: -200px;
  width: 200px;
  right: 0px;
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  display: none;
  z-index: 1;
  &.open {
    display: block;
  }

  @media (max-width: 912px) {
    width: 270px;
  }
  @media (max-width: 640px) {
    width: 250px;
  }
  @media (max-width: 480px) {
    width: 270px;
  }

  @media (max-width: 350px) {
    width: 180px;
  }
`;

export const StyledLink2 = styled.span`
  color: rgba(144, 155, 182, 1);
  cursor: pointer;
  &:hover {
    color: rgba(255, 92, 0, 1);
  }
`;
export const DropdownToggle = styled.span`
  cursor: pointer;
`;

export const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
`;

export const StyledLink = styled.span`
  color: rgba(69, 90, 100, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  & img {
    width: auto;
    height: 1.2rem;
  }

  &:hover {
    color: rgba(255, 92, 0, 1);
  }

  @media (max-width: 640px) {
    & img {
      width: auto;
      height: 1.5rem;
    }
  }
  @media (max-width: 480px) {
    & img {
      width: auto;
      height: 1.5rem;
    }
  }
`;
export const StyledNavLink = styled(NavLink)`
  color: rgba(69, 90, 100, 1);
  text-decoration: none;
  &:hover {
    color: rgba(255, 92, 0, 1);
  }
`;
export const StyledNavLink2 = styled(Link)`
  color: rgba(69, 90, 100, 1);

  text-decoration: none;
  &:hover {
    color: blue;
  }

  small {
    font-size: 14px;
  }
  @media (max-width: 640px) {
    small {
      font-size: 18px;
      word-break: break-word;
    }
  }
  @media (max-width: 480px) {
    small {
      font-size: 18px;
      word-break: break-word;
    }
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    list-style: none;
    padding: 10px;
    margin: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #fff;
    display: none;

    &.open {
      display: block;
    }

    @media (max-width: 912px) {
      width: 270px;
    }
    @media (max-width: 640px) {
      width: 250px;
    }
    @media (max-width: 480px) {
      width: 250px;
    }

    @media (max-width: 350px) {
      width: 180px;
    }
  }
`;
