import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ShortenedURL from "./ShortenedUrl";
import { useParams } from "react-router-dom";
import axios from "axios";
import { headers } from "../../../../utils/request";
import { URL } from "../../../../utils/url";

export const DetailsBox = ({ userType }) => {
  const [event, setEvent] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    if (userType === "admin") {
      fetchAdminEvent(id);
    } else if (userType === "host") {
      fetchHostEvent(id);
    }
  }, [id, userType]);

  const fetchHostEvent = async (id) => {
    try {
      const response = await axios.get(
        `${URL}host/event-details/${id}/`,
        headers(localStorage.getItem("token"))
      );
      const datas = response.data;
      setEvent(datas);
    } catch (error) {}
  };

  const fetchAdminEvent = async (id) => {
    try {
      const response = await axios.get(
        `${URL}superadmin/event-details/${id}/`,
        headers(localStorage.getItem("token"))
      );
      const datas = response.data;
      setEvent(datas);
    } catch (error) {
      console.log(error);
    }
  };

  const formatTime = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}`);
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  return (
    <StyledDetails>
      {event && (
        <>
          <div className="leftdiv">
            <div className="header-info">
              <h1>Event Info</h1>
            </div>
            <div className="line"></div>
            <div className="content">
              <div className="column">
                <div>
                  <h1>Owner</h1>
                </div>
                <div className="mt-2">
                  <p>
                    {event.firstname} {event.lastname}
                  </p>
                </div>
              </div>
              <div className="column">
                <div>
                  <h1>Type</h1>
                </div>
                <div className="mt-2">
                  <p>{event.event_type}</p>
                </div>
              </div>
            </div>

            <div className="content">
              <div className="column">
                <div>
                  <h1>Guest no</h1>
                </div>
                <div className="mt-2">
                  <p>{event.event_guest}</p>
                </div>
              </div>
              <div className="column">
                <div>
                  <h1>Tag</h1>
                </div>
                <div className="mt-2">
                  <p>{event.event_tag}</p>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="column">
                <div>
                  <h1>Banner</h1>
                </div>
                <div className="mt-2">
                  <p>
                    <ShortenedURL content={event.even_background} />
                  </p>
                </div>
              </div>
              <div className="column">
                <div>
                  <h1>Toast</h1>
                </div>
                <div className="mt-2">
                  <p>{event.event_toast}</p>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="header-info">
                <h1>Event Menu</h1>
              </div>
              <div className="line"></div>

              <div className="content">
                <div className="column">
                  <div>
                    <h1>Menu Upload</h1>
                  </div>
                  <div className="mt-2">
                    <p>
                      {event.upload_data &&
                        event.upload_data.map((e, index) => (
                          <p key={index}>
                            <ShortenedURL content={e.upload_menu} />
                          </p>
                        ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rightdiv">
            <div className="wrapper">
              <div className="header-info">
                <h1>Event Date, Time & Venue</h1>
              </div>
              <div className="line"></div>
              <div className="content">
                <div className="column">
                  <div>
                    <h1>Date</h1>
                  </div>
                  <div className="mt-2">
                    <p>{event.event_date}</p>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <h1>Street Address</h1>
                  </div>
                  <div className="mt-2">
                    <p>{event.street_address}</p>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <h1>State</h1>
                  </div>
                  <div className="mt-2">
                    <p>{event.state}</p>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  <div>
                    <h1>Time</h1>
                  </div>
                  <div className="mt-2">
                    <p>{formatTime(event.event_time)}</p>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <h1>Country</h1>
                  </div>
                  <div className="mt-2">
                    <p>{event.country}</p>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <h1>City</h1>
                  </div>
                  <div className="mt-2">
                    <p>{event.city}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="header-info">
                <h1>Event Code, Qr-code</h1>
              </div>
              <div className="line"></div>
              <div className="content2">
                <div className="column">
                  <div>
                    <h1>Qr-code image</h1>
                  </div>
                  <div className="mt-2">
                    <p id="qr">
                      <ShortenedURL content={event.qr_code_image} />
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <h1>Code</h1>
                  </div>
                  <div className="mt-2 codewrapper">
                    <div className="codetext">
                      <p>
                        Event
                        <br />
                        code
                      </p>
                    </div>
                    <div>
                      <p id="code">{event.event_code}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </StyledDetails>
  );
};

const StyledDetails = styled.div`
  width: 95%;
  margin: 20px;
  border-radius: 25px;
  background-color: #f0f4f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  .img {
    height: 250px;
    width: auto;
  }
  .leftdiv {
    border-radius: 12px;
    padding: 20px 30px;
    width: 50%;
    background-color: white;
    & .line {
      height: 5px;
      border: none;
      border-bottom: 1px solid rgba(255, 92, 0, 1);
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
    }
    & h1 {
      font-family: "MatterMedium", sans-serif;
      font-size: 17px;
      color: #000;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    & .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      & .column {
      }
      & h1 {
        font-family: "MatterMedium", sans-serif;
        font-size: 17px;
        color: #000;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & p {
        font-size: 14px;
        font-style: normal;
        font-family: "MatterNormal", sans-serif;
        font-weight: 400;
        color: rgba(144, 155, 182, 1);
      }
    }
  }
  .rightdiv {
    border-radius: 12px;
    padding: 20px 30px;
    width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & .line {
      height: 5px;
      border: none;
      border-bottom: 1px solid rgba(255, 92, 0, 1);
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
    & h1 {
      font-family: "MatterMedium", sans-serif;
      font-size: 17px;
      color: #000;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    & .content2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      & #qr {
        font-size: 14px;
        font-style: normal;
        font-family: "MatterNormal", sans-serif;
        font-weight: 400;
        color: rgba(144, 155, 182, 1);
      }
      & .codewrapper {
        background-color: rgba(255, 236, 226, 1);
        border-radius: 10px;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        border: 1px dotted rgba(255, 92, 0, 1);
        & .codetext {
          background-color: rgba(255, 92, 0, 1);
          padding: 5px 15px;
          border-radius: 10px;
        }
      }

      & #code {
        color: rgba(255, 92, 0, 1);
        padding: 0px 10px;
        font-family: "MatterNormal", sans-serif;
        font-weight: 400;
        font-size: 14px;
      }
      & h1 {
        font-family: "MatterMedium", sans-serif;
        font-size: 17px;
        color: #000;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & p {
        font-size: 12px;
        font-style: normal;
        font-family: "MatterNormal", sans-serif;
        font-weight: 400;
        color: white;
      }
    }
    & .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      display: flex;

      & h1 {
        font-family: "MatterMedium", sans-serif;
        font-size: 17px;
        color: #000;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & p {
        font-size: 14px;
        font-style: normal;
        font-family: "MatterNormal", sans-serif;
        font-weight: 400;
        color: rgba(144, 155, 182, 1);
      }
    }
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-right: 0px;
    }
    .leftdiv {
    }
    .rightdiv {
    }
    .img {
      height: 170px;
      width: auto;
    }
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    gap: 0rem;

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .leftdiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      & .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        & .column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        & p {
          font-size: 16px;
        }
      }
    }

    .rightdiv {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & .content2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
        & .column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        & #qr {
          font-size: 16px;
        }
        & .codewrapper {
          gap: 0rem;

          & .codetext {
            background-color: rgba(255, 92, 0, 1);
            padding: 0px 15px;
            height: 50px;
          }
        }

        & #code {
          padding: 0px 10px;
          margin-top: 17px;
          font-size: 17px;
        }

        & h1 {
          font-size: 18px;
        }
        & p {
          font-size: 16px;
        }
      }
      & .content {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        & .column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        & p {
          font-size: 16px;
        }
      }
    }
    .img {
      height: 250px;
      width: auto;
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }
`;
