import React from "react";
import styled from "styled-components";

const RadioBox = styled.div`
  /* height: 174px;
  position: relative;
  width: 100%; */
  margin-top: 14px;
`;

const Options = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Option = styled.label`
  display: flex;
  align-items: center;
  margin: 10px 10px 10px 23px;
  cursor: pointer;
`;

const RadioInput = styled.input`
  display: none;
`;

const CustomRadio = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #FF5C00;
  /* background-color: yellow; */
  margin-right: 10px;
  cursor: pointer;

  ${RadioInput}:checked + & {
    background-color: #FF5C00;
    
  }
`;

const TextWrapper = styled.div`
  color: #383838;
  font-family: "MatterNormal", sans-serif;
  font-size: 20px;
  font-weight: 400;

  @media screen and (max-width: 640px) {
  font-size: 17px;
  }

  @media screen and (max-width: 480px) {
  font-size: 17px;

  }
`;



const Radio = ({ options, selectedOption, onOptionChange, className }) => {
  return (
    <RadioBox className={className}>
      <Options>
        {options.map((option) => (
          <Option key={option.value}>
            <RadioInput
              type="radio"
              name="radioOption"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => onOptionChange(option.value)}
            />
            <CustomRadio />
            <TextWrapper>{option.label}</TextWrapper>
          </Option>
        ))}
      </Options>
    </RadioBox>
  );}

export default Radio;
