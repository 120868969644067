import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const useAdminVendor = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalEditVendorOpen, setModalEditVendorOpen] = useState(false);
  const [isModalCreateVendorOpen, setModalCreateVendorOpen] = useState(false);
  const [isModalDeleteVendorOpen, setModalDeleteVendorOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [Error, setError] = useState(null);
  const [dropError, setDropError] = useState(null);
  const { id } = useParams();
  const [catid, setCatId] = useState(null);
  const [vendorid, setVendorId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [category, setCatgeory] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (catid) {
      axios
        .get(
          `${URL}superadmin/vendor/${catid}/rud/`,
          headers(localStorage.getItem("token"))
        )
        .then((response) => {
          setInitialValues(response.data);
          formikUpdateVendor.setValues(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [catid]);
  const vendorUpdateinitialValue = {
    vendor_name: initialValues?.menu_name || "",
    passcode: initialValues?.passcode || "",
  };

  const onSubmitUpdateVendor = async (values, { resetForm }) => {
    const payload = {
      vendor_name: values.vendor_name,
      passcode: values.passcode,
    };

    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}superadmin/vendor/${catid}/rud/`,
        payload,
        headers(localStorage.getItem("token"))
      );

      toast.success("vendor updated successfully!");
      resetForm();
      refetch();
      setLoading(false);
      setModalEditVendorOpen(false);
    } catch (error) {
      toast.error("vendor update failed");
      setLoading(false);
    }
  };
  const formikUpdateVendor = useFormik({
    initialValues: vendorUpdateinitialValue,
    onSubmit: onSubmitUpdateVendor,
  });

  useEffect(() => {
    if (!category) {
      return;
    } else {
      mapData();
    }
  }, []);
  const mapData = async () => {
    try {
      const response = await axios.get(
        `${URL}superadmin/menucategory-no-pg/${id}/`,
        headers(localStorage.getItem("token"))
      );
      const st = response?.data?.map((e) => ({
        label: e.cat_name,
        value: e.id,
      }));
      setCatgeory(st);
    } catch (error) {
      setCatgeory([]);
    }
  };

  const fetchVendor = (page, searchTerm) => {
    let url = `/superadmin/${id}/vendor/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const { data: vendordata,
    isError:vendorerror,
    isLoading:vendorloading,
    refetch } = useQuery(
    ["vendors", pageNumber, searchTerm],
    () => fetchVendor(pageNumber, searchTerm)
  );
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  const removeFromVendor = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${URL}superadmin/vendor/${vendorid}/rud/`,
        headers(localStorage.getItem("token"))
      );
      const datas = response.data;
      toast.success("Vendor deleted successfully!");
      refetch();
      setLoading(false);

      setModalDeleteVendorOpen(false);
    } catch (error) {
      toast.error("Vendor delete failed");
      setLoading(false);
    }
  };

  const openModalEditVendor = () => {
    setModalEditVendorOpen(true);
  };

  const openModalDeleteVendor = () => {
    setModalDeleteVendorOpen(true);
  };
  const openModalCreateVendor = () => {
    setModalCreateVendorOpen(true);
  };

  const closeModalEditVendor = () => {
    setModalEditVendorOpen(false);
  };
  const closeModalDeleteVendor = () => {
    setModalDeleteVendorOpen(false);
  };
  const closeModalCreateCategory = () => {
    setModalCreateVendorOpen(false);
  };
  const handleDropdownToggle = (index, id) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedEventId(id);
  };

  const createVendorvalidationSchema = Yup.object({
    vendor_name: Yup.string().required("vendor name is required"),
  });

  const createVendorinitialValues = {
    vendor_name: "",
  };

  const onSubmitCreateVendor = async (values) => {
    values.category_id = selectedCategory;
    values.event_uuid = id;
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}superadmin/vendor/create/`,
        values,
        headers(localStorage.getItem("token"))
      );
      toast.success("vendor created successfully!");
      setCatgeory([]);
      mapData();
      setModalCreateVendorOpen(false);
      refetch();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("vendor creation failed");
      setLoading(false);
      setModalCreateVendorOpen(false);
    }
  };

  const formikVendorCreate = useFormik({
    initialValues: createVendorinitialValues,
    validationSchema: createVendorvalidationSchema,
    onSubmit: onSubmitCreateVendor,
  });


  return {
    searchTerm,
  pageNumber,
  setPageNumber,
  openDropdownIndex,
  loading,
  isModalEditVendorOpen,
  isModalCreateVendorOpen,
  isModalDeleteVendorOpen,
  selectedEventId,
  selectedCategory,
  dropError,
  catid,
  vendorid,
  category,
  initialValues,
  vendordata,
  vendorloading,
  vendorerror,
  handleSearch,
  openModalEditVendor,
  openModalCreateVendor,
  openModalDeleteVendor,
  closeModalEditVendor,
  closeModalDeleteVendor,
  closeModalCreateCategory,
  handleDropdownToggle,
  removeFromVendor,
  setSelectedCategory,
  setOpenDropdownIndex,
  id,
  setCatId,
  setVendorId,
  setDropError,
  formikUpdateVendor,
  formikVendorCreate
  };
};

export default useAdminVendor;
