const dateConverter = (created_at) => {
  var d = created_at.split("T")[0];
  return d;
};

const timeConverter = (created_at) => {
  // var d = created_at.split('T')[-1];
  let time = new Date(created_at);
  let formatted_time =
    time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();
  return formatted_time;
};


const timeConverter2 = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds(); // Extract seconds
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert hour from 24-hour to 12-hour format
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if minutes are less than 10
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds; // Add leading zero if seconds are less than 10
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
};

export { timeConverter,timeConverter2,dateConverter };
