import styled from "styled-components";
import { Link } from "react-router-dom";
import React from "react";

const NotActive = () => {
  
  return (
    <Styles>
      <div className="center Boxes">
        <h1>Pending</h1>
        <p>Whoops, This event isnt active yet !!!</p>
        <Link to="/">
          <button>Go to wiserve's home</button>
        </Link>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background-color: black;
  color: #fff;
  min-height: 100vh;
  display: grid;
  place-content: center;

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-weight: 600;
    font-size: 4rem;
    font-family: "MatterNormal", sans-serif;
    color: yellow;

  }
  p {
    font-size: 17px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: red;
    text-transform: lowercase;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  button {
    font-weight: 500;
    font-family: "MatterNormal", sans-serif;
    border-radius: 1rem;
    border: none;
    padding: 10px 20px;
    transition: 0.4s;
    font-size: 1.2rem;
  }
  button:hover {
    transform: scale(1.1);
  }
`;

export default NotActive;
