import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  width: 320px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 24px;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 180px;
  }
  @media (max-width: 640px) {
    width: 230px;
  }
  @media (max-width: 480px) {
    width: 330px;
  }
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: #ff5c00;
  transition: width 1s ease-in-out;
`;

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 20 ? prevProgress + 1 : 20
      );
    }, 50);

    return () => clearInterval(progressInterval);
  }, []);

  return (
    <ProgressBarContainer>
      <ProgressBarFill progress={progress} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
