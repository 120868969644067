import styled from "styled-components";

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  height: 100vh;

  & .format {
    width: auto;
    height: 195px;
    margin-top: 20px;
  }
  & .wrapper-div {
    margin-top: 30px;
  }

  & .input-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  & .input-fle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    gap: 1.5rem;
    & .wrapper-child {
      width: 100%;
    }
  }
  & .align {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 640px) {
    & .format {
      width: auto;
      height: 175px;
    }
    .input-fle {
      display: flex;
      flex-direction: column;
      & .wrapper-child {
        width: 100%;
      }
    }
  }
  @media (max-width: 480px) {
    .input-fle {
      display: flex;
      flex-direction: column;
      & .wrapper-child {
        width: 100%;
      }
    }
    & .format {
      width: auto;
      height: 130px;
    }
  }
  @media (max-width: 320px) {
    & .format {
      width: auto;
      height: 95px;
    }
  }
`;
export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContent = styled.div`
  width: 100%;
  background-color: #f0f4f7;
  padding-bottom: 40px;
  height: 100%;
  overflow-y: auto;
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .pagination-bar {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  .more {
    display: inline-block;
    vertical-align: middle;
  }
  .stat {
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  @media (max-width: 1400px) {
    .more {
      margin-top: -8px;
    }
  }
  @media (max-width: 540px) {
    .stat {
      margin-bottom: 20px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .more {
      margin-top: -8px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;

    .stat {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .more {
      margin-top: -8px;
    }
  }
`;
