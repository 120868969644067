import React, { useState } from 'react';
import styled from 'styled-components';

// Define a list of country codes and their corresponding values
const countryCodes = [
  { label: 'NG', value: '234' },
  // { label: 'US', value: '1' },
  // { label: 'IN', value: '91' },
  // Add more country codes as needed
];

// Styled components
const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  height: 45px; /* Default height for input */
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
  font-family: "MatterNormal", sans-serif;
  @media (max-width: 640px) {
  font-size: 18px;
  }
  @media (max-width: 480px) {
  font-size: 18px;
  }
`;
const Container2 = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* border: 1px solid var(--Gray-300, #d0d5dd); */
  border: 1px solid black;
  height: 45px; /* Default height for input */
  width: 100%;
  font-size: 14px;
  margin-bottom: 15px;
  font-family: "MatterNormal", sans-serif;
  @media (max-width: 640px) {
  font-size: 18px;
  }
  @media (max-width: 480px) {
  font-size: 18px;
  }
`;
const CountryCodeDropdown = styled.select`
  margin-right: 10px;
  padding-left: 8px;
  border: none;
  outline: none; /* Remove focus outline */
`;

const PhoneNumberInput = styled.input`
  padding: 8px;
  border: none;
  width: 100%;
  font-size: 14px;
  font-family: "MatterNormal", sans-serif;
  outline: none; /* Remove focus outline */
  @media (max-width: 640px) {
  font-size: 16px;
  }
  @media (max-width: 480px) {
  font-size: 18px;
  }

`;
const WrapperLabel = styled.div`
    margin-bottom: 10px;
    

`
const WrapperNum = styled.div`
.label{
  color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;

}

@media (max-width: 480px) {
  .label{
    font-size: 17px;
}}

`

const CountryCodeInput = ({ onChange,name,type,value,country }) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('234');
// console.log(selectedCountryCode,"ct")

const handleCountryCodeChange = (event) => {
  const countryCode = event.target.value;
  setSelectedCountryCode(countryCode);
  country(countryCode); // Call the prop function to update the selected country code in the parent component
};

  return (
    <WrapperNum>
      <WrapperLabel>
      <p className="label">
          Phone Number
        </p> 
      </WrapperLabel>
  
    <Container>
      <CountryCodeDropdown value={selectedCountryCode} onChange={handleCountryCodeChange}>
        {countryCodes.map((code) => (
          <option key={code.value} value={code.value}>
            {code.label}
          </option>
        ))}
      </CountryCodeDropdown>

      <PhoneNumberInput 
      placeholder="705-926-8623"
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      />
    </Container>
    </WrapperNum>
  );
};

export const CountryCodeInput2 = ({ onChange,name,type,value,country }) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('234');
// console.log(selectedCountryCode,"ct")

const handleCountryCodeChange = (event) => {
  const countryCode = event.target.value;
  setSelectedCountryCode(countryCode);
  country(countryCode); // Call the prop function to update the selected country code in the parent component
};

  return (
    <WrapperNum>
      <WrapperLabel>
      <p className="label">
          Phone Number
        </p> 
      </WrapperLabel>
  
    <Container2>
      <CountryCodeDropdown value={selectedCountryCode} onChange={handleCountryCodeChange}>
        {countryCodes.map((code) => (
          <option key={code.value} value={code.value}>
            {code.label}
          </option>
        ))}
      </CountryCodeDropdown>
      <PhoneNumberInput 
      placeholder="705-926-8623"
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      />
    </Container2>
    </WrapperNum>
  );
};
export default CountryCodeInput;
