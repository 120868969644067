import React from "react";
import styled from "styled-components";

const Notif = styled.div`
font-family: "MatterMedium", sans-serif;
  border-radius: 48px;
  /* height: 40px; */
  font-size: 14px;
  color:#383838;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #E9EAF0;
  gap: 0.5rem;
  /* padding: 0px 20px; */
  img {
    margin-top: 0.2rem;
  }

  @media screen and (max-width: 912px) {
    font-size: 19px;
    padding: 15px 18px;
    img {
      margin-top: 0.3rem;
    }
  }

  @media screen and (max-width: 640px) {
    font-size: 16px;
    padding: 2px 20px;

    img {
      margin-top: 0.3rem;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 15px;
    padding: 2px 20px;
    img {
      /* margin-top: 0.3rem; */
      width: 20px;
    }
  }
`;
const Notif2 = styled.div`
  font-family: "MatterMedium", sans-serif;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9EAF0;
  /* padding: 0px 20px; */
  img {
    margin-top: 0.2rem;
  }

  @media screen and (max-width: 912px) {
    font-size: 19px;
    color:red;
    padding: 15px 18px;
  }

  @media screen and (max-width: 640px) {
    font-size: 16px;
    padding: 5px 20px;


  }
  @media screen and (max-width: 480px) {

    /* width: 170px; */
    font-size: 15px;
    padding: 5px 20px;

  
  }
`;

const NotiSuccess = ({ title}) => {
  return (
    <Notif
    >
      <div>{title}</div>
      <div>
        <img src="/images/badge.png" alt="badge" />
      </div>
    </Notif>
  );
};


const NotiError = ({ title }) => {
  return (
    <Notif2
    >
      <div>{title}</div>
    </Notif2>
  );
};


export { NotiSuccess,NotiError };
