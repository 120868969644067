import React from "react";
import styled from "styled-components";
import Sidebars from "./Sidebar";

const Dashboard = ({ children }) => {
  return (
    <Dash>
      <Sidebars />
      <Content>{children}</Content>
    </Dash>
  );
};

const Content = styled.div`
  flex: 1;
  overflow: hidden;

  @media (max-width: 1200px) {
    margin-left: 75px;
  }
`;

const Dash = styled.div`
  display: flex;
  width: 100%;
`;

export default Dashboard;
