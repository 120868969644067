import React from "react";
import styled from "styled-components";

export const Button2 = ({
  title,
  onClick,
  src,
  alt,
  backgroundColor,
  padding,
}) => {
  return (
    <div className="bouncing-element">
    <Button
      backgroundColor={backgroundColor}
      onClick={onClick}
      padding={padding}
      type="submit"
      >
      <div>{title}</div>
      <div>
        <img src={src} alt={alt} />
      </div>
    </Button>
      </div>
  );
};

const Button = styled.button`
  background-color: ${(props) =>
    props.backgroundColor || "rgba(255, 92, 0, 1)"};
  font-family: "MatterNormal", sans-serif;
  border-radius: 12px;
  height: 40px;
  border: none;
  width: 200px;
  padding: 0px 20px;
  padding: ${(props) => props.padding || "0px 20px"};
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  gap: 1rem;
  position: relative;

 &:hover{
  background-color: black;
 }
  & img {
    width: auto;
    height: 1.5rem;
    position: relative;
    top: 3px;
  }

  @media screen and (max-width: 912px) {
    height: 40px;
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    height: 40px;
    font-size: 17px;
    padding: 0px 15px;
    margin-top: 20px;
    width: 220px;

    & img {
      width: auto;
      height: 1.8rem;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-size: 17px;
    padding: 0px 20px;
    width: 240px;
    height: 50px;

    & img {
      width: auto;
      height: 1.8rem;
    }
  }
  @media screen and (max-width: 350px) {
    margin-top: 20px;
    font-size: 17px;
    padding: 0px 7px;
    width: 200px;
    height: 40px;

    & img {
      width: auto;
      height: 1.8rem;
    }
  }
`;
const Buttons = styled.button`
  background-color: ${(props) =>
    props.backgroundColor || "rgba(255, 92, 0, 1)"};
  font-family: "MatterNormal", sans-serif;
  border-radius: 24px;
  height: 40px;
  border: none;
  width: 200px;
  padding: 0px 20px;
  padding: ${(props) => props.padding || "0px 20px"};
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  gap: 1rem;
  position: relative;

  & img {
    width: auto;
    height: 1.5rem;
    position: relative;
    top: 3px;
  }

  @media screen and (max-width: 912px) {
    height: 40px;
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    height: 40px;
    font-size: 17px;
    padding: 0px 15px;
    margin-top: 20px;
    width: 220px;

    & img {
      width: auto;
      height: 1.5rem;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-size: 17px;
    padding: 0px 20px;
    width: 240px;

    & img {
      width: auto;
      height: 1.5rem;
    }
  }
  @media screen and (max-width: 350px) {
    margin-top: 20px;
    font-size: 17px;
    padding: 0px 7px;
    width: 200px;

    & img {
      width: auto;
      height: 1.5rem;
    }
  }
`;
const Buttonss = styled.button`
  background-color: ${(props) =>
    props.backgroundColor || "rgba(255, 92, 0, 1)"}!important;
  font-family: "MatterNormal", sans-serif;
  /* border-radius: 24px!important; */
  height: 40px;
  border: none !important;
  width: 200px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* background-color:rgba(255, 92, 0, 1)!important; */
  @media screen and (max-width: 912px) {
    height: 40px;
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    height: 40px;
    font-size: 17px;
    padding: 0px 15px;
    margin-top: 20px;
    width: 220px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 5px;
    font-size: 17px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;
export const Button3 = ({
  title,
  onClick,
  src,
  alt,
  backgroundColor,
  padding,
}) => {
  return (
    <div className="bouncing-element">
    <Buttons
      backgroundColor={backgroundColor}
      onClick={onClick}
      padding={padding}
      type="submit"
    >
      <div>{title}</div>
      <div>
        <img src={src} alt={alt} />
      </div>
    </Buttons>
    </div>

  );
};

export const Button4 = ({
  title,
  onClick,
  src,
  alt,
  disabled,
  backgroundColor,
  padding,
}) => {
  return (
    <div className="bouncing-element">

    <Buttonss
      backgroundColor={backgroundColor}
      onClick={onClick}
      padding={padding}
      disabled={disabled}
      type="submit"
    >
      <div>{title}</div>
    </Buttonss>
    </div>

  );
};
