import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

// Navbar component
export const NavBar = () => {
  // State to manage the menu's open/close state
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Function to toggle the menu state
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
 
  // JSX structure for the navbar
  return (
    <StyledNavbar isMenuOpen={isMenuOpen}>
      <header>
        <img src="/images/logo.png" alt="logo" className="logo" />
        {/* Hamburger icon to trigger the menu */}
        <div
          className={`hamburger ${isMenuOpen ? "open" : ""} bouncing-element`}
          onClick={toggleMenu}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        {/* Navigation links */}
        <nav className={`nav__links ${isMenuOpen ? "show" : ""}`}>
          <ul>
            {/* Scroll to specific sections */}
            <li>
              <Link
                activeClass="active"
                to="why_wiserve"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Why Wiserve?
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="how_it_works"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                How it works?
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="faqs"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
              >
                Faqs
              </Link>
            </li>
            {/* Custom button as a navigation link */}
            <Button className="bouncing-element">
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
              >
                Contact us
              </Link>
            </Button>
          </ul>
        </nav>
      </header>
    </StyledNavbar>
  );
};

const StyledNavbar = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  li,
  a {
    font-family: "MatterNormal", sans-serif;
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 5%;
  }

  .logo {
    cursor: pointer;
    width: auto;
    height: 35px;
  }

  .nav__links ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav__links li {
    padding: 20px 40px;
  }

  .nav__links li a {
    transition: all 0.3s ease 0s;
  }

  .nav__links .active {
    color: #ff7300;
  }

  .nav__links li a:hover {
    color: rgba(255, 92, 0, 1);
    cursor: pointer;
  }

  // Media query for responsive design
  @media (max-width: 1024px) {
    .logo {
      width: auto;
      height: 30px;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .nav__links {
      display: none;
    }

    .nav__links ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style: none;
      padding: 0 20px;
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    .hamburger .line {
      width: 35px;
      height: 3px;
      background-color: #FF5C00;
      margin: 3px;
      transition: all 1s ease;
    }

    .hamburger.open .line:nth-child(1) {
      transform: translateY(10px) rotate(55deg);
    }

    .hamburger.open .line:nth-child(2) {
      opacity: 0;
    }

    .hamburger.open .line:nth-child(3) {
      transform: translateY(-15px) rotate(-55deg);
    }

    .nav__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      background-color: white;
      border-radius: 25px 0px 0px 10px;
      top: ${({ isMenuOpen }) => (isMenuOpen ? "90px" : "-100%")};
      right: 0;
      padding: 0px;
      z-index: 1000;
      transition: top 2s ease;
      opacity: 1;
      background-color:black;
    }

    .nav__links.show {
      display: block;
    }

    .nav__links.show ul {
      animation: slideDown 2s forwards;
    }
  }

  @media (max-width: 1200px) {
    a {
      font-size: 18px;
    }
  }
  @media (max-width: 912px) {
    a {
      font-size: 16px;
    }
  }
  @media (max-width: 640px) {
    a {
    font-size: 18px;
    color: white!important;

  }
   
  }
  @media (max-width: 480px) {
    a {
      font-size: 18px;
      color: white!important;

    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

// Styled component for the custom button
const Button = styled.button`
  width: 130px;
  height: 30px;
  background-color: #D9D9D9;
  border-radius: 48px;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  font-family: "GilroyLight", sans-serif;
  margin-left: 35px;
  &:hover {
    background-color: #ff7300;
    color: white;
    border: none;
  }

  @media screen and (max-width: 912px) {
    font-size: 12px;
    height: 40px;
    width: 180px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  @media screen and (max-width: 640px) {
    height: 40px;
    width: 180px;
    font-size: 12px;
    background-color: rgba(255, 92, 0, 1);

  }
`;