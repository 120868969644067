import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 6px;
  padding: 10px 8px;
  border: 0.5px solid #ff7300;
  margin-top: 14px;
  background-color: white;
  h1{
    font-size: 20px;
    font-family: "MatterNormal", sans-serif;

  }

 
  @media screen and (max-width: 640px) {
    padding: 3px 8px;
    h1{
    font-size: 17px;

  }}
`;



const Desc = styled.div`
  /* background-color: #f8f8f8; */
  padding: 15px 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: white;
  p{
    font-size: 17px;
    font-family: "MatterNormal", sans-serif;
  color: #383838;
  }
  @media screen and (max-width: 480px) {
    p{
    font-size: 17px;

  }
}
`;

const Content = styled.div`
  /* margin-bottom: 5px; */
  /* margin-top: 5px; */
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

img{
    width: auto;
    height: auto;
}
`;

function Drop({
  selectedCategory,
  setSelectedCategory,
  category,
  isActive,
  setIsActive,
  label,
  setlabel,
  
}) {
  const handleCategorySelect = (category) => {
    setSelectedCategory(category.value);
    setlabel(category.label);
    setIsActive(false);
  };

  return (
    <>
      <Container>
        <Content onClick={() => setIsActive(!isActive)}>
          <div>
            <h1 >{label || "Choose one"}</h1>
          </div>
          <Box>
          <img src={isActive ? "/images/arrowu.png" : "/images/arrrowd.png"} alt="Arrow Icon" />
          </Box>
        </Content>
      </Container>
      {isActive && (
        <Desc>
          {category.map((option, index) => (
            <Content key={index} onClick={() => handleCategorySelect(option)} style={{backgroundColor:selectedCategory === option.value ? "rgba(255, 115, 0, 0.1)" : "white",borderRadius:"8px"}}> 
                <div >
    <p style={{ color: selectedCategory === option.value ? "#FF5C00" : "black" }}>
        {option.label}
    </p>
</div>

              {selectedCategory === option.value && (
                <img src="/images/chec.png"/>
              )}
            </Content>
          ))}
        </Desc>
      )}
    </>
  );
}

export default Drop;
