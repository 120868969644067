import React, { useState } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const useAdminTrivia = () => {
  const [loading, setLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionsid, setQuestionsid] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const { id } = useParams();

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isModalEditTriviaQuestionsOpen, setModalEditTriviaQuestionsOpen] =
    useState(false);
  const [isModalCreateTriviaQuestionsOpen, setModalCreateTriviaQuestionsOpen] =
    useState(false);
  const [isModalDeleteTriviaQuestionsOpen, setModalDeleteTriviaQuestionsOpen] =
    useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const onSubmitUpdateTriviaQuestions = async (
    questionId,
    values,
    { resetForm }
  ) => {
    setLoading(true);
    try {
      const updatedQuestion = {
        text: values.question,
        event: id,
        options: values.options.map((option, index) => ({
          text: option,
          is_correct: index === correctAnswer,
        })),
      };
      const response = await axios.patch(
        `${URL}trivia-questions-rud/${id}/${questionId}/`,
        updatedQuestion,
        headers(localStorage.getItem("token"))
      );

      toast.success("trivia updated successfully!");
      refetch();
    } catch (error) {
      toast.error("trivia update failed");
    } finally {
      setLoading(false);
      setModalEditTriviaQuestionsOpen(false);
      setSelectedQuestion(null);
    }
  };

  const formikUpdateTriviaQuestions = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: selectedQuestion?.text || "",
      options: selectedQuestion?.options?.map((option) => option.text) || [
        "",
        "",
      ],
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Question is required"),
      options: Yup.array()
        .of(Yup.string().required("Option is required"))
        .min(2, "At least two options are required"),
    }),
    onSubmit: (values, { resetForm }) =>
      onSubmitUpdateTriviaQuestions(selectedQuestion.id, values, resetForm),
  });

  const fetchTriviaQuestions = () => {
    let url = `/trivia-questions/${id}/`;

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: triviaquestionsdata,
    isLoading: triviaquestionsloading,
    isError: triviaquestionserror,
    refetch,
  } = useQuery("trivia-questions", () => fetchTriviaQuestions());

  const removeFromTriviaQuestion = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${URL}trivia-questions-rud/${id}/${questionsid}/`,
        headers(localStorage.getItem("token"))
      );
      toast.success("trivia question deleted successfully");
      refetch();
    } catch (error) {
      toast.error("trivia deletion failed");
    } finally {
      setLoading(false);
      setModalDeleteTriviaQuestionsOpen(false);
    }
  };

  const openModalEditTriviaQuestions = (question) => {
    setSelectedQuestion(question);
    const correctOptionIndex = question.options.findIndex(
      (option) => option.is_correct
    );
    setCorrectAnswer(correctOptionIndex);
    setModalEditTriviaQuestionsOpen(true);
  };

  const openModalDeleteTriviaQuestions = () => {
    setModalDeleteTriviaQuestionsOpen(true);
  };

  const openModalCreateTriviaQuestions = () => {
    setSelectedQuestion(null);
    setCorrectAnswer(null);
    setModalCreateTriviaQuestionsOpen(true);
  };
  const closeModalEditTriviaQuestions = () => {
    setSelectedQuestion(null);
    setModalEditTriviaQuestionsOpen(false);
  };

  const closeModalDeleteTriviaQuestions = () => {
    setModalDeleteTriviaQuestionsOpen(false);
  };
  const closeModalCreateTriviaQuestions = () => {
    setSelectedQuestion(null);
    setModalCreateTriviaQuestionsOpen(false);
  };

  const handleRadioChange = (index) => {
    setCorrectAnswer(index);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const onSubmitCreateTriviaQuestions = async (values, { resetForm }) => {
    setLoading(true);

    const newQuestion = {
      text: values.question,
      event: id,
      options: values.options.map((option, index) => ({
        text: option,
        is_correct: index === correctAnswer,
      })),
    };

    try {
      const response = await axios.post(
        `${URL}trivia-questions-create/${id}/`,
        newQuestion,
        headers(localStorage.getItem("token"))
      );

      setLoading(false);
      toast.success("trivia question created successfully");
      resetForm();
      refetch();
    } catch (error) {
      setLoading(false);
      // toast.error("trivia question creation failed");
      setModalCreateTriviaQuestionsOpen(false);
    }
  };

  const formikTriviaQuestionsCreate = useFormik({
    initialValues: {
      question: "",
      options: ["", ""],
    },
    validationSchema: Yup.object({
      question: Yup.string().required("question is required"),
      options: Yup.array()
        .of(Yup.string().required("option is required"))
        .min(2, "at least two options are required"),
    }),
    onSubmit: (values, { resetForm }) =>
      onSubmitCreateTriviaQuestions(values, resetForm),
  });


  return {
    loading,
    id,
    selectedQuestion,
    questionsid,
    correctAnswer,
    openDropdownIndex,
    isModalEditTriviaQuestionsOpen,
    isModalCreateTriviaQuestionsOpen,
    isModalDeleteTriviaQuestionsOpen,
    selectedEventId,
    triviaquestionsdata,
    triviaquestionsloading,
    triviaquestionserror,
    formikUpdateTriviaQuestions,
    formikTriviaQuestionsCreate,
    openModalEditTriviaQuestions,
    openModalDeleteTriviaQuestions,
    openModalCreateTriviaQuestions,
    closeModalEditTriviaQuestions,
    closeModalDeleteTriviaQuestions,
    closeModalCreateTriviaQuestions,
    handleRadioChange,
    handleDropdownToggle,
    removeFromTriviaQuestion,
    setQuestionsid,
    setOpenDropdownIndex,
  };
};

export default useAdminTrivia;
