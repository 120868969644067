import React from "react";
import styled from "styled-components";

const Button = styled.button`
  font-family: "GilroySemiBold", sans-serif;
  border-radius: 48px;
  /* height: 40px; */
  width: 200px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-bottom: 80px;

  
  @media screen and (max-width: 912px) {
    height: 70px;
    width: 280px;
    font-size: 25px;
    /* padding: 15px 25px; */
  
  }

  @media screen and (max-width: 640px) {
    height: 50px;
    width: 220px;
    font-size: 17px;

    img {
      margin-top: 0.3rem;
    }
  }

  @media screen and (max-width: 480px) {
    height: 50px;
    width: 230px;
    font-size: 17px;
    padding: 0px 15px;

  }
`;


const PrimaryButton2 = ({ title, backgroundColor,onClick}) => {
  return (
    <Button
     onClick={onClick}
      type="submit"
      style={{
        backgroundColor: backgroundColor,
        border: "none",
        color: "white",
      }}
    >
      <div>{title}</div>
    </Button>
  );
};



export { PrimaryButton2 };
