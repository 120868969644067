
export const WorksSteps = [
  { image: "/images/s1.svg", text: "Create an account, create an event on your dashboard" },
  { image: "/images/s2.svg", text: "Upload the paper-menu and request activation"},
  { image: "/images/s3.svg", text: "Our agent onboards and finish creating your event" },
  { image: "/images/s4.svg", text: "Viola, ready to use at your event" },
  // { image: "/images/s5.svg", text: "....." },

  ];

