import React from "react";
import styled from "styled-components";
import { MdKeyboardBackspace } from "react-icons/md";

const Head = ({ title, onClick, isDarkMode }) => {
  return (
    <Header isDarkMode={isDarkMode}>
      <h1>{title}</h1>
      <div onClick={onClick}>
        <MdKeyboardBackspace
          size={30}
          color={isDarkMode ? "white" : "#383838"}
          className="arrow"
        />
        {/* <img src="/images/backarrow.png" alt="arrow" className="arrow"/> */}
      </div>
      <div className="line" />
    </Header>
  );
};

export { Head };

const Header = styled.div`
font-family: "MatterMedium", sans-serif;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    /* color: #383838; */
    color: ${(props) => (props.isDarkMode ? "white" : "#383838")};
    margin-top: 30px;
  }
  .arrow {
    /* margin-top: 0.2rem; */
  }

  .line {
    width: 100%;
    height: 1px;
    background: #ff5c00;
    margin-top: 40px;
  }

  @media screen and (max-width: 912px) {
    .arrow {
      /* width:40px;
        height:auto; */
      position: absolute;
      left: 30px;
      top: 27px;
    }

    h1 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 640px) {
    img {
      margin-top: 0.3rem;
    }
    .arrow {
      /* width:25px;
        height:auto; */
      position: absolute;
      left: 30px;
      top: 26px;
    }

    h1 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    .arrow {
      /* width:20px;
        height:auto; */
      position: absolute;
      left: 30px;
      top: 27px;
    }

    h1 {
      font-size: 25px;
    }
    .line {
      margin-top: 30px;
    }
  }
`;
