import React from "react";
import styled from "styled-components";

export const RadioInput = ({
  placeholder,
  type,
  onChange,
  value,
  error,
  name,
  disabled,
  multiple,
  onClickRemove,
  radioChecked,
  radioOnChange,
  radioName,
}) => {
  const InputComponent = multiple ? "textarea" : "input";

  return (
    <StyledInput>
      <div className="parent">
        <WrapInput disabled={disabled}>
          <InputWrapper>
            <InputComponent
              type={type}
              name={name}
              className={`form-control${multiple ? " textarea" : ""}`}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              disabled={disabled}
            />
            <RadioItem>
              <input
                type="radio"
                checked={radioChecked}
                onChange={radioOnChange}
                name={radioName}
              />
            </RadioItem>
            <CloseButton onClick={onClickRemove}>
              <img
                src="/images/mdx3.svg"
                alt="option_remove"
                className="close"
              />
            </CloseButton>
          </InputWrapper>
        </WrapInput>
        {error && <p id="text-danger">{error}</p>}
      </div>
    </StyledInput>
  );
};

const StyledInput = styled.div`
  width: 100%;
  position: relative;

  .parent {
    margin-bottom: 30px;
    position: relative;
  }

  #text-danger {
    color: red;
    font-size: 12px;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid #000;
    border-bottom: 1px solid black;
    height: 38px;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
    font-family: "MatterNormal", sans-serif;
    padding-right: 60px;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #999999;
    outline: -webkit-focus-ring-color auto 0px;
    outline: none;
    border: 1px solid rgba(255, 92, 0, 1);
    background-color: white;
  }

  .textarea {
    height: 120px;
  }

  .close {
    width: auto;
    height: 25px;
    cursor: pointer;
  }
  
  @media (max-width: 480px) {
    .form-control {
      font-size: 17px;
      height: 40px;
    }
  }
`;

const WrapInput = styled.div`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none; 
    cursor: not-allowed; 
  `}
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const RadioItem = styled.div`
  position: absolute;
  right: 50px;
  display: flex;
  align-items: center;
  transform: translateY(20%);

  input[type="radio"] {
    cursor: pointer;
    transition: transform 0.3s;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #999;
    border-radius: 50%;
    position: relative;
    outline: none;
    background-color: white;
  }

  input[type="radio"]:checked {
    transform: scale(1.2);
    border-color: rgba(0, 160, 64, 1);

  }

  input[type="radio"]:checked::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgba(0, 160, 64, 1);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  transform: translateY(10%);
  cursor: pointer;
`;
