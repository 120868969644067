import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

const FoodMenuHeader = ({
  tag,
  date,
  isDarkMode,
  handleModeChange,
}) => {

  const handleSignOut = () => {
    // Close the current tab
    window.close();
    // Redirect to home page
    window.location.href = "/"; // Replace "/home" with your actual home page URL
  };
  return (
    <Header isDarkMode={isDarkMode}>
      <div className="div1">
        <h1 className="tag">{tag}</h1>
        <p className="datep mt-2">{date}</p>
        <p className="food mt-2">Food Menu</p>
      </div>
      <div className="fl">
        <div className="div2" onClick={handleSignOut}>
          <p className="signout">Sign out</p>
        </div>
        <SwitchButton onClick={handleModeChange} isDarkMode={isDarkMode}>
          <SwitchBall isDarkMode={isDarkMode} />
        </SwitchButton>
      </div>
    </Header>
  );
};

export { FoodMenuHeader };

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .fl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
  }
  .div1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  .div2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 29px;
    background-color: #e9eaf0;
    padding: 5px 15px;
    margin-top: 20px;
  }

  @media screen and (max-width: 812px) {
    .tag {
      font-size: 45px;
      color: ${(props) => (props.isDarkMode ? "#ffffff" : "#1d1d1d")};
      font-family: "MatterMedium", sans-serif;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
    }
    .signout {
      font-size: 32px;
      color: #383838;
      font-family: "MatterMedium", sans-serif;
      font-style: normal;
      font-weight: 500;
    }
    .food {
      font-size: 32px;
      color: #7d7d7d;
      font-family: "MatterMedium", sans-serif;
      font-style: normal;
      font-weight: 500;
    }

    .datep {
      font-size: 45px;
      color: ${(props) => (props.isDarkMode ? "#ffffff" : "#1d1d1d")};
      font-family: "MatterMedium", sans-serif;
      font-style: normal;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 640px) {
    .tag,
    .datep {
      font-size: 30px;
    }
    .signout {
      font-size: 20px;
    }
    .food {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    .tag,
    .datep {
      font-size: 25px;
    }
    .signout {
      font-size: 18px;
    }
    .food {
      font-size: 18px;
    }
  }
`;

const SwitchButton = styled.button`
  position: relative;
  display: inline-block;
  background-color: ${(props) => (props.isDarkMode ? "#1D1D1D" : "#E9EAF0")};
  box-shadow: 7.1px 7.1px 14.2px 1px rgba(36, 39, 44, 0.7);
  transition: background-color 0.3s;
  cursor: pointer;
  outline: none;
  /* border: 0.5px ; */
  border: none;
  border: ${(props) => (props.isDarkMode ? "1px solid white" : "none")};

  @media screen and (max-width: 812px) {
    width: 80px;
    height: 38px;
    border-radius: 20px;
  }
  @media screen and (max-width: 640px) {
    width: 60px;
    height: 27px;
    border-radius: 14.2px;
  }

  @media screen and (max-width: 480px) {
    width: 50px;
    height: 25px;
    border-radius: 14.2px;
  }
`;

const SwitchBall = styled.span`
  content: "";
  display: block;
  border-radius: 50%;
  background-color: #fff;
  background-color: ${(props) => (props.isDarkMode ? "#fff" : "#1D1D1D")};
  position: absolute;
  top: 50%;
  transition: transform 0.3s, background-color 0.3s;
  left: ${(props) => (props.isDarkMode ? "calc(100% - 10px)" : "10px")};
  transform: translate(-50%, -50%);
  transition: left 2s, background-color 2s;
  @media screen and (max-width: 812px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 640px) {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: 480px) {
    width: 16px;
    height: 16px;
  }
`;
