import { Route, Routes } from "react-router-dom";
import * as DashboardPages from "../app/dashboard/pages/index";
import * as WebsitePages from "../app/website/pages/index";
import NotFound from "../app/website/components/NotFound";
import {
  SuperUserProtectedRoute,
  HostProtectedRoute,
  UnProtectedRoute,
} from "../utils/protectedRoutes";
import { PrivacyPolicy } from "../app/website/components/PrivacyPolicy";
import * as WebAppPages from "../app/webapp/pages/index";
import CallConfirmation from "../app/webapp/components/CallConfirmation";
import OrderConfirmation from "../app/webapp/components/OrderConfirmation";
import FoodEmpty from "../app/webapp/components/FoodEmpty";

function Routing() {
  return (
    <div>
      <Routes>
        {/*host-onboarding event*/}
        <Route
          path="/host-onboard"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostOnboardPage />
            </HostProtectedRoute>
          }
        />

        <Route
          path="/host-onboard-event-type"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostOnboardEventType />
            </HostProtectedRoute>
          }
        />

        <Route
          path="/host-onboard-event-info"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostOnboardEventInfo />
            </HostProtectedRoute>
          }
        />
        <Route
          path="/host-onboard-event-media"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostOnboardEventMedia />
            </HostProtectedRoute>
          }
        />

        {/*host-dashboard*/}
        <Route
          path="/dashboard-host-overview"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostDashboardPage />
            </HostProtectedRoute>
          }
        />

        <Route
          path="/dashboard-host-event/:id/details"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostEventDetailsPage />
            </HostProtectedRoute>
          }
        />

        <Route
          path="/dashboard-host-ongoing-event"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostOngoingEventPage />
            </HostProtectedRoute>
          }
        />

        <Route
          path="/dashboard-host-approved-event"
          element={
            <HostProtectedRoute>
              <DashboardPages.HostApprovedEventPage />
            </HostProtectedRoute>
          }
        />

        {/*admin-dashboard*/}

        <Route
          path="/dashboard-admin-event/:id/details"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminEventDetailsPage />
            </SuperUserProtectedRoute>
          }
        />

        <Route
          path="/dashboard-admin-approved-event"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminApprovedEventPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin-ongoing-event"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminOngoingEventPage />
            </SuperUserProtectedRoute>
          }
        />

        <Route
          path="/dashboard-admin-overview"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminDashboardPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin-activation-request"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminActivationRequestPage />
            </SuperUserProtectedRoute>
          }
        />

        <Route
          path="/dashboard-admin/:id/menu-categories/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminMenuCategoriesPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin/:id/menu/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminMenuPage />
            </SuperUserProtectedRoute>
          }
        />

        <Route
          path="/dashboard-admin/:id1/menu/:id2/extras"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminMenuExtrasPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin/:id/vendor/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminVendorPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin/:id/callreason/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminCallReasonPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin/:id/trivia/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminTriviaPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin/:id/trivia-results/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminTriviaResultPage />
            </SuperUserProtectedRoute>
          }
        />
        <Route
          path="/dashboard-admin/:id/optical/"
          element={
            <SuperUserProtectedRoute>
              <DashboardPages.AdminOpticalPage />
            </SuperUserProtectedRoute>
          }
        />

        {/*webapp*/}

  

        <Route
          path="/:token"
          element={
            <UnProtectedRoute>
              <WebAppPages.WelcomePage />
            </UnProtectedRoute>
          }
        />

        <Route
          path="/:token/food-menu"
          element={
            <UnProtectedRoute>
              <WebAppPages.MenuPage />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/:token/call-waiter"
          element={
            <UnProtectedRoute>
              <WebAppPages.CallWaiterPage />
            </UnProtectedRoute>
          }
        />

        <Route
          path="/:token/food-cart"
          element={
            <UnProtectedRoute>
              <WebAppPages.FoodCartPage />
            </UnProtectedRoute>
          }
        />

        <Route
          path="/:token/call-confirmation"
          element={
            <UnProtectedRoute>
              <CallConfirmation />
            </UnProtectedRoute>
          }
        />

        <Route
          path="/:token/order-confirmation"
          element={
            <UnProtectedRoute>
              <OrderConfirmation />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/:token/food-empty"
          element={
            <UnProtectedRoute>
              <FoodEmpty />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/:token/ads-trivia"
          element={
            <UnProtectedRoute>
              <WebAppPages.OthersPage />
            </UnProtectedRoute>
          }
        />

        {/*others*/}
        <Route path="/" element={<WebsitePages.HomePage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Routing;
