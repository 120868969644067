import React, { useState } from "react";
import { ContextStore } from "./contextStore";

const FormDataProvider = ({ children }) => {
  const [cartInfo, setCartInfo] = useState([]);
  const [loginInfo, setLoginInfo] = useState([]);
  const [loginModalId, setLoginModalId] = useState("");
  const [verifyInfo, setVerifyInfo] = useState([]);
  const [hostuserId, sethostUserId] = useState([]);
  const [eventId, setEventId] = useState("");
  const [AddedItem, setAddedItem] = useState([]);  
  const [eventType, setEventType] = useState("");
  const [eventToast, setEventToast] = useState("");
  const [eventTag, setEventTag] = useState("");
  const [eventNumber, setEventNumber] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventCountry, setEventCountry] = useState("");
  const [eventState, setEventState] = useState("");
  const [eventCity, setEventCity] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventBanner, setEventBanner] = useState("");

  const value = {
    loginInfo,
    setLoginInfo,
    loginModalId,
    setLoginModalId,
    verifyInfo,
    setVerifyInfo,
    hostuserId,
    sethostUserId,
    eventId,
    setEventId,
    cartInfo,
    setCartInfo,
    AddedItem,
    setAddedItem,
    eventType, setEventType,
    eventToast, setEventToast,
    eventTag, setEventTag,
    eventNumber, setEventNumber,
    eventDate, setEventDate,
    eventTime, setEventTime,
    eventCountry, setEventCountry,
    eventState, setEventState,
    eventCity, setEventCity,
    eventAddress, setEventAddress,
    eventBanner, setEventBanner

  };

  return (
    <ContextStore.Provider value={value}>{children}</ContextStore.Provider>
  );
};

export default FormDataProvider;
