import React from "react";
import styled from "styled-components";
import CartBoxInput from "./CartBoxInput";
import CheckBoxExtra from "./CheckBoxExtra";

export default function CartBox({
  value,
  onChange,
  name,
  error,
  foodname,
  fooddescrip,
  data,
  selectedOptions,
  setSelectedOptions,
  onOptionChange,
  src,
  onClick,
  id
}) {
  return (
    <Parent>
      <div className="parent" id={id}>
        <div className="div1">
          <div className="flex">
            <img src={src} className="images" />
            <div className="mt-2">
              <h1>{foodname}</h1>
              <p className="ptag mt-3">{fooddescrip}</p>
              <div className="close" onClick={onClick}>
                <img src="/images/x.png" className="clo" />
              </div>
            </div>
          </div>
        </div>
        <div className="div2">
          <CheckBoxExtra
          setSelectedOptions={setSelectedOptions}
          selectedOptions={ selectedOptions}
          data={data}
          onOptionChange={onOptionChange} />
        </div>
        <CartBoxInput
          placeholder="Leave a note.."
          onChange={onChange}
          value={value}
          name={name}
          error={error}
        />
      </div>
    </Parent>
  );
}

const Parent = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 20px;
  margin-top: 20px;

  .parent {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 10px 10px;
  }

 

  @media screen and (max-width: 820px) {
    position: relative;
    .close {
      width: 40px;
      height: auto;
      position: absolute;
      right: 0px;
      top: 13px;
    }
    .clo {
      width: auto;
      height: 30px;
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
    h1 {
      color: #1d1d1d;
      text-align: left;
      font-family: "MatterMedium", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.5px;
    }

    .div1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .div2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .ptag {
      color: #969696;
      font-family: "MatterNormal", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      margin-top: 5px;
      /* padding: 10px 250px; */
    }
    .images{
      width: 120px;
      height: 90px;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 640px) {
    .close {
      right: -8px;
      top: 13px;
    }
    .images {
      width: 40px;
      height: 20px;
      border-radius: 10px;
    }
    .div1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* width:150px; */
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
    }
    .clo {
      width: auto;
      height: 20px;
    }

    h1 {
      font-size: 30px;
      text-align: left;
    }
    .ptag {
      font-size: 17px;
      padding-right: 10px;
    }

    .div2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .images{
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 480px) {
    .images {
      width: 50px;
      height: 50px;
    }
    .clo {
      width: auto;
      height: 15px;
    }
    .flex {
      display: flex;
      justify-content: center;
      /* align-items: center; */
      gap: 0.8rem;
    }

    .div2 {
      /* padding: 0px 10px; */
      /* align-self: center; */
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h1 {
      font-size: 25px;
      text-align: left;
    }
    .ptag {
      font-size: 15px;
      padding-right: 20px;
    }
  }
`;
