import React,{useEffect,useState} from "react";
import { Navigate } from "react-router-dom";

// const ProtectedRoute = ({ children }) => {
//   const auth = localStorage.getItem("token");
//   return auth ? <>{children}</> : <Navigate to="/" />;
// };



export const HostProtectedRoute = ({ children }) => {
  const [userdata, setUserData] = useState({});
  const hostUserType = userdata?.is_host;
  const superUserType = userdata?.is_superuser;
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const value = await localStorage.getItem("userdata");
        if (value !== null) {
          setUserData(JSON.parse(value));
        }
      } catch (error) {
        console.error(error);
      }
    };

    retrieveData();
  }, []);

  const auth = localStorage.getItem("token");
  
  if(auth){
    if(hostUserType){
      return <>{children}</>
    } else if(superUserType) {
      return <Navigate to="*" />
    } 
  } else {
    return  <Navigate to="/" />
  }
};



export const SuperUserProtectedRoute = ({ children }) => {
  const [userdata, setUserData] = useState({});
  const hostUserType = userdata?.is_host;
  const superUserType = userdata?.is_superuser;
useEffect(() => {
  const retrieveData = async () => {
    try {
      const value = await localStorage.getItem("userdata");
      if (value !== null) {
        setUserData(JSON.parse(value));
      }
    } catch (error) {
      console.error(error);
    }
  };

  retrieveData();
}, []);
  const auth = localStorage.getItem("token");
  if(auth){
    if(superUserType){
      return <>{children}</>
    } else if(hostUserType) {
      return <Navigate to="*" />
    } 
  } else {
    return  <Navigate to="/" />
  }
};


export const UnProtectedRoute = ({ children }) => {

  if(children){
    return <>{children}</>
  } else {
    return  <Navigate to="*" />
  }
};