import React from "react";
import { TableData } from "../../components/TableData";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { ModalUpload2 } from "../../components/ModalBox/ModalUploadFile";
import { ModalUploadFile2 } from "../../components/ModalBox/ModalUploadFile";
import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledLink,
  StyledNavLink,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/opticalstyles";
import useAdminOptical from "../../../../utils/apilogics/admin/overview/opticalLogic";
import StatusHandler from "../../../../utils/statusHandler";
import ShortenedURL from "../../components/dashboardmain/ShortenedUrl";

export const AdminOptical = () => {
  const {
    loading,
    opticalid,
    error,
    selectedFile,
    backgroundImage,
    openDropdownIndex,
    isModalEditTriviaOpticalOpen,
    isModalCreateTriviaOpticalOpen,
    isModalDeleteTriviaOpticalOpen,
    triviaopticaldata,
    triviaopticalerror,
    triviaopticalloading,
    formikUpdateTriviaOptical,
    formikTriviaOpticalCreate,
    setOpticalid,
    setOpenDropdownIndex,
    openModalEditTriviaOptical,
    openModalDeleteTriviaOptical,
    openModalCreateTriviaOptical,
    closeModalEditTriviaOptical,
    closeModalDeleteTriviaOptical,
    closeModalCreateTriviaOptical,
    handleDropdownToggle,
    handleFileChange,
    handleFileSelect,
    removeFromTriviaOptical,
  } = useAdminOptical();
  return (
    <Wrapper>
      {isModalEditTriviaOpticalOpen && (
        <form onSubmit={formikUpdateTriviaOptical.handleSubmit}>
          <Modal
            title="Update Trivia Question"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditTriviaOptical}
          >
            <div className="wrapper-div">
              <Input
                name="optical_title"
                type="text"
                label="Optical Title"
                onChange={formikUpdateTriviaOptical.handleChange}
                value={formikUpdateTriviaOptical.values.optical_title}
                error={formikUpdateTriviaOptical.errors.optical_title}
                placeholder="enter trivia optical title"
              />
            </div>
            <ModalUpload2
              selectedFile={selectedFile}
              handleFileChange={handleFileChange}
              content={backgroundImage}
              title="Optical Image"
              error={error}
            />
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteTriviaOpticalOpen && (
        <Modal
          title="Delete Trivia Optical"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeleteTriviaOptical}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="update"
              onClick={() => removeFromTriviaOptical(opticalid)} // Pass questionId directly
            />
          </div>
        </Modal>
      )}
      {isModalCreateTriviaOpticalOpen && (
        <form onSubmit={formikTriviaOpticalCreate.handleSubmit}>
          <Modal
            title="Create Trivia Optical"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateTriviaOptical}
          >
            <div className="wrapper-div">
              <Input
                name="optical_title"
                type="text"
                label="Trivia title"
                onChange={formikTriviaOpticalCreate.handleChange}
                value={formikTriviaOpticalCreate.values.optical_title}
                error={formikTriviaOpticalCreate.errors.optical_title}
                placeholder="enter trivia optical title"
              />
            </div>

            <ModalUploadFile2
              label="Optical Image"
              name="optical_image"
              onFileSelect={handleFileSelect}
              error={error}
            />
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick="/dashboard-admin-overview"
            show
            title="create trivia optical"
            onClick={openModalCreateTriviaOptical}
            titleMenu="Add Menu"
            src="/images/arrow-right.svg"
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Trivia Optical"
            showInput
            {...(!triviaopticalloading && {
              optical:
                triviaopticaldata && triviaopticaldata?.data?.length === 0
                  ? ""
                  : triviaopticaldata,
            })}
          >
            <StatusHandler
              isError={triviaopticalerror}
              isLoading={triviaopticalloading}
            >
              {triviaopticaldata && triviaopticaldata?.data < 1 ? (
                <ModalEmpty title="no trivia optical found" />
              ) : (
                triviaopticaldata?.data.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="trivia question">{e.optical_title}</td>

                    <td data-cell="options">
                      <ShortenedURL content={e.optical_image} />
                    </td>

                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              className="nav-link"
                              onClick={() => {
                                setOpticalid(e.id);
                                openModalEditTriviaOptical();
                              }}
                            >
                              edit optical
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setOpticalid(e.id);
                                openModalDeleteTriviaOptical();
                              }}
                            >
                              delete optical
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
      </MainContent>
    </Wrapper>
  );
};
