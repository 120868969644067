import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Button = styled.button`
  font-family: "MatterNormal", sans-serif;
  border-radius: 12px;
  height: 40px;
  width: 200px;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  img {
    margin-top: 0.2rem;
  }
/* 
  @media screen and (max-width: 912px) {
    font-size: 12px;
    height: 40px;
    width: 180px;
    img {
      margin-top: 0.3rem;
    }
  } */
  @media screen and (max-width: 912px) {
    width: 220px;
    height: 50px;
    img {
      margin-top: 0.8rem;
    }
    font-size: 15px;
  }

  @media screen and (max-width: 640px) {
    width: 200px;
    img {
      margin-top: 0.3rem;
    }
    font-size: 14px;
    height: 40px;
  }

  @media screen and (max-width: 480px) {
    height: 55px;
    width: 270px;
    font-size: 18px;
    img {
      width: auto;
      height:30px;
    }
  }
`;

const Button2 = styled.button`
  font-family: "MatterNormal", sans-serif;
  /* border-radius: 12px; */
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7rem;

  @media screen and (max-width: 912px) {
    height: 50px;
    img {
      margin-top: 0.8rem;
    }
    font-size: 18px;
  }
  @media screen and (max-width: 640px) {
   
    font-size: 17px;
    height: 70px;
  }
  @media screen and (max-width: 480px) {
    margin-left: 25px;
    height: 60px;
    font-size: 21px;
    .img {
      width: auto;
      height:80px;
    }
    }
`;

const PrimaryButton = ({ title,bg ,to,onClick}) => {
  return (
    <NavLink to={to} style={{ textDecoration: 'none' }}>
    <Button
      type="submit"
      onClick={onClick}
      style={{
        backgroundColor: bg || "#FF5C00",
        border: "none",
        color: "white",
      }}
    >
      <div>{title}</div>
      <div>
        <img src="/images/arrow-right.svg" alt="arrow" />
      </div>
    </Button>
</NavLink>

  );
};
const PrimaryButton2 = ({ title,bg ,onClick,disabled}) => {
  return (
    <Button
      type="submit"
      onClick={onClick}
      disabled={disabled} 
      style={{
        backgroundColor: bg || "#FF5C00",
        border: "none",
        color: "white",
      }}
    >
      <div>{title}</div>
      <div>
        <img src="/images/arrow-right.svg" alt="arrow" />
      </div>
    </Button>

  );
};
const SecondaryButton = ({ title, onClick }) => {
  return (
    <Button2
      onClick={onClick}
      style={{
        backgroundColor: "white",
        border: "none",
        color: "#2C2C2C",
        cursor: "ponter",
      }}
    >
      <div>{title}</div>
      <div>
        <img src="/images/avatar.svg" alt="avatar icon" className="img"/>
      </div>
    </Button2>
  );
};

export { PrimaryButton,PrimaryButton2,SecondaryButton };
