import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const useAdminMenu = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalEditMenuOpen, setModalEditMenuOpen] = useState(false);
  const [isModalCreateMenuOpen, setModalCreateMenuOpen] = useState(false);
  const [isModalDeleteMenuOpen, setModalDeleteMenuOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [Error, setError] = useState(null);
  const [dropError, setDropError] = useState(null);
  const { id } = useParams();
  const [catid, setCatId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [category, setCatgeory] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [menuid, setMenuId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState("");

  const updateMenuvalidationSchema = Yup.object({
    description: Yup.string().required("Description is required"),
    cater_qty: Yup.string().required("Cater quantity is required"),
    avatar: Yup.string().required("Menu avatar is required"),
    menu_name: Yup.string().required("menu name is required"),
  });

  useEffect(() => {
    if (menuid) {
      axios
        .get(
          `${URL}superadmin/menu/rud/${menuid}/`,
          headers(localStorage.getItem("token"))
        )
        .then((response) => {
          setInitialValues(response?.data);
          formikUpdateMenu.setValues(response?.data);
          setBackgroundImage(response.data.avatar);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [menuid]);

  const menuUpdateinitialValue = {
    menu_name: initialValues?.menu_name || "",
    description: initialValues?.description || "",
    cater_qty: initialValues?.cater_qty || "",
  };

  const multipartConfig = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const onSubmitUpdateMenu = async (values, { resetForm }) => {
    const payload = {
      cater_qty: values.cater_qty,
      menu_name: values.menu_name,
      description: values.description,
      avatar: selectedFile,
    };

    setLoading(true);
    try {
      const response = await axios.patch(
        `${URL}superadmin/menu/rud/${menuid}/`,
        payload,
        multipartConfig,
        headers(localStorage.getItem("token"))
      );

      toast.success("menu updated successfully!");
      resetForm();
      refetch();
      setLoading(false);
      setModalEditMenuOpen(false);
    } catch (error) {
      toast.error("menu update failed");
      setLoading(false);
    }
  };
  const formikUpdateMenu = useFormik({
    validationSchema: updateMenuvalidationSchema,
    initialValues: menuUpdateinitialValue,
    onSubmit: onSubmitUpdateMenu,
  });

  useEffect(() => {
    if (!category) {
      return;
    } else {
      mapData();
    }
  }, []);

  const mapData = async () => {
    try {
      const response = await axios.get(
        `${URL}superadmin/menucategory-no-pg/${id}/`,
        headers(localStorage.getItem("token"))
      );
      const st = response?.data?.map((e) => ({
        label: e.cat_name,
        value: e.id,
      }));
      setCatgeory(st);
    } catch (error) {
      setCatgeory([]);
    }
  };

  const fetchMenu = (page, searchTerm) => {
    let url = `/superadmin/menu/${id}/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: menudata,
    isLoading: menuloading,
    isError: menuerror,
    refetch,
  } = useQuery(["menu", pageNumber, searchTerm], () =>
    fetchMenu(pageNumber, searchTerm)
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  const removeFromMenu = async () => {
    try {
      const response = await axios.delete(
        `${URL}superadmin/menu/rud/${menuid}/`,
        headers(localStorage.getItem("token"))
      );
      const datas = response.data;
      toast.success("menu deleted successfully!");
      refetch();
      setModalDeleteMenuOpen(false);
    } catch (error) {
      toast.error("menu delete failed");
    }
  };

  const openModalEditMenu = () => {
    setModalEditMenuOpen(true);
  };

  const openModalDeleteMenu = () => {
    setModalDeleteMenuOpen(true);
  };
  const openModalCreateMenu = () => {
    setModalCreateMenuOpen(true);
  };

  const closeModalEditMenu = () => {
    setModalEditMenuOpen(false);
  };
  const closeModalDeleteMenu = () => {
    setModalDeleteMenuOpen(false);
  };
  const closeModalCreateMenu = () => {
    setModalCreateMenuOpen(false);
  };
  const handleDropdownToggle = (index, id) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedEventId(id);
  };

  const createMenuinitialValues = {
    menu_name: "",
    cater_qty: "",
    description: "",
    avatar: "",
  };

  const createMenuvalidationSchema = Yup.object({
    description: Yup.string().required("Description is required"),
    cater_qty: Yup.string().required("cater quantity is required"),
    menu_name: Yup.string().required("menu name is required"),
  });

  const onSubmitCreateMenu = async (values, { resetForm }) => {
    values.category_id = selectedCategory;
    values.event_uuid = id;
    values.avatar = selectedFile;
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}superadmin/menu/create/`,
        values,
        multipartConfig,
        headers(localStorage.getItem("token"))
      );
      toast.success("menu created successfully!");
      setCatgeory([]);
      mapData();
      resetForm();
      setModalCreateMenuOpen(false);
      refetch();
      setLoading(false);
    } catch (error) {
      toast.error("menu creation failed");
      setLoading(false);
      setModalCreateMenuOpen(false);
    }
  };

  const formikMenuCreate = useFormik({
    initialValues: createMenuinitialValues,
    validationSchema: createMenuvalidationSchema,
    onSubmit: onSubmitCreateMenu,
  });
  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setError(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    menudata,
    menuloading,
    menuerror,
    handleSearch,
    formikUpdateMenu,
    formikMenuCreate,
    isModalEditMenuOpen,
    isModalDeleteMenuOpen,
    catid,
    isModalCreateMenuOpen,
    category,
    setSelectedCategory,
    setDropError,
    dropError,
    handleFileSelect,
    id,
    setOpenDropdownIndex,
    openDropdownIndex,
    openModalEditMenu,
    openModalDeleteMenu,
    openModalCreateMenu,
    closeModalEditMenu,
    closeModalDeleteMenu,
    closeModalCreateMenu,
    handleDropdownToggle,
    removeFromMenu,
    setMenuId,
    handleFileChange,
    selectedFile,
    backgroundImage,
    loading,
  };
};

export default useAdminMenu;
