import React from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { dateConverter } from "../../../../utils/dateTimeConverter";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  StyledNavLink2,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledNavLink,
  StyledLink,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/categorystyles";
import useAdminCategory from "../../../../utils/apilogics/admin/overview/categoryLogic";
import StatusHandler from "../../../../utils/statusHandler";

export const AdminMenuCategory = () => {
  const {
    openDropdownIndex,
    setOpenDropdownIndex,
    loading,
    isModalEditCategoryOpen,
    isModalCreateCategoryOpen,
    isModalDeleteCategoryOpen,
    initialValues,
    Error,
    catid,
    setCatId,
    id,
    searchTerm,
    setSearchTerm,
    pageNumber,
    setPageNumber,
    menucatdata,
    menucatloading,
    menucaterror,
    formikUpdateCategory,
    formikCategoryCreate,
    handleSearch,
    removeFromCategory,
    openModalEditCategory,
    openModalDeleteCategory,
    openModalCreateCategory,
    closeModalEditCategory,
    closeModalDeleteCategory,
    closeModalCreateCategory,
    handleDropdownToggle,
  } = useAdminCategory();

  return (
    <Wrapper>
      {isModalEditCategoryOpen && (
        <form onSubmit={formikUpdateCategory.handleSubmit}>
          <Modal
            title="Edit Category"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditCategory}
          >
            <div className="wrapper-div">
              <Input
                label="Category Name"
                type="text"
                name="cat_name"
                onChange={formikUpdateCategory.handleChange}
                value={formikUpdateCategory.values.cat_name}
                error={formikUpdateCategory.errors.cat_name}
              />
            </div>
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteCategoryOpen && (
        <Modal
          title="Delete Category"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeleteCategory}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="update"
              onClick={() => removeFromCategory(catid)} // Pass categoryIdToDelete here
            />
          </div>
        </Modal>
      )}
      {isModalCreateCategoryOpen && (
        <form onSubmit={formikCategoryCreate.handleSubmit}>
          <Modal
            title="Create Menu Categories"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateCategory}
          >
            <div className="wrapper-div">
              <Input
                label="Category Name"
                type="text"
                name="cat_name"
                onChange={formikCategoryCreate.handleChange}
                value={formikCategoryCreate.values.street_address}
                error={formikCategoryCreate.errors.street_address}
                placeholder="enter category name"
              />
            </div>
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick="/dashboard-admin-overview"
            show
            title="create categories"
            onClick={openModalCreateCategory}
            titleMenu="add menu"
            src="/images/arrow-right.svg"
            menu
            menuOnclick={`/dashboard-admin/${id}/menu`}
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Menu Categories"
            onChange={handleSearch}
            placeholder="search category"
            {...(!menucatloading && {
              categories:
                menucatdata && menucatdata?.data?.results.length === 0
                  ? ""
                  : menucatdata,
            })}
          >
            <StatusHandler isError={menucaterror} isLoading={menucatloading}>
              {menucatdata && menucatdata?.data?.results.length < 1 ? (
                <ModalEmpty title="no categories found" />
              ) : (
                menucatdata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="category name">
                      <StyledNavLink2 to={`/dashboard-admin/${id}/menu`}>
                        {e.cat_name}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="date updated">
                      {dateConverter(e.updated_at)}
                    </td>
                    <td data-cell="created">{dateConverter(e.created_at)}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setCatId(e.id);
                                openModalEditCategory();
                              }}
                              className="nav-link"
                            >
                              edit category
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin/${id}/menu`}
                              className="nav-link"
                            >
                              add menu details{" "}
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin/${id}/vendor`}
                              className="nav-link"
                            >
                              add vendors{" "}
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledNavLink
                              to={`/dashboard-admin/${id}/callreason`}
                              className="nav-link"
                            >
                              add callreasons
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setCatId(e.id);
                                openModalDeleteCategory();
                              }}
                            >
                              delete category
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {menucatdata && menucatdata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={menucatdata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
