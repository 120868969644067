import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../../../website/components/Button";
import { OnboardB } from "../../../components/OnboardBox";
import ProgressBar from "../../../components/progressbar/Progress";
import { NavLink } from "react-router-dom";

export const Onboarding = () => {
  return (
    <StyledOnboard>
      <div className="proskip">
        <div>
          <ProgressBar />
        </div>
        <div>
          <NavLink
            to="/dashboard-host-overview"
            style={{ textDecoration: "none" }}
          >
            <img src="/images/skip.svg" className="image2" alt="skip" />
          </NavLink>
        </div>
      </div>
      <div className="parent">
        <div className="leftdiv">
          <div className="textdiv">
            <div className="headerdiv">
              <h1>
                We're thrilled to
                <br />
                have you on board.
              </h1>
              <img src="/images/onboard_text_image2.svg" alt="icon"/>
            </div>
            <div className="subdiv">
              <p>
                Let's get started in creating the perfect event that suits your
                needs.
              </p>
              <img src="/images/magicpen.svg" alt="magic-pen"/>
            </div>
          </div>
          <OnboardB title="Create, manage, and promote events, all in one place." />
          <OnboardB title="Create, manage, and promote events, all in one place." />
          <OnboardB title="Create, manage, and promote events, all in one place." />
          <div className="button">
            <PrimaryButton
              bg="#0D0D0D"
              title="Get started now"
              to="/host-onboard-event-type"
            />
          </div>
        </div>
        <div className="rightdiv">
          <img
            src="/images/onboard_hero.svg"
            className="onboard"
            alt="onboard"
          />
        </div>
      </div>
    </StyledOnboard>
  );
};

const StyledOnboard = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 5%;
  & .proskip {
    display: flex;
    align-items: center;
    margin-top: 10px;
    & .image2 {
      cursor: pointer;
    }
  }
  & .parent {
    display: flex;

    & .leftdiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: white;
      & .textdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .headerdiv {
          display: flex;
          align-items: center;
          gap: 1em;
          margin-top: 30px;

          & h1 {
            font-weight: 500;
            font-size: 30px;
            color: #000;
            line-height: 44px;
            font-family: "MatterMedium", sans-serif;
          }
          & img {
            width: auto;
            height: 70px;
          }
        }
        & .subdiv {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          margin-top: 20px;
          & p {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: #2d2d2d;
          }
        }
      }
    }
    & .rightdiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(232, 232, 232, 0.18);

      & .onboard {
        width: auto;
        height: 600px;
      }
    }
  }

  @media (max-width: 1200px) {
    justify-content: flex-start;

    & .proskip {
      display: flex;
      & .image2 {
        width: auto;
        height: 60px;
      }
    }
    & .parent {
      display: flex;
      width: 100%;
      & .leftdiv {
        width: 100%;
        display: flex;
        flex-direction: column;

        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .headerdiv {
            display: flex;
            gap: 1rem;
            margin-top: 0px;
            & h1 {
              font-size: 30px;
            }
            & img {
              width: auto;
              height: 60px;
            }
          }
          & .subdiv {
            display: flex;
            margin-bottom: 10px;
            gap: 1rem;
            margin-top: 10px;
            & p {
              font-size: 18px;
              line-height: 1.5rem;
            }
          }
        }
      }
      & .rightdiv {
        width: 100%;
        & .onboard {
          width: auto;
          height: 350px;
        }
      }
    }
  }
  @media (max-width: 640px) {
    height: auto;

    & .parent {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .leftdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .headerdiv {
            display: flex;
            gap: 1rem;
            margin-top: 0px;
            & h1 {
              font-size: 23px;
              line-height: 2.3rem;
            }
            & img {
              width: auto;
              height: 60px;
              align-self: center;
            }
          }
          & .subdiv {
            display: flex;
            margin-bottom: 10px;
            gap: 0rem;
            margin-top: 10px;
            & p {
              font-size: 17px;
              padding-right: 80px;
            }
          }
        }
      }
      & .rightdiv {
        width: 100%;
        & .onboard {
          width: auto;
          height: 600px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    height: auto;
    & .proskip {
      display: flex;
      flex-direction: column;
      & .image2 {
        width: auto;
        height: 60px;
      }
    }
    & .parent {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .leftdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & .button {
          align-self: center;
        }
        & .textdiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & .headerdiv {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 0px;
            & h1 {
              font-size: 30px;
              text-align: center;
            }
            & img {
              width: auto;
              height: 60px;
              align-self: center;
            }
          }
          & .subdiv {
            display: flex;

            margin-bottom: 10px;
            gap: 1rem;
            margin-top: 10px;
            & p {
              font-size: 18px;
              line-height: 1.5rem;
              padding: 0px;
            }
          }
        }
      }
      & .rightdiv {
        width: 100%;
        & .onboard {
          width: auto;
          height: 500px;
        }
      }
    }
  }
`;
