import React from "react";
import { StatWrapper } from "../../components/dashboardmain/Statistics";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { DashHeader } from "../../components/dashboardmain/Header";
import { DashHeader3 } from "../../components/dashboardmain/Header";
import { GetStarted } from "../../components/dashboardmain/GetStarted";
import { WelcomeHost } from "../../components/dashboardmain/Welcome";
import Modal from "../../components/ModalBox/Modal";
import { ModalUploadFile } from "../../components/ModalBox/ModalUploadFile";
import { ModalTextBox } from "../../components/ModalBox/ModalTextBox";
import { Button2 } from "../../components/button/Button2";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { ModalContentBox } from "../../components/ModalBox/ModalContentBox";
import { ModalUploadFileDisabled } from "../../components/ModalBox/ModalUploadFile";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import StatusHandler from "../../../../utils/statusHandler";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownMenu2,
  DropdownToggle,
  DropdownItem,
  StyledLink,
  StyledNavLink,
  StyledNavLink2,
  StyledLink2,
  DropdownWrapper,
} from "../../../../utils/styles/host/overview/overviewstyles";
import useHostOverview from "../../../../utils/apilogics/host/overviewLogic";

export const HostOverview = () => {
  const {
    pageNumber,
    setPageNumber,
    openDropdownIndex,
    loading,
    isModalUploadOpen,
    isModalActivationOpen,
    isModalMenuUploadedOpen,
    uuiId,
    selectedEventType,
    uploadError,
    uploads,
    hostdata,
    hostloading,
    hosterror,
    userdata,
    approvedeventddata,
    ongoingeventddata,
    openModal,
    openModalMenuUploaded,
    openModalRequest,
    closeModal,
    closeModalRequest,
    closeModalMenuUploaded,
    handleSearch,
    handleDropdownToggle,
    onSubmitRequestActivation,
    onSubmitUploadMenu,
    handleFileSelect,
    setOpenDropdownIndex,
    formik,
  } = useHostOverview();

  return (
    <Wrapper>
      {isModalMenuUploadedOpen && (
        <Modal
          imageSource="images/mdt3.png"
          title="Uploaded Menu"
          borderColor="0.5px solid rgba(0, 160, 64, 1)"
          backgroundColor="rgba(237, 255, 244, 1)"
          textColor="rgba(0, 160, 64, 1)"
          rightIconSrc="images/mdx.svg"
          onClose={closeModalMenuUploaded}
          scroll
        >
          {uploads && uploads?.length < 1 ? (
            <ModalEmpty title="No menu uploaded yet!!!" />
          ) : (
            uploads?.map((e) => (
              <ModalUploadFileDisabled
                key={e.id}
                name="upload_menu"
                dateupload={e.created_at}
                content={e.upload_menu}
                note={e.upload_note || "No note added"}
              />
            ))
          )}
        </Modal>
      )}
      {isModalUploadOpen && (
        <Modal
          imageSource="images/mdt1.png"
          title="Upload Menu"
          rightIconSrc="images/mdx.svg"
          onClose={closeModal}
        >
          <img src="images/mdmenuformat.png" className="format" alt="format" />
          <ModalContentBox
            Content={`${selectedEventType} with event id of ${uuiId.substring(
              0,
              5
            )}`}
          />

          <ModalUploadFile
            name="upload_menu"
            onFileSelect={handleFileSelect}
            error={uploadError}
          />

          <ModalTextBox
            handleTextChange={formik.handleChange}
            name="upload_note"
            value={formik.values.upload_note}
          />

          <div className="align">
            <Button2
              title="Upload"
              src="/images/uploadd.svg"
              backgroundColor={
                loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
              }
              onClick={onSubmitUploadMenu}
              alt="upload"
            />
          </div>
        </Modal>
      )}
      {isModalActivationOpen && (
        <Modal
          imageSource="images/mdt2.png"
          title="Event Activation Request"
          borderColor="1px solid rgba(0, 160, 64, 1)"
          backgroundColor="rgba(237, 255, 244, 1)"
          textColor="rgba(0, 160, 64, 1)"
          rightIconSrc="images/mdx.svg"
          onClose={closeModalRequest}
        >
          <ModalContentBox
            Content={`${selectedEventType} with event id of ${uuiId.substring(
              0,
              5
            )}`}
            title="Activate Event"
            // disabled
          />
          <ModalNote
            note="Kindly check and clarify you have uploaded menu data and are satisfied
        about the event created before making this request."
          />
          <div className="align">
            <Button2
              title="Request Activation"
              src="/images/mdgreen.svg"
              backgroundColor={
                loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
              }
              onClick={onSubmitRequestActivation}
            />
          </div>
        </Modal>
      )}

      <MainContent>
        <CenteredContent>
          {hostdata && hostdata?.data?.results.length > 0 ? (
            <DashHeader
              title={`Hi ${userdata?.data?.firstname}, Welcome!`}
              toeventOnboard="/host-onboard-event-type"
              titleButton="Create an Event"
            />
          ) : (
            <DashHeader3 title="Get Started" />
          )}
        </CenteredContent>
        <CenteredContent>
          {hostdata && hostdata?.data?.results.length > 0 ? (
            <WelcomeHost />
          ) : (
            <GetStarted toeventOnboard="/host-onboard-event-type" />
          )}
        </CenteredContent>

        <StatWrapper
          figureTotal={hostdata?.data?.count}
          figureOngoing={ongoingeventddata ? ongoingeventddata?.data.count : 0}
          figureApproved={
            approvedeventddata ? approvedeventddata?.data.count : 0
          }
        />
        <Table>
          <TableData
            headtitle="All Events"
            onChange={handleSearch}
            placeholder="Search event"
            {...(!hostloading && {
              host:
                hostdata && hostdata?.data?.results.length === 0
                  ? ""
                  : hostdata,
            })}
            showActions
          >
            <StatusHandler isError={hosterror} isLoading={hostloading}>
              {hostdata && hostdata?.data?.results.length === 0 ? (
                <ModalEmpty title="no event found" />
              ) : (
                hostdata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="event id">
                      <StyledNavLink2
                        to={`/dashboard-host-event/${e.event_uuid}/details`}
                      >
                        {e.event_uuid.substring(0, 5)}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="guest number">{e.event_guest}</td>
                    <td data-cell="location">{e.city}</td>
                    <td data-cell="event type">{e.event_type}</td>
                    <td data-cell="date">{e.event_date}</td>

                    {e.active ? (
                      <td data-cell="actions" className="dropdown action">
                        <DropdownWrapper>
                          <DropdownToggle
                            onClick={() => handleDropdownToggle(index)}
                          >
                            <img
                              src="/images/more.svg"
                              className="more"
                              alt="more"
                            />
                          </DropdownToggle>
                          <DropdownMenu2
                            onMouseLeave={() => setOpenDropdownIndex(null)}
                            className={
                              openDropdownIndex === index ? "open" : ""
                            }
                          >
                            <DropdownItem>
                              <StyledNavLink
                                to={`/dashboard-host-event/${e.event_uuid}/details`}
                                className="nav-link"
                              >
                                view event details
                              </StyledNavLink>
                            </DropdownItem>
                          </DropdownMenu2>
                        </DropdownWrapper>
                      </td>
                    ) : (
                      <td data-cell="actions" className="dropdown action">
                        <DropdownWrapper>
                          <DropdownToggle
                            onClick={() =>
                              handleDropdownToggle(
                                index,
                                e.id,
                                e.event_uuid,
                                e.event_type
                              )
                            }
                          >
                            <img
                              src="/images/more.svg"
                              className="more"
                              alt="more"
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            onMouseLeave={() => setOpenDropdownIndex(null)}
                            className={
                              openDropdownIndex === index ? "open" : ""
                            }
                          >
                            <DropdownItem>
                              <StyledNavLink
                                to={`/dashboard-host-event/${e.event_uuid}/details`}
                                className="nav-link"
                              >
                                view event details
                              </StyledNavLink>
                            </DropdownItem>
                            <DropdownItem>
                              <StyledLink onClick={openModal}>
                                upload menu
                              </StyledLink>
                            </DropdownItem>
                            <DropdownItem>
                              <StyledLink onClick={openModalMenuUploaded}>
                                view uploaded menu
                              </StyledLink>
                            </DropdownItem>
                            <DropdownItem>
                              <StyledLink onClick={openModalRequest}>
                                request activation
                                <img src="/images/verify.svg" alt="verify" />
                              </StyledLink>
                            </DropdownItem>
                            <DropdownItem className="disabled-button">
                              <StyledLink2>request event delete</StyledLink2>
                            </DropdownItem>
                          </DropdownMenu>
                        </DropdownWrapper>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {hostdata && hostdata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={hostdata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
