import React from "react";
import styled from "styled-components";

export const WaiterHeader = ({ title, children }) => {
  return (
    <StyledHeader>
      <div>
          <p className="title">{title}</p>
        </div>
        
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  font-family: "MatterNormal", sans-serif;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #969696;
  }
  @media (max-width: 640px) {
    .title {
      font-size: 17px;

  }
  }
  @media (max-width: 480px) {
    .title {
      font-size: 18px;
    font-weight: 500;
    /* letter-spacing: 2px; */
  }
  }

`;
