import React, { useEffect } from "react";
import { WelcomeScreen } from "./welcome/Welcome";
import FoodCart from "./foodcart/FoodCart";
import Menu from "./menu/Menu";
import CallWaiter from "./callwaiter/CallWaiter";
import IndexOthers from "./anything/Home";
import withBottomNav from "../components/WithBottomNav";

export const WelcomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="screen-size-detect">
      <WelcomeScreen />
    </div>
  );
};


export const MenuPage = withBottomNav(Menu);
export const FoodCartPage = withBottomNav(FoodCart);
export const CallWaiterPage = withBottomNav(CallWaiter);
export const OthersPage = withBottomNav(IndexOthers);


