import React, { useState } from "react";
import styled from "styled-components";

export const ModalTextBox = ({ handleTextChange, value, name }) => {
  return (
    <StyledTextBox>
      <Textarea
        placeholder="Leave a note"
        value={value}
        onChange={handleTextChange}
        name={name}
      />
    </StyledTextBox>
  );
};

export const ModalTextBox2 = ({ handleTextChange, value, name }) => {
  return (
    <Wrapper>
      <label className="label">Description</label>
      <StyledTextBox2>
        <Textarea2
          placeholder="Leave a menu description"
          value={value}
          onChange={handleTextChange}
          name={name}
        />
      </StyledTextBox2>
    </Wrapper>
  );
};
const StyledTextBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const StyledTextBox2 = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Wrapper = styled.div`
  margin-bottom: 10px;
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }
  @media (max-width: 640px) {
    width: 100%;

    .label {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    width: auto;

    .label {
      font-size: 18px;
    }
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 18px 20px;
  border: none;
  border-radius: 12px;
  background-color: rgba(247, 248, 255, 1);
  resize: none;
  font-size: 13px;
  font-weight: 400;
  font-family: "MatterNormal", sans-serif;
  color: rgba(62, 62, 62, 1);

  @media (max-width: 480px) {
    font-size: 15px !important;
  }
`;
const Textarea2 = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 18px 20px;
  border: none;
  border-radius: 12px;
  border: 1px solid #000;
  background-color: white;
  resize: none;
  font-size: 13px;
  font-weight: 400;
  font-family: "MatterNormal", sans-serif;
  color: rgba(62, 62, 62, 1);

  @media (max-width: 480px) {
    font-size: 15px !important;
  }
`;
