import React, { useState } from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { DashHeader2 } from "../../components/dashboardmain/Header";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import useHostApprovedEvent from "../../../../utils/apilogics/host/approvedLogic";
import StatusHandler from "../../../../utils/statusHandler";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  StyledNavLink2,
} from "../../../../utils/styles/host/approvedstyles";

export default function HostApprovedEvent() {
  const {
    searchTerm,
    pageNumber,
    setPageNumber,
    hoastapprovedloading,
    hostapproveddata,
    hostapprovederror,
    handleSearch,
  } = useHostApprovedEvent();

  return (
    <Wrapper>
      <MainContent>
        <CenteredContent>
          <DashHeader2
            title="Approved Event"
            towhere="/dashboard-host-overview"
          />
        </CenteredContent>
        <Table>
          <TableData
            headtitle="All Approved Event"
            onChange={handleSearch}
            placeholder="Search approved"
            {...(!hoastapprovedloading && {
              others:
                hostapproveddata && hostapproveddata?.data?.results.length === 0
                  ? ""
                  : hostapproveddata,
            })}
            others={
              hostapproveddata && hostapproveddata?.data?.results.length === 0
                ? ""
                : hostapproveddata
            }
          >
            <StatusHandler
              isError={hostapprovederror}
              isLoading={hoastapprovedloading}
            >
              {hostapproveddata &&
              hostapproveddata?.data?.results.length === 0 ? (
                <ModalEmpty title="no approved event found" />
              ) : (
                hostapproveddata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="event id">
                      <StyledNavLink2
                        to={`/dashboard-host-event/${e.event_uuid}/details`}
                      >
                        {e.event_uuid.substring(0, 5)}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="location">{e.city}</td>
                    <td data-cell="event type">{e.event_type}</td>
                    <td data-cell="date">{e.event_date}</td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {hostapproveddata && hostapproveddata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={hostapproveddata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
}
