import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { URL } from "../../../../utils/url";
import StatusHandler from "../../../../utils/statusHandler";

const IllusionContainer = styled.div`
  text-align: center;
  /* background: white; */
  /* padding: 10px; */
  /* border-radius: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Caption = styled.div`
  margin-top: 20px;
  font-family: "MatterNormal", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
`;

export const Optical = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedEventId = localStorage.getItem("eventId");
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${URL}trivia-optical/${storedEventId}`
        );
        const image = response.data[0];
        setImageUrl(image.optical_image);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, []);

  return (
    <>
    <IllusionContainer imageUrl={imageUrl}>
      <StatusHandler isError={error} isLoading={loading}>
      </StatusHandler>
    </IllusionContainer>
        <Caption>What do you see?</Caption>
    </>
  );
};
