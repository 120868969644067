import { useState} from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../request";

const useAdminApprovedEvent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const fetchApprovedEvent = (page, searchTerm) => {
    let url = `/superadmin/approved-event/get/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    isLoading: allapprovedloading,
    data: allapproveddata,
    error: allapprovederror,
  } = useQuery(
    ["admin-approvedevnt", pageNumber, searchTerm],
    () => fetchApprovedEvent(pageNumber, searchTerm),
    {
      enabled: true,
    }
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    allapprovedloading,
    allapproveddata,
    allapprovederror,
    handleSearch,
  };
};

export default useAdminApprovedEvent;
