import React, { useState, useContext } from "react";
import styled from "styled-components";
import { PrimaryButton2 } from "../../../../website/components/Button";
import ProgressBar2 from "../../../components/progressbar/Progress2";
import { useDropzone } from "react-dropzone";
import { NavLink, useNavigate } from "react-router-dom";
import { ContextStore } from "../../../../../context/contextStore";
import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../../../../../utils/url";
import request, { headers } from "../../../../../utils/request";
import { useQuery } from "react-query";

const maxSize = 2000000; // 2MB

export const OnboardEventMedia = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    eventType,
    eventToast,
    eventTag,
    eventNumber,
    eventCountry,
    eventState,
    eventCity,
    eventAddress,
    eventTime,
    eventDate,
  } = useContext(ContextStore);

  const navigate = useNavigate();
  const { data: userdata} = useQuery(
    "user",
    () => {
      return request.get("/user/", headers(localStorage.getItem("token")));
    },
    {
      enabled: true,
    }
  );
  const multipartConfig = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      setErrorMessage("Invalid file type. Please select a JPEG or PNG file.");
      return;
    }

    if (file.size > maxSize) {
      setErrorMessage("Max Size: 2MB");
      return;
    }

    setSelectedFile(file);
    setErrorMessage("");
  };

  const handleComplete = async () => {
    if (!selectedFile) {
      setErrorMessage("No file selected.");
      return;
    }

    const payload = {
      user_id: userdata?.data.id,
      street_address: eventAddress,
      state: eventState,
      city: eventCity,
      country: eventCountry,
      event_date: eventDate.toISOString().split("T")[0],
      event_time: eventTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      event_guest: eventNumber,
      event_type: eventType,
      event_tag: eventTag,
      event_toast: eventToast,
      even_background: selectedFile,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${URL}event/`,
        payload,
        multipartConfig
      );
      toast.success("event created successfully");
      navigate("/dashboard-host-overview");
      setLoading(false);
    } catch (error) {
      toast.error("event creation failed");
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });

  const fileSizeError =
    selectedFile && selectedFile.size > maxSize ? "Max Size: 2MB" : "";

  const errorMessageToShow = errorMessage || fileSizeError;

  return (
    <StyledOnboardEventInfo>
      <div className="proskip">
        <ProgressBar2 start={60} end={80} />
      </div>
      <NavLink id="curs" exact={true} to="/host-onboard-event-info">
        <img src="/images/backk.svg" className="backk" alt="back" />
      </NavLink>
      <div className="div1">
        <div className="rdiv">
          <img src="/images/mediaa.png" className="onboard" alt="media" />
        </div>
        <div className="ldiv">
          <div className="textdiv">
            <div className="headerdiv">
              <h1>Event Media</h1>
            </div>
            <div className="subdiv">
              <div>
                <p>Upload event banner image, a high quality image.</p>
              </div>
              <div>
                <img src="/images/media_1.svg" alt="media-icon" />
              </div>
            </div>
            <div className="box">
              <div className="flex">
                <img src="/images/bulb.svg" alt="bulb-icon" />
                <div>|</div>
              </div>
              <div>
                <p>
                  This image would be used to customize your event as seen on
                  the image on the left{" "}
                </p>
              </div>
            </div>
            <div className="drag-box" {...getRootProps()}>
              <input {...getInputProps()} />
              <img src="/images/media_2.svg" alt="media-icon" />
              <p className="p">
                {selectedFile ? selectedFile.name : "Drag and drop images or"}
              </p>
              <div className="button">
                <p>Browse file</p>
              </div>
            </div>
            <div className="butt">
              <p style={{ color: errorMessageToShow ? "red" : "inherit" }}>
                {errorMessageToShow ||
                  `Jpeg or Png supported | Max Size: ${fileSizeError}`}
              </p>
            </div>
            <div className="butt">
              <PrimaryButton2
                bg={loading ? "rgba(255, 92, 0, 0.1)" : "#0D0D0D"}
                title="Complete"
                onClick={handleComplete}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledOnboardEventInfo>
  );
};
const StyledOnboardEventInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 5%;

  & .backk {
    cursor: pointer;
    width: auto;
    height: 30px;
    margin-top: 40px;
  }

  & .proskip {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  & .div1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & .ldiv {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      & .wrapp {
      }
      & .textdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .headerdiv {
          display: flex;
          align-items: center;
          gap: 1em;
          margin-top: 30px;

          & h1 {
            font-weight: 500;
            font-size: 25px;
            color: #000;
            line-height: 44px;
            font-family: "MatterMedium", sans-serif;
          }
          & img {
            width: auto;
            height: 70px;
          }
        }
        & .subdiv {
          display: flex;
          gap: 1.5rem;
          margin-top: 20px;
          position: relative;
          margin-bottom: 40px;

          & img {
            position: absolute;
            top: -45px;
            width: auto;
            /* right: -35px; */
            height: 120px;
          }
          & p {
            text-align: left;
            line-height: 1.5rem;
            font-size: 15px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: #2d2d2d;
          }
        }
        & .box {
          background-color: #f2f4ff;
          border-radius: 30px;
          display: flex;
          align-items: center;
          padding: 5px 15px;
          gap: 0.5rem;
          & img {
            width: auto;
            height: 20px;
          }
          & p {
            text-align: left;
            line-height: 1.5rem;
            font-size: 12px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: #2d2d2d;
          }

          & .flex {
            display: flex;
            align-items: center;
            gap: 0.6rem;
          }
        }
        & .drag-box {
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(0, 0, 0, 0.07);
          height: 250px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          margin-top: 20px;
          & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(13, 13, 13, 0.1);
            padding: 10px 30px;
            border-radius: 32px;
            margin-top: 10px;
            font-size: 13px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: white;
            cursor: pointer;
          }
          & .p {
            text-align: left;
            line-height: 1.5rem;
            font-size: 13px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: #2d2d2d;
            margin-top: 10px;
          }
        }
        & .butt {
          align-self: flex-end;
          margin-top: 20px;
          & p {
            text-align: left;
            line-height: 1.5rem;
            font-size: 13px;
            font-weight: 400;
            font-family: "MatterNormal", sans-serif;
            color: #2d2d2d;
          }
        }
      }
    }

    & .rdiv {
      position: relative;

      & .onboard {
        position: absolute;
        width: auto;
        height: 600px;
        left: -50px;
      }
    }
  }

  @media (max-width: 1200px) {
    & .proskip {
      display: flex;
    }
    & .div1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .ldiv {
        width: 100%;
        & .textdiv {
          & .headerdiv {
            margin-top: 30px;

            & h1 {
              font-size: 30px;
            }
            & img {
              width: auto;
              height: 70px;
            }
          }
          & .subdiv {
            display: flex;
            margin-top: 20px;
            position: relative;
            margin-bottom: 40px;
            & img {
              display: none;
            }
            & p {
              font-size: 19px;
            }
          }
          & .box {
            & img {
              width: auto;
              height: 25px;
            }
            & p {
              font-size: 18px;
            }

            & .flex {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 0.6rem;
            }
          }
          & .drag-box {
            height: 300px;
            margin-top: 20px;
            & .button {
              margin-top: 20px;
              font-size: 15px;
              padding: 12px 40px;
            }
            & .p {
              font-size: 18px;
              margin-top: 20px;
            }
          }
          & .butt {
            align-self: center;
            margin-top: 20px;
            & p {
              font-size: 18px;
            }
          }
        }
      }

      & .rdiv {
        & .onboard {
          width: auto;
          height: 500px;
          left: -40px;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 640px) {
    & .div1 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & .ldiv {
        width: 100%;
        & .textdiv {
          & .headerdiv {
            margin-top: 30px;

            & h1 {
              font-size: 23px;
            }
            & img {
              width: auto;
              height: 70px;
            }
          }
          & .subdiv {
            display: flex;
            margin-top: 20px;
            position: relative;
            margin-bottom: 40px;

            & img {
              display: none;
            }
            & p {
              font-size: 18px;
            }
          }
          & .box {
            & img {
              width: auto;
              height: 25px;
            }
            & p {
              font-size: 17px;
            }

            & .flex {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 0.6rem;
            }
          }
          & .drag-box {
            height: 300px;
            margin-top: 20px;
            & .button {
              margin-top: 20px;
              font-size: 14px;
              padding: 12px 40px;
            }
            & .p {
              font-size: 17px;
              margin-top: 20px;
            }
          }
          & .butt {
            align-self: center;
            margin-top: 20px;
            & p {
              font-size: 17px;
            }
          }
        }
      }

      & .rdiv {
        & .onboard {
          width: auto;
          height: 600px;
          left: 0px;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 480px) {
    & .div1 {
      display: flex;
      justify-content: space-between;

      & .ldiv {
        width: 100%;
        & .textdiv {
          & .headerdiv {
            margin-top: 30px;

            & h1 {
              font-size: 30px;
            }
            & img {
              width: auto;
              height: 70px;
            }
          }
          & .subdiv {
            display: flex;
            margin-top: 20px;
            position: relative;
            margin-bottom: 40px;

            & p {
              font-size: 19px;
            }
          }
          & .box {
            & img {
              width: auto;
              height: 30px;
            }
            & p {
              font-size: 18px;
            }

            & .flex {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 0.6rem;
            }
          }
          & .drag-box {
            height: 300px;
            margin-top: 20px;
            & .button {
              margin-top: 20px;
              font-size: 18px;
              padding: 12px 40px;
            }
            & .p {
              font-size: 18px;
              margin-top: 20px;
            }
          }
          & .butt {
            align-self: flex-end;
            margin-top: 20px;
            & p {
              font-size: 18px;
            }
          }
        }
      }

      & .rdiv {
        & .onboard {
          width: auto;
          height: 500px;
          position: relative;
        }
      }
    }
  }
`;
