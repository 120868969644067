import React, { forwardRef } from "react";
import styled from "styled-components";
import { format, startOfTomorrow } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="parent_time">
    <img src="/images/calender.svg" alt="calendar icon" />
    <p onClick={onClick} ref={ref}>
      {value}
    </p>
  </div>
));

export const DatePick = ({ error, selectedDate, handleDateChange }) => {
  return (
    <Wrapper>
      <label className="label">Event Date</label>
      <StyledDateTimePicker>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          customInput={
            <DateCustomInput>
              {format(selectedDate, "yyyy/MM/dd")}
            </DateCustomInput>
          }
          // minDate={startOfTomorrow()}
        />
        {error && <p id="text-danger">{error}</p>}
      </StyledDateTimePicker>
    </Wrapper>
  );
};

const TimeCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="parent_time">
    <img src="/images/clock.svg" alt="clock-icon" />
    <p onClick={onClick} ref={ref}>
      {value}
    </p>
  </div>
));

export const Time = ({ error, handleTimeChange, selectedTime }) => {
  return (
    <Wrapper>
      <label className="label">Event Time</label>
      <StyledDateTimePicker>
        <DatePicker
          selected={selectedTime}
          onChange={handleTimeChange}
          customInput={
            <TimeCustomInput>{format(selectedTime, "h:mm aa")}</TimeCustomInput>
          }
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          dateFormat="h:mm aa"
        />
        {error && <p id="text-danger">{error}</p>}
      </StyledDateTimePicker>
    </Wrapper>
  );
};

const StyledDateTimePicker = styled.div`
  border-radius: 8px;
  width: 100%;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 20px;
  height: 42px;
  cursor: pointer;
  margin-top: 15px;

  .parent_time {
    display: flex;
    align-items: center;
    gap: 1rem;

    & img {
      width: auto;
      height: 25px;
    }

    & p {
      color: #000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 2px;
      font-family: "MatterMedium", sans-serif;
    }
  }

  #text-danger {
    color: red;
    font-size: 0.8rem;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    width: 100%;

    .parent_time {
      & img {
        height: 25px;
      }

      & p {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    width: auto;
    .parent_time {
      & img {
        height: 22px;
      }

      & p {
        font-size: 15px;
      }
    }
  }
`;

const Wrapper = styled.div`
  margin-bottom: 10px;
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }
  @media (max-width: 640px) {
    width: 100%;

    .label {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    width: auto;
    margin-top: 15px;
    .label {
      font-size: 18px;
    }
  }
`;
