import React,{useState}from "react";
import useTriviaQ from "../../../../utils/apilogics/webapp/useTriviaQuestion";
import styled from 'styled-components';
import { Button4 } from "../../../dashboard/components/button/Button2";

const Start = ({ onStart, active }) => (
  <Identity>
    <Button4
      title="Start Trivia"
      onClick={onStart}
      backgroundColor="green"
      disabled={!active}
    />
  </Identity>
);

const NameInput = ({ onNameSubmit }) => {
  const [name, setName] = useState("");
  const [isEmpty, setIsEmpty] =useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      setIsEmpty(true);
      return;
    }
    onNameSubmit(name);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Identity>
        <input
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setIsEmpty(false);
          }}
          placeholder="Enter your name"
          className={isEmpty ? "error" : ""}
        />
        <Button4 title="Continue" type="submit" />
        {isEmpty && <ErrorMessage>Please enter your name.</ErrorMessage>}
      </Identity>
    </form>
  );
};

const Trivia = () => {
  const {
    showQuiz,
    showNameInput,
    showScore,
    questions,
    currentQuestion,
    playerName,
    score,
    timer,
    active,
    secondsUsed,
    handleStart,
    handleNameSubmit,
    handleAnswerOptionClick,
  } = useTriviaQ();

  return (
    <StyledTrivia>
      <div className="app Boxes">
        {!showNameInput && !showQuiz && <Start onStart={handleStart} active={active} />}
        {!showQuiz && showNameInput && <NameInput onNameSubmit={handleNameSubmit} />}
        {showQuiz && !showScore && (
          <>
            <div className="question-section">
              <div className="question-count">
                <span>Question {currentQuestion + 1}</span>/{questions.length}
              </div>
              <div className="question-text">
                {questions[currentQuestion].text}
              </div>
            </div>
            <div className="answer-section">
              {questions[currentQuestion].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerOptionClick(option.is_correct)}
                >
                  {option.text}
                </button>
              ))}
            </div>
          </>
        )}
        {showScore && (
          <div className="score-section">
            <p>
              {playerName}, you scored {score} out of {questions.length}
              <br />
              in {secondsUsed} seconds
            </p>
          </div>
        )}
        {showQuiz && (
          <div className="timer-section">
            <p className="timer-text">Time Left: {timer} seconds</p>
          </div>
        )}
      </div>
    </StyledTrivia>
  );
};

export default Trivia;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
`;

const Identity = styled.div`
  input {
    width: 100%;
    margin-bottom: 30px;
    color: rgba(255, 92, 0, 1);
    font-family: "MatterNormal", sans-serif;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid rgba(255, 92, 0, 1);
    padding-bottom: 10px;
    background: transparent;
  }
  input:focus {
    border: none;
    border-bottom: 1px solid white;
    background: transparent;
    outline: none;
  }
`;

const StyledTrivia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .app {
    background-color: rgba(255, 92, 0, 1);
    background-color: black;
    width: 350px;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    color: rgba(255, 92, 0, 1);
    font-family: "MatterNormal", sans-serif;
  }

  .score-section {
    display: flex;
    justify-content: center;
    font-size: 16px;
    align-items: center;
    text-align: center;
    line-height: 20px;
  }

  .question-section {
    width: 100%;
    position: relative;
  }

  .question-count {
    margin-bottom: 20px;
  }

  .question-count span {
    font-size: 25px;
  }

  .question-text {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .timer-section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .timer-text {
    background: black;
    padding: 15px;
    margin-right: 20px;
    border: 1px solid rgba(255, 92, 0, 1);
    border-radius: 15px;
    text-align: center;
    color: red;
    font-size: 13px;
  }

  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  button {
    width: 100%;
    font-size: 13px;
    color: #ffffff;
    background-color: black;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid white;
    cursor: pointer;
  }

  .correct {
    background-color: #2f922f;
  }

  .incorrect {
    background-color: #ff3333;
  }

  button:hover {
    color: white;
  }

  button:focus {
    outline: none;
  }

  button svg {
    margin-right: 5px;
  }

  @media (max-width: 850px) {
    .app {
      width: 320px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
    }
  }
`;
