export const guestlogininitialValues = {
    event_code: "",
  };
  
  export const callwaiterinitialValues = {
    tableNo: "",
    seatNo: "",
  };
  
  export const cartinitialValues = {
    table: "",
    seat: "",
  };
  