import { useState } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../request";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { URL } from "../../url";
import axios from "axios";

const useAdminRequest = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalUpdateRequestOpen, setModalUpdateRequestOpen] = useState(false);
  const [dropError, setDropError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [requestid, setRequestid] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const closeModalUpdateRequest = () => {
    setModalUpdateRequestOpen(false);
  };
  const openModalUpdateRequest = () => {
    setModalUpdateRequestOpen(true);
  };
  const fetchAllRequest = (page, searchTerm) => {
    let url = `/all-request-activation/?page=${page}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: activationrequestdata,
    isLoading: activationrequestloading,
    error: activationrequesterror,
    refetch,
  } = useQuery(
    ["activationrequest", pageNumber, searchTerm],
    () => fetchAllRequest(pageNumber, searchTerm),
    {
      enabled: true,
    }
  );

  const initialValues = {
    activation_status: "",
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const onSubmitActivationRequestUpdate = async (values, { resetForm }) => {
    if (!selectedOption) {
      setDropError("please select an option.");
      return;
    }
    values.activation_status = selectedOption;
    setLoading(true);

    try {
      const response = await axios.post(
        `${URL}request-activation-update/${requestid}/`,
        values,
        headers(localStorage.getItem("token"))
      );
      toast.success("status updated successfully!");
      resetForm();
      setModalUpdateRequestOpen(false);
      refetch();
      setLoading(false);
    } catch (error) {
      toast.error("activation request update failed");
      setLoading(false);
      setModalUpdateRequestOpen(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitActivationRequestUpdate,
  });

  return {
    openDropdownIndex,
    setOpenDropdownIndex,
    loading,
    isModalUpdateRequestOpen,
    dropError,
    searchTerm,
    setSearchTerm,
    pageNumber,
    setPageNumber,
    requestid,
    setRequestid,
    selectedOption,
    setSelectedOption,
    activationrequestdata,
    activationrequestloading,
    activationrequesterror,
    refetch,
    setDropError,
    closeModalUpdateRequest,
    openModalUpdateRequest,
    handleSearch,
    handleDropdownToggle,
    formik,
  };
};

export default useAdminRequest;
