// PrivacyCheckbox.js
import React from "react";
import styled from "styled-components";

const PrivacyCheckbox = ({ label, id, name, checked, onChange }) => {
  return (
    <StyledCheckbox>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}> You agree to our friendly <a href="/privacy">privacy policy.</a></label>
        
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  & input {
    margin-right: 20px;
    transform: scale(1.5);
  }
  & a {
    color: #667085; 
    text-decoration: underline; 
    cursor: pointer; 
  }
 
  & label {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    font-family: "MatterNormal", sans-serif;
    color: #667085;
  }

  @media (max-width: 1024px) {
    & input {
    transform: scale(1.6);
  }
 
 
  & label {
    font-size: 16px;
  }
  }
  @media (max-width: 640px) {
    & input {
    transform: scale(1.6);
  }
 
 
  & label {
    font-size: 16px;
  }
  }

  @media (max-width: 480px) {
    & input {
    margin-right: 20px;
    transform: scale(2);
  }
 
 
  & label {
    font-size: 18px;
  }
  }
`;

export default PrivacyCheckbox;
