import React from "react";

const ShortenedURL = ({ content }) => {
  let shortenedURL = "";

  const qrRegex = /\/qr_code\/(.*?)_/;

  if (content) {
    try {
      const url = new URL(content);
      const qrMatch = url.pathname.match(qrRegex);
      if (qrMatch) {
        shortenedURL = qrMatch[1];
      } else {
        const pathParts = url.pathname.split("/");
        const filename = pathParts[pathParts.length - 1];
        shortenedURL = filename;
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  }

  return (
    <a href={content} target="_blank" rel="noopener noreferrer">
      {shortenedURL || ""}
    </a>
  );
};

export default ShortenedURL;
