import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { URL } from "../../../utils/url";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "../components/Input/Input";
import CountryCodeInput from "../components/Input/InputCountry";
import PrivacyCheckbox from "../components/Input/CheckPrivacy";
import { ContactButton } from "../components/button/ContactButton";

export const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);

  const validationSchema = Yup.object({
    fullname: Yup.string().required("fullname is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("email is required"),
    message: Yup.string()
      .required("message is required ")
      .max(500, "Message is too long"),
  });

  const initialValues = {
    fullname: "",
    email: "",
    message: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    // console.log(values);

    setLoading(true);
    try {
      const response = await axios.post(`${URL}general-contact-us/`, values);
      const data = response.data;
      console.log(data, "wert");
      toast.success("Message sent!");
      resetForm();
      setCharacterCount(0); // Reset the character count
      setLoading(false);
    } catch (error) {
      console.log(error.response.data, "error");
      toast.error("Message submission failed");
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setCharacterCount(value.length);
    formik.handleChange(event); // Call formik's handleChange to update formik.values.message
  };

  return (
    <StyledContact>
      <div className="contactdiv">
        <div className="textdiv">
          <h1>How to reach us?</h1>
          <div className="mt-3">
            <p>
              Get in touch with our sales and support teams for demos,
              onboarding support, or product questions.
            </p>
          </div>
          {/* <div className="mt-3">
            <p>
              Get in touch with our sales and support teams for demos,
            </p>
          </div> */}
          <div>
            <img
              src="/images/contact.png"
              className="contact bouncing-element"
            />
          </div>
        </div>
        <div className="inputdiv">
          <div className="twodivs">
            <div className="leftdiv">
              <Input placeholder="first name" label="First name" />
            </div>
            <div className="rightdiv">
              <Input placeholder="last name" label="Last name" />
            </div>
          </div>
          <Input placeholder="you@company.com" label="Email" />
          <CountryCodeInput />
          <Input label="Message" multiple />
          <PrivacyCheckbox />
          <ContactButton />
        </div>
      </div>
    </StyledContact>
  );
};

const StyledContact = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 5%;

  .contactdiv {
    display: flex;
    justify-content: space-between;
    & .textdiv {
      /* background-color: red; */
      width: 100%;

      & h1 {
        font-weight: 400;
        font-size: 36px;
        color: #2c2c2c;
        line-height: 44px;
        font-family: "MatterNormal", sans-serif;
      }
      & p {
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        font-family: "MatterNormal", sans-serif;
        color: #000;
        line-height: 152%;
        padding-right: 190px;
      }
      & .contact {
        width: auto;
        height: 400px;
      }
    }
    & .inputdiv {
      border-radius: 38px;
      background: #fff;
      box-shadow: 0px 9px 58.568px 0px rgba(0, 0, 0, 0.05);
      width: 100%;
      padding: 30px;
      & .checkbox-div {
        display: flex;
        align-items: center;
      }
      & .twodivs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
        /* width: 100%; */
        & .leftdiv {
          width: 100%;
        }
        & .rightdiv {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    height: 100vh;
    .contactdiv {
      /* flex-direction: column; */
      justify-content: center;

      & .textdiv {
        & h1 {
          font-size: 30px;
        }
        & p {
          font-size: 15px;
          padding: 0px 0px;
        }
      }
      & .inputdiv {
        padding: 20px;
        margin-top: 0px;
      }
    }
  }
  @media (max-width: 1024px) {
    .contactdiv {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .textdiv {
        & h1 {
          font-size: 30px;
          text-align: center;
        }
        & p {
          font-size: 16px;
          padding: 0px 150px;
          text-align: center;
        }
      }
      & .inputdiv {
        padding: 20px;
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 640px) {
    /* height: 100vh; */
    margin-top: 50%;
    margin-bottom: 300px;
    .contactdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .textdiv {
        & h1 {
          font-size: 30px;
          text-align: center;
        }
        & p {
          font-size: 16px;
          padding: 0px 55px;
          text-align: center;
        }
        & .contact {
          width: auto;
          height: 350px;
        }
      }
      & .inputdiv {
        padding: 20px;
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 480px) {
    margin-top: 55%;
    margin-bottom: 250px;
    .contactdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .textdiv {
        position: relative;
        & h1 {
          font-size: 30px;
          text-align: center;
        }
        & p {
          font-size: 17px;
          padding: 0px 10px;
          /* text-align: center; */
        }
        & .contact {
          position: relative;
          top: 0;
          bottom: 0;
          left: 10%;
          width: auto;
          height: 280px;
        }
      }
      & .inputdiv {
        padding: 10px;
      }
    }
  }
  @media (max-width: 350px) {
    margin-top: 300px;
    margin-bottom: 180px;
    .contactdiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .textdiv {
        position: relative;
        & h1 {
          font-size: 30px;
          text-align: center;
        }
        & p {
          font-size: 17px;
          padding: 0px 10px;
          /* text-align: center; */
        }
        & .contact {
          position: relative;
          top: 0;
          bottom: 0;
          left: 10%;
          width: auto;
          height: 280px;
        }
      }
      & .inputdiv {
        padding: 10px;
      }
    }
  }
`;
