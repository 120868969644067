import { useState} from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../request";

const useAdminOngoingEvent = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
  
    const fetchOngoingEvent = (page, searchTerm) => {
      let url = `/superadmin/ongoing-event/get/?page=${page}`;
      if (searchTerm) {
        url += `&search=${searchTerm}`;
      }
  
      return request.get(url, headers(localStorage.getItem("token")));
    };
  
    const { data: ongoingeventdata,
        isLoading: ongoingloading,
        error: ongoingerror,
        refetch } = useQuery(
      ["admin-ongoing-event", pageNumber, searchTerm],
      () => fetchOngoingEvent(pageNumber, searchTerm),
      {
        enabled: true,
      }
    );
    const handleSearch = (event) => {
      const searchTerm = event.target.value;
      setSearchTerm(searchTerm);
      setPageNumber(1);
    };
  
  return {
    searchTerm,
    pageNumber,
    setPageNumber,
    ongoingloading,
    ongoingeventdata,
    ongoingerror,
    handleSearch,
  };
};

export default useAdminOngoingEvent;