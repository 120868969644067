export const GENDER_OPTIONS = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  
];

export const STATUS_OPTIONS = [
  { label: "approve activation request", value: "approved" },
  { label: "decline activation request", value: "declined" },
];
