import React from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import { dateConverter } from "../../../../utils/dateTimeConverter";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { ModalDropDown } from "../../components/ModalBox/ModalDropDown";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledLink,
  StyledNavLink,
  DropdownWrapper,
} from "../../../../utils/styles/admin/overview/reasonstyles";
import useAdminReason from "../../../../utils/apilogics/admin/overview/reasonLogic";
import StatusHandler from "../../../../utils/statusHandler";

export const AdminCallReason = () => {
  const {
    setPageNumber,
    openDropdownIndex,
    loading,
    isModalEditReasonOpen,
    isModalCreateReasonOpen,
    isModalDeleteReasonOpen,

    dropError,
    catid,
    pageNumber,
    callreasondata,
    callreasonerror,
    callreasonloading,
    category,
    id,
    removeFromCallReason,
    setSelectedCategory,
    handleSearch,
    openModalEditReason,
    openModalCreateReason,
    openModalDeleteReason,
    closeModalEditReason,
    closeModalDeleteVendor,
    closeModalCreateReason,
    handleDropdownToggle,
    setReasonId,
    setCatId,
    setOpenDropdownIndex,
    setDropError,
    formikUpdateReason,
    formikReasonCreate,
  } = useAdminReason();

  return (
    <Wrapper>
      {isModalEditReasonOpen && (
        <form onSubmit={formikUpdateReason.handleSubmit}>
          <Modal
            title="Edit Category"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditReason}
          >
            <div className="wrapper-div">
              <Input
                label="Category Name"
                type="text"
                name="reason"
                onChange={formikUpdateReason.handleChange}
                value={formikUpdateReason.values.reason}
                error={formikUpdateReason.errors.reason}
              />
            </div>
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteReasonOpen && (
        <Modal
          title="Delete Call Reasons"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeleteVendor}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="delete"
              onClick={() => removeFromCallReason(catid)} // Pass categoryIdToDelete here
            />
          </div>
        </Modal>
      )}
      {isModalCreateReasonOpen && (
        <form onSubmit={formikReasonCreate.handleSubmit}>
          <Modal
            title="Create Call Reasons"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateReason}
          >
            <div className="wrapper-div">
              <ModalDropDown
                options={category?.map((e, index) => ({
                  label: e.label,
                  value: e.value,
                }))}
                defaultOption={{ label: "Select Category", value: null }}
                onSelect={(selectedOption) => {
                  setSelectedCategory(selectedOption.value);
                  setDropError(null);
                }}
                error={dropError}
              />
              <div className="mt-4">
                <Input
                  label="Call Reason"
                  type="text"
                  name="reason"
                  onChange={formikReasonCreate.handleChange}
                  value={formikReasonCreate.values.reason}
                  error={formikReasonCreate.errors.reason}
                  placeholder="enter call reason"
                />
              </div>
            </div>
            <div className="align">
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick={`/dashboard-admin/${id}/menu-categories`}
            show
            title="create call reason"
            onClick={openModalCreateReason}
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Call Reasons"
            onChange={handleSearch}
            placeholder="search reasons"
            {...(!callreasonloading && {
              callreason:
                callreasondata && callreasondata?.data?.results.length === 0
                  ? ""
                  : callreasondata,
            })}
          >
            <StatusHandler
              isError={callreasonerror}
              isLoading={callreasonloading}
            >
              {callreasondata && callreasondata?.data?.results.length < 1 ? (
                <ModalEmpty title="no callreason found" />
              ) : (
                callreasondata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="Call Reason">{e.reason}</td>
                    <td data-cell="category">{e.cat_name}</td>
                    <td data-cell="created">{dateConverter(e.created_at)}</td>
                    <td data-cell="actions" className="dropdown action">
                      <DropdownWrapper>
                        <DropdownToggle
                          onClick={() => handleDropdownToggle(index, e.id)}
                        >
                          <img
                            src="/images/more.svg"
                            className="more"
                            alt="more"
                          />
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => setOpenDropdownIndex(null)}
                          className={openDropdownIndex === index ? "open" : ""}
                        >
                          <DropdownItem>
                            <StyledNavLink
                              onClick={() => {
                                setCatId(e.id);
                                openModalEditReason();
                              }}
                              className="nav-link"
                            >
                              edit call reason
                            </StyledNavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <StyledLink
                              onClick={() => {
                                setReasonId(e.id);
                                openModalDeleteReason();
                              }}
                            >
                              delete call reason
                            </StyledLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownWrapper>
                    </td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {callreasondata && callreasondata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={callreasondata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
};
