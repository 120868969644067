import React from "react";
import styled from "styled-components";

export const TableData = ({
  onClick,
  headtitle,
  children,
  host,
  admin,
  vendor,
  categories,
  callreason,
  request,
  menu,
  menuextra,
  others,
  triviaquestions,
  trivia,
  optical,
  placeholder,
  onChange,
  showActions,
  showInput,
}) => {
  return (
    <Div onClick={onClick}>
      <TableHeader>
        <di className="wraph1">
          <h1>{headtitle}</h1>
        </di>
        {!showInput && (
          <SearchInput>
            <div>
              <input placeholder={placeholder} onChange={onChange} />
            </div>
          </SearchInput>
        )}
      </TableHeader>
      <div className="line"></div>

      <TableContent>
        <table>
          <thead>
            {host && (
              <tr>
                <th data-cell="event_id">Event identity</th>
                <th data-cell="guest_number">Guest number</th>
                <th data-cell="location">City</th>
                <th data-cell="type">Event type</th>
                <th data-cell="date">Date</th>
                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {others && (
              <tr>
                <th data-cell="event_id">Event identity</th>
                <th data-cell="location">City</th>
                <th data-cell="type">Event type</th>
                <th data-cell="date">Date</th>
              </tr>
            )}
            {admin && (
              <tr>
                <th data-cell="host/planner">Host / Event Planner</th>
                <th data-cell="event_id">Event identity</th>
                <th data-cell="guest_number">Guest number</th>
                <th data-cell="type">Event type</th>
                <th data-cell="location">City</th>
                {/* <th data-cell="time">Time</th> */}
                <th data-cell="date">Date</th>

                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {categories && (
              <tr>
                <th data-cell="category_name">Category name</th>
                <th data-cell="date_updated"> Date Updated</th>
                <th data-cell="date_created"> Date Created</th>
                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {vendor && (
              <tr>
                <th data-cell="vendor_name">Vendor Name</th>
                <th data-cell="cat_name">Category</th>
                <th data-cell="vendor_code">Vendor Code</th>
                <th data-cell="date_updated"> Date Updated</th>
                <th data-cell="date_created"> Date Created</th>
                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {callreason && (
              <tr>
                <th data-cell="callreason_name">Call Reason</th>
                <th data-cell="cat_name">Category</th>
                {/* <th data-cell="date_updated"> Date Updated</th> */}
                <th data-cell="date_created"> Date Created</th>
                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {menu && (
              <tr>
                <th data-cell="menu_name">Menu Name</th>
                <th data-cell="cat_name">Category</th>
                <th data-cell="quantity">Cater Quantity</th>
                <th data-cell="date_created"> Date Created</th>

                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {menuextra && (
              <tr>
                <th data-cell="menu_name">Extra Name</th>
                <th data-cell="cat_name">Menu Name</th>
                <th data-cell="date_created"> Date Created</th>
                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {request && (
              <tr>
                <th data-cell="menu_name">Event identity</th>
                <th data-cell="cat_name">Activation Status</th>
                <th data-cell="date_created"> Date Created</th>
                {showActions && <th data-cell="actions">Actions</th>}
              </tr>
            )}
            {trivia && (
              <tr>
                <th data-cell="trivia_name">Trivia Name</th>
                <th data-cell="trivia_score">Trivia Score</th>
                <th data-cell="seconds">Trivia Score</th>
                {/* <th data-cell="time">Time</th> */}
              </tr>
            )}
            {triviaquestions && (
              <tr>
                <th data-cell="trivia_name">Questions</th>
                <th data-cell="trivia_score">Options</th>
              </tr>
            )}
            {optical && (
              <tr>
                <th data-cell="trivia_name">Optical</th>
                <th data-cell="trivia_score">Image</th>
              </tr>
            )}
          </thead>
          <tbody>{children}</tbody>
        </table>
      </TableContent>
    </Div>
  );
};

const Div = styled.button`
  width: 95%;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #d8dcdf;
`;

const TableHeader = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border: none;
  padding: 20px 20px;
  background-color: white;
  cursor: not-allowed;

  & .wraph1 {
    background-color: rgba(137, 148, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid rgba(255, 255, 255, 1);
    & h1 {
      color: white;
      font-family: "MatterMedium", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-top: 10px;
    border: none;
    padding: 20px 20px;
    background-color: white;
    h1 {
      font-size: 18px;
    }
  }
`;

const SearchInput = styled.div`
  font-family: "MatterNormal", sans-serif;

  input {
    border: 1px solid #808080;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }
  @media (max-width: 640px) {
    input {
      border: 1px solid #808080;
      border-radius: 10px;
      width: 100%;
      padding: 10px;
      font-size: 18px;
    }
  }
`;

const TableContent = styled.button`
  width: 100%;
  font-family: "MatterNormal", sans-serif;
  border: none;
  border-radius: 25px;
  background-color: white;
  padding: 10px 20px;
  cursor: not-allowed;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      padding: 12px 0px;
      font-size: 15px;
    }
    th {
      color: #858585;
      font-size: 15px;
    }

    tr:nth-child(even) {
      border: none;
    }
  }
  tbody tr:hover {
    border-bottom: 1px dotted rgba(255, 92, 0, 1);
  }
  @media (max-width: 1200px) {
    table {
      th,
      td {
        font-size: 18px;
      }
      th {
        color: #858585;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 640px) {
    td {
      display: grid;
      grid-template-columns: 15ch auto;
      padding: 0.5rem 0rem;
    }
    th {
      display: none;
    }
    td::before {
      content: attr(data-cell) " : ";
      font-weight: 500;
      text-transform: capitalize;
      color: #858585;
      font-size: 17px;
    }
    td {
      font-size: 17px;
    }
  }

  @media (max-width: 480px) {
    td {
      display: grid;
      grid-template-columns: 10ch auto;
      padding: 0.5rem 0rem;
      font-size: 17px !important;
    }
    th {
      display: none;
    }
    td::before {
      content: attr(data-cell) " : ";
      font-weight: 500;
      text-transform: capitalize;
      color: #858585;
      font-size: 17px;
    }
  }
`;
