import React from "react";
import styled, { css } from "styled-components";

export const Input = ({
  placeholder,
  label,
  forr,
  type,
  onChange,
  value,
  error,
  name,
  disabled,
  multiple,
}) => {
  const InputComponent = multiple ? "textarea" : "input";

  return (
    <StyledInput>
      <div className="parent">
        <label className="label mb-1" htmlFor={forr}>
          {label}
        </label>
        <WrapInput disabled={disabled}>
          <InputComponent
            type={type}
            name={name}
            className={`form-control${multiple ? " textarea" : ""}`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        </WrapInput>
        {error && <p id="text-danger">{error}</p>}
      </div>
    </StyledInput>
  );
};
export const Input2 = ({
  placeholder,
  label,
  forr,
  type,
  onChange,
  value,
  error,
  name,
  disabled,
  multiple,
}) => {
  const InputComponent = multiple ? "textarea" : "input";

  return (
    <StyledInput2>
      <div className="parent">
        <label className="label mb-1" htmlFor={forr}>
          {label}
        </label>
        <WrapInput disabled={disabled}>
          <InputComponent
            type={type}
            name={name}
            className={`form-control${multiple ? " textarea" : ""}`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        </WrapInput>
        {error && <p id="text-danger">{error}</p>}
      </div>
    </StyledInput2>
  );
};
const WrapInput = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}
`;
const StyledInput = styled.div`
  width: 100%;

  .parent {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  #text-danger {
    color: red;
    font-size: 12px;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #999999;
    outline: -webkit-focus-ring-color auto 0px;
    outline: none;
    border: 1px solid rgba(255, 92, 0, 1);
    background-color: white;
  }
  .form-control {
    border-radius: 8px;
    border: 1px solid #000;
    border-bottom: 1px solid black;
    height: 38px;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
    font-family: "MatterNormal", sans-serif;
    &::placeholder {
      text-align: right;
      color: #353535;
    }
  }
  .textarea {
    height: 120px;
  }
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }

  @media (max-width: 1024px) {
    width: 100%;
    .form-control {
      font-size: 16px;
    }

    .label {
      font-size: 16px;
    }
    .form-control.textarea {
      height: 100px;
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    #text-danger {
    font-size: 16px;
  }
    .form-control {
      font-size: 16px;
    }
    .label {
      font-size: 16px;
    }
    .form-control.textarea {
      height: 80px;
    }
  }
  @media (max-width: 480px) {
    width: auto;
    #text-danger {
    font-size: 16px;
  }
    .parent {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
    }
    .form-control {
      font-size: 17px;
      height: 40px;
    }

    .label {
      font-size: 17px;
    }
    .form-control.textarea {
      height: 80px;
    }
  }
`;
const StyledInput2 = styled.div`
  width: 100%;

  .parent {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  #text-danger {
    color: red;
    font-size: 12px;
    font-family: "MatterNormal", sans-serif;
    margin-top: 5px;
  }
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border: 1px solid rgba(255, 92, 0, 1);
    background-color: white;
  }
  .form-control {
    border-radius: 8px;
    border: 1px solid #000;
    height: 38px;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
    font-family: "MatterNormal", sans-serif;
  }
  .textarea {
    height: 120px;
  }
  .label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "MatterMedium", sans-serif;
  }

  @media (max-width: 1024px) {
    width: 100%;
    .form-control {
      font-size: 16px;
    }

    .label {
      font-size: 16px;
    }
    .form-control.textarea {
      height: 100px;
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    #text-danger {
    font-size: 16px;
   
  }
    .form-control {
      font-size: 16px;
    }
    .label {
      font-size: 16px;
    }
    .form-control.textarea {
      height: 80px;
    }
  }
  @media (max-width: 480px) {
    width: auto;
    #text-danger {
    font-size: 16px;
  }
    .parent {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
    }
    .form-control {
      font-size: 17px;
      height: 40px;
    }

    .label {
      font-size: 17px;
    }
    .form-control.textarea {
      height: 80px;
    }

  }
`;
