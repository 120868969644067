// AdminApprovedEvent.styles.js
import styled from "styled-components";
import { Link } from "react-router-dom";

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;
export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const MainContent = styled.div`
  width: 100%;
  background-color: #f0f4f7;
  height: auto;

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .pagination-bar {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  .more {
    display: inline-block;
    vertical-align: middle;
  }
  .stat {
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  @media (max-width: 1400px) {
    .more {
      margin-top: -8px;
    }
  }
  @media (max-width: 540px) {
    .stat {
      margin-bottom: 20px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .more {
      margin-top: -8px;
    }
  }
  @media (max-width: 480px) {
    .stat {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .more {
      margin-top: -8px;
    }
  }
`;
export const StyledNavLink2 = styled(Link)`
  color: rgba(69, 90, 100, 1);

  text-decoration: none;
  &:hover {
    color: blue;
  }
`;
