import React from "react";
import styled from "styled-components";

export const Button1 = ({ title, onClick, src, alt }) => {
  return (
    <Button onClick={onClick}>
      <div>{title}</div>
      <div>
        <img src={src} alt={alt} />
      </div>
    </Button>
  );
};

const Button = styled.button`
  background-color: rgba(255, 255, 255, 1);
  font-family: "MatterNormal", sans-serif;
  border-radius: 12px;
  border: 1.5px solid rgba(181, 181, 181, 1);
  height: 40px;
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  position: relative;

  & img {
    width: auto;
    height: 1.5rem;
    position: relative;
    top: 1px;
  }
  @media screen and (max-width: 912px) {
    height: 40px;
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    height: 40px;
    font-size: 17px;
    padding: 0px 15px;
    margin-top: 20px;
    width: 220px;

    & img {
      width: auto;
      height: 1.8rem;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-size: 17px;
    padding: 0px 20px;
    width: 240px;
    height: 50px;
    & img {
      width: auto;
      height: 2.2rem;
    }
  }

  @media screen and (max-width: 350px) {
    margin-top: 20px;
    font-size: 17px;
    padding: 0px 7px;
    width: 200px;
    height: 40px;
    & img {
      width: auto;
      height: 2.2rem;
    }
  }
`;
