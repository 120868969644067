import * as yup from "yup";

export const guestloginvalidationSchema = yup.object().shape({
  event_code: yup.string().required("event code is required"),
});

export const callwaitervalidationSchema = yup.object().shape({
  seatNo: yup.string().required("seat number/name is required"),
  tableNo: yup.string().required("table number is required"),
});

export const cartvalidationSchema = yup.object().shape({
  seat: yup.string().required("seat number/name is required"),
  table: yup.string().required("table number is required"),
});
