import React, { useState, useRef } from "react";
import styled from "styled-components";
import { BigText } from "../components/BigText";
import { WhyCard } from "../components/WhyCard";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";

export const WhyWiserve = () => {
  const [showAllCards, setShowAllCards] = useState(false);
  const scrollToRef = useRef(null);

  const toggleShowAllCards = () => {
    setShowAllCards((prev) => !prev);

    // Scroll to the top of the container when cards are hidden
    if (showAllCards) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <StyledWhyWiserve id="why_wiserve" className="container" ref={scrollToRef}>
      <div className="d-flex justify-content-center">
        <BigText title="Why Wiserve?" />
      </div>
      <div className="big-parent">
        <div className="parent">
          <div className="card1">
            <WhyCard
              withPadding
              imageUrl="/images/reduced_loitering.svg"
              numberImageUrl="/images/01.svg"
              part1="Simple to use."
              description="With Wiserve’s simple and easy-to-use interface, you can 
        add a customised food menu for your event. All your 
        guests need to do is scan a barcode at the event, enabling 
        them to order their food via a simple yet dynamic interface 
        that functions seamlessly."
            />
          </div>
          <div className="card2">
            <WhyCard
              withPadding
              imageUrl="/images/food_comfort.svg"
              numberImageUrl="/images/02.svg"
              part1="Food at your"
              part2="Guest's Comfort"
              description="Wiserve provides an online interactive food menu catalogue that allows your guests to view the menu over their mobile devices which makes it easy to quickly order their food from the comfort of their seats."
            />
          </div>
        </div>

        {showAllCards && (
          <>
            <div className="parent">
              <div className="card3">
                <WhyCard
                  imageUrl="/images/all_event.svg"
                  numberImageUrl="/images/03.svg"
                  part1="Made for all Events."
                  description="Wiserve can be integrated to any and all kinds of events 
            ranging from cocktail parties, wedding receptions, business 
            meetings, conferences, seminars and many many more. 
            Wiserve ensures food security as only invited guest are 
            allowed to order food at the event via scanning the event's 
            barcode."
                />
              </div>
              <div className="card4">
                <WhyCard
                  imageUrl="/images/reduced_time.svg"
                  numberImageUrl="/images/04.svg"
                  part1="Reduced Food"
                  part2="Wait time."
                  description="Under Wiserve coordination, the food wait time is greatly 
reduced as waiters are concentrated in serving more 
effectively."
                />
              </div>
            </div>

            <div className="parent">
              <div className="card5">
                <WhyCard
                  withPadding
                  imageUrl="/images/personalised_food.svg"
                  numberImageUrl="/images/05.svg"
                  part1="Personalised"
                  part2="Food Menu"
                  description="As a host or event planner, you can customise your food 
        menu to feature a wide variety of cuisines and flavours that 
        will be available at the event. Our menus are set up in the 
        best interest of our clients and vendors to guarantee that 
        their guests are getting what they desire at their 
        convenience."
                />
              </div>
              <div className="card6">
                <WhyCard
                  withPadding
                  imageUrl="/images/reduced_loitering.svg"
                  numberImageUrl="/images/06.svg"
                  part1="Reduced"
                  part2="Loitering"
                  description="This reduces movement times for our waiters while the 
        dining experience remains unique, memorable and 
        innovative. Event planners can now focus on ensuring 
        maximum satisfaction of their clients by reducing the 
        movement of waiters during event time."
                />
              </div>
            </div>
          </>
        )}
      </div>

      {!showAllCards && (
        <div onClick={toggleShowAllCards}>
          <img src="/images/drop.svg" className="drop" />
        </div>
      )}

      <div onClick={toggleShowAllCards} className="dropmobile">
        {showAllCards ? (
          <IoIosArrowDropup size="35" />
        ) : (
          <IoIosArrowDropdown size="35" />
        )}
      </div>
    </StyledWhyWiserve>
  );
};

const StyledWhyWiserve = styled.div`
  padding: 0px 10px;
  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }

  .drop {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .dropmobile {
    display: none;
  }
  .card4 {
    margin-right: 50px;
  }
  .card3 {
    margin-left: 50px;
  }
  .card2,
  .card4,
  .card6 {
    margin-top: 70px;
  }

  @media (max-width: 1024px) {
    .drop {
      display: none;
    }
    .dropmobile {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }
    .card4 {
      margin-right: 0px;
    }
    .card3,
    .card1,
    .card5 {
      margin-left: 0px;
    }
    .card2,
    .card4,
    .card6 {
      margin-top: 40px;
    }
  }

  @media (max-width: 640px) {
    .parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }
    .card4 {
      margin-right: 0px;
    }
    .card3 {
      margin-left: 0px;
    }
    .card2,
    .card4,
    .card6 {
      margin-top: 40px;
    }
  }

  @media (max-width: 480px) {
    margin-top: 10%;
    padding: 40px 10%;

    .parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }
  }

  @media (max-width: 350px) {
    margin-top: 50px;
    padding: 40px 10%;

    .parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }
  }
`;
