import React from "react";
import styled from "styled-components";

const ViewMore = ({ onClick }) => {
  return (
    <CardContainer>
      <div className="div2" onClick={onClick}>
        <div className="button">
          <img src="/images/arrow.png" className="add" alt="arrow-right" />
        </div>
        <div>
          <p className="foodname mb-2">View More</p>
        </div>
      </div>
    </CardContainer>
  );
};

export { ViewMore };

const CardContainer = styled.div`
  border-radius: 10px;
  .div2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    width: 160px !important;
    height: 140px !important;
    width: 150px!important;
    height: 160px!important;
  }
  .button {
    position: absolute;
    top: 50%;
  }
  .foodname {
    font-family: "MatterNormal", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 812px) {
    .div2 {
      width: 230px;
      height: 240px;
    }

    .foodname {
      font-size: 20px;
    }
    .add {
      width: auto;
      height: 2.5rem;
    }
  }



  @media screen and (max-width: 640px) {

    .foodname {
      font-size: 17px;
    }
    .add {
      width: auto;
      height: 1.5rem;
    }
  }
`;
