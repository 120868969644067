import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Card } from "../../components/Card";
import { FoodMenuHeader } from "../../components/MenuHeader";
import { useQuery } from "react-query";
import { URL } from "../../../../utils/url";
import { ViewMore } from "../../components/ViewMore";
import axios from "axios";
import { ContextStore } from "../../../../context/contextStore";
import { LoadingSpinner } from "../../../../utils/loader";
import { NotiSuccess, NotiError } from "../../components/Notification";

export default function Menu() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [eachCat, setEachCat] = useState([]);
  const [layout, setLayout] = useState("all");
  const [selectedItemName, setSelectedItemName] = useState("");
  const [addnewtocart, setAddNewToCart] = useState(false);
  const [rejectincart, setRejectInCart] = useState(false);
  const { AddedItem, setAddedItem } = useContext(ContextStore);
  const [menudetails, setMenuDetails] = useState([]);
  const { cartInfo, setCartInfo } = useContext(ContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useParams();
  const [isDarkMode, setIsDarkMode] = useState(false);

  const eventId = localStorage.getItem("eventId");
  const handleModeChange = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem("darkMode", JSON.stringify(!isDarkMode));
  };

  useEffect(() => {
    localStorage.setItem("eventId", eventId);
  }, [eventId]);

  useEffect(() => {
    const storedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode);
    }
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const { refetch } = useQuery(
    ["fetchmenu", token],
    () => {
      return axios.get(`${URL}category/${token}/`);
    },

    {
      onSuccess: (menudata) => {
        setMenuDetails(menudata.data.data);
        setIsLoading(false);
      },
    }
  );
  const setBack = () => {
    setTimeout(() => {
      setAddNewToCart(false);
      setRejectInCart(false);
    }, 2000);
  };

  const topNavigation = menudetails?.map((item) => {
    return {
      category: item.cat_name,
      id: item.id,
    };
  });

  const displayEachCatMenu = (id) => {
    setLayout(id);
    let m = menudetails.find((cat) => {
      return cat.id === id;
    });
    let newMenu = m.menu?.map((item) => item);
    setEachCat(newMenu);
  };

  const handleAddToCart = (id) => {
    let item = cartInfo.find((item) => item.id === id);

    if (item) {
      setSelectedItemName(item.menu_name);
      setRejectInCart(true);
      setBack();
      return;
    } else {
      menudetails.map((cat) => {
        let newItem = cat.menu.find((item) => {
          return item.id === id;
        });
        const otherItems = { ...newItem, note: "", extras: [] };

        if (newItem && newItem.menu_extras.length > 0) {
          setAddedItem(newItem);
          navigate(`/${token}/food-cart`);
        }

        if (newItem) {
          setSelectedItemName(newItem.menu_name);
          setAddNewToCart(true);
          let myGuy = [...cartInfo, otherItems];
          setCartInfo(myGuy);
          setBack();
        }
      });
    }
  };

  return (
    <Parent isDarkMode={isDarkMode}>
      <div className="parent">
        {isLoading ? (
          <Loading isDarkMode={isDarkMode}>
            <LoadingSpinner />
          </Loading>
        ) : (
          <>
            {modalVisible && (
              <Modal>
                <ModalContent>
                  <h1 className="mh">{modalData?.menu_name}</h1>
                  <p className="mp">{modalData?.description}</p>
                  <div className="x" onClick={closeModal}>
                    <img src="/images/xx.png" className="imgg" />
                  </div>
                </ModalContent>
              </Modal>
            )}
            <FoodMenuHeader
              tag={localStorage.getItem("eventTag")}
              date={localStorage.getItem("eventYear")}
              handleModeChange={handleModeChange}
              isDarkMode={isDarkMode}
            />
            <Wrapper2>
              <TopNav>
                {layout === "all" ? (
                  <>
                    <div onClick={() => setLayout("all")} className="activee">
                      <h1>All</h1>
                    </div>
                  </>
                ) : (
                  <div className="none-active" onClick={() => setLayout("all")}>
                    <h1>All</h1>
                  </div>
                )}

                {topNavigation?.map(({ id, category }) => (
                  <div key={category}>
                    <h1
                      onClick={() => displayEachCatMenu(id)}
                      className={layout === id ? "active" : ""}
                    >
                      {category}
                    </h1>
                  </div>
                ))}
              </TopNav>
              <div className="cont mb-2">
                {addnewtocart && (
                  <NotiSuccess
                    title={`${selectedItemName} added to food cart`}
                  />
                )}

                {rejectincart && (
                  <NotiError
                    title={`${selectedItemName} already added to food cart`}
                  />
                )}
              </div>
            </Wrapper2>
            {layout === "all" ? (
              <Wrapper>
                <div style={{ marginBottom: "80px", overflow: "hidden" }}>
                  {menudetails?.map((category) => (
                     <div key={category.id}>
                      {category.menu.length > 2 && (
                        <div onClick={() => displayEachCatMenu(category.id)}>
                          <p className="seemore">See More</p>
                        </div>
                      )}
                      <H1 isDarkMode={isDarkMode}>{category.cat_name}</H1>
                      <div className="mt-3 mb-4 flex">
                        {category?.menu.slice(0, 4).map((item, i) =>
                          item.cater_qty_availble > 1 ? (
                            <>
                              <div key={i}>
                                <Card
                                  name={item.menu_name}
                                  img={item.avatar}
                                  onClick={() => {
                                    setModalVisible(true);
                                    setModalData(item);
                                  }}
                                  addToCart={() => handleAddToCart(item.id)}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div key={i}>
                                <Card
                                  name={item.menu_name}
                                  img={item.avatar}
                                  finished="Finished"
                                  //  opacity="0.8"
                                />
                              </div>
                            </>
                          )
                        )}

                        {category.menu.length > 4 && (
                          <ViewMore
                            onClick={() => displayEachCatMenu(category.id)}
                          />
                        )}
                      </div>
                    </div>

                  ))}
                </div>
              </Wrapper>
            ) : (
              <Wrapper3 isDarkMode={isDarkMode}>
                <div className="fle">
                  {eachCat?.map((category, i) =>
                    category.cater_qty_availble > 0 ? (
                      <>
                        <div className="mt-3">
                          <Card
                            name={category.menu_name}
                            img={category.avatar}
                            onClick={() => {
                              setModalVisible(true);
                              setModalData(category);
                            }}
                            addToCart={() => handleAddToCart(category.id)}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-3">
                          <Card
                            name={category.menu_name}
                            img={category.avatar}
                            finished="Finished"
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
                <div onClick={scrollToTop()}></div>
              </Wrapper3>
            )}
          </>
        )}
      </div>
    </Parent>
  );
}

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ModalContent = styled.div`
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;

  @media screen and (max-width: 812px) {
    width: 350px;

    .x {
      position: absolute;
      right: 20px;
      bottom: 15px;
    }

    .imgg {
      width: auto;
      height: 30px;
    }
    .mh {
      color: #1d1d1d;
      font-family: "MatterMedium", sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
    .mp {
      color: #383838;
      font-family: "MatterNormal", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      text-transform: lowercase;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 350px;
    padding: 10px 20px;
    margin: 0 auto;
    .mh {
      font-size: 25px;
    }
    .mp {
      font-size: 18px;
    }
    .imgg {
      width: auto;
      height: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    width: 250px;
    padding: 25px 20px;
    .mh {
      font-size: 25px;
    }
    .mp {
      font-size: 17px;
    }
  }
`;

const H1 = styled.h1`
  @media screen and (max-width: 812px) {
    /* color: #1d1d1d; */
    color: ${(props) => (props.isDarkMode ? "#ffffff" : "#1d1d1d")};
    font-family: "MatterMedium", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  @media screen and (max-width: 640px) {
    font-size: 17px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  padding: 0px 5px 0px 20px;
  /* overflow: auto; */
  margin-bottom: 10px;
  .flex {
    display: flex;
    color: #000;
    gap: 2rem;
    overflow-x: auto;
  }

  .flex::-webkit-scrollbar {
    display: none;
  }

  .seemore {
    font-family: "MatterNormal", sans-serif;
    text-decoration-line: underline;
    color: #bababa;
    font-weight: 400;
    line-height: normal;
  }
  @media screen and (max-width: 812px) {
    .seemore {
      font-size: 25px;
      position: relative;
      right: -525px;
      top: 32px;
    }
  }
  @media screen and (max-width: 640px) {
    .seemore {
      font-size: 18px;
      position: relative;
      right: -378px;
      top: 22px;
    }
  }
  @media screen and (max-width: 480px) {
    .seemore {
      font-size: 16px;
      position: relative;
      right: -220px;
      top: 34px;
    }
  }
`;

const Wrapper2 = styled.div`
  padding: 0px 0px 0px 20px;
  margin-top: 15px;
  position: relative;

  /* height: 80vh; */
  .cont {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    right: 0;
    /* bottom:15px; */
    z-index: 2;
  }
`;

const Wrapper3 = styled.div`
  height: 80vh;
  padding: 0px 0px 0px 20px;
  margin-top: 15px;
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};
  overflow-y: scroll;
  .fle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 120px;
  }
`;
const Parent = styled.div`
  width: 100%;
  /* height: auto; */
  overflow-y: auto;
  opacity: 0;
  animation: fadeIn 5s forwards; 
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};

  @media screen and (max-width: 640px) {
    overflow: hidden;
  }
`;

const TopNav = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 6px 10px;
  overflow-x: scroll;
  margin-bottom: 15px;
  margin-top: 25px;

  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 20px;
  @media screen and (max-width: 812px) {
    .activee {
      font-size: 25px;
      font-family: "MatterMedium", sans-serif;
      text-transform: uppercase;
      color: white;
      background-color: #ff5c00;
      border-radius: 20px;
      padding: 7px 18px;
      box-shadow: 0px 4px 8px 0px rgba(255, 92, 0, 0.25);
    }
    h1 {
      font-size: 25px;
      font-family: "MatterMedium", sans-serif;
      text-transform: uppercase;
      /* margin:0 auto; */
      &.active {
        font-family: "MatterMedium", sans-serif;

        color: white;
        background-color: #ff5c00;
        border-radius: 20px;
        padding: 7px 18px;
        box-shadow: 0px 4px 8px 0px rgba(255, 92, 0, 0.25);
      }
    }
  }

  @media screen and (max-width: 640px) {
   
    h1 {
      font-size: 17px;
    }
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 18px;
    }
  }
`;
