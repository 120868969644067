import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import request, { headers } from "../../../request";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { URL } from "../../../url";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const useAdminOptical = () => {
  const [loading, setLoading] = useState(false);
  const [opticalid, setOpticalid] = useState(null);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState("");

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isModalEditTriviaOpticalOpen, setModalEditTriviaOpticalOpen] =
    useState(false);
  const [isModalCreateTriviaOpticalOpen, setModalCreateTriviaOpticalOpen] =
    useState(false);
  const [isModalDeleteTriviaOpticalOpen, setModalDeleteTriviaOpticalOpen] =
    useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (id && opticalid) {
      axios
        .get(
          `${URL}trivia-optical-rud/${id}/${opticalid}/`,
          headers(localStorage.getItem("token"))
        )
        .then((response) => {
          setInitialValues(response.data);
          formikUpdateTriviaOptical.setValues(response.data);
          setBackgroundImage(response.data.optical_image);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to load trivia optical details");
        });
    }
  }, [id, opticalid]);

  const initialValueTriviaOptical = {
    optical_title: initialValues?.optical_title || "",
  };

  const multipartConfigs = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onSubmitUpdateTriviaOptical = async (values) => {
    // if (!selectedFile) {
    //   setError("Please select an image file");
    //   return;
    // }
    const payload = {
      optical_title: values.optical_title,
      event: id,
      optical_image: selectedFile,
    };
    setLoading(true);
    try {
      await axios.patch(
        `${URL}trivia-optical-rud/${id}/${opticalid}/`,
        payload,
        multipartConfigs
      );

      toast.success("trivia optical updated successfully!");
      refetch();
      setLoading(false);
      setModalEditTriviaOpticalOpen(false);
    } catch (error) {
      toast.error("trivia optical update failed");
      console.log(error);
      setLoading(false);
    }
  };

  const formikUpdateTriviaOptical = useFormik({
    initialValues: initialValueTriviaOptical,
    onSubmit: onSubmitUpdateTriviaOptical,
  });

  const fetchTriviaOptical = () => {
    let url = `/trivia-optical/${id}/`;
    return request.get(url, headers(localStorage.getItem("token")));
  };

  const {
    data: triviaopticaldata,
    isError: triviaopticalerror,
    isLoading: triviaopticalloading,
    refetch,
  } = useQuery("trivia-optical", fetchTriviaOptical);

  const removeFromTriviaOptical = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `${URL}trivia-optical-rud/${id}/${opticalid}/`,
        headers(localStorage.getItem("token"))
      );
      toast.success("Trivia optical deleted successfully");
      refetch();
    } catch (error) {
      toast.error("Trivia optical deletion failed");
    } finally {
      setLoading(false);
      setModalDeleteTriviaOpticalOpen(false);
    }
  };

  const openModalEditTriviaOptical = () => {
    setModalEditTriviaOpticalOpen(true);
  };

  const openModalDeleteTriviaOptical = () => {
    setModalDeleteTriviaOpticalOpen(true);
  };

  const openModalCreateTriviaOptical = () => {
    setModalCreateTriviaOpticalOpen(true);
  };

  const closeModalEditTriviaOptical = () => {
    setModalEditTriviaOpticalOpen(false);
  };

  const closeModalDeleteTriviaOptical = () => {
    setModalDeleteTriviaOpticalOpen(false);
  };

  const closeModalCreateTriviaOptical = () => {
    setModalCreateTriviaOpticalOpen(false);
  };

  const handleDropdownToggle = (index, eventId) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedEventId(eventId);
  };

  const onSubmitCreateTriviaOptical = async (values, { resetForm }) => {
    if (!selectedFile) {
      setError("please select an image file");
      return;
    }

    const data = new FormData();
    data.append("optical_title", values.optical_title);
    data.append("optical_image", selectedFile);
    data.append("event", id);

    setLoading(true);
    try {
      await axios.post(
        `${URL}trivia-optical-create/${id}/`,
        data,
        multipartConfigs
      );
      setLoading(false);
      toast.success("trivia optical created successfully");
      setSelectedFile(null);
      resetForm();
      refetch();
    } catch (error) {
      toast.error("trivia optical creation failed");
      setLoading(false);
    } finally {
      setModalCreateTriviaOpticalOpen(false);
    }
  };

  const formikTriviaOpticalCreate = useFormik({
    initialValues: {
      optical_title: "",
    },
    validationSchema: Yup.object({
      optical_title: Yup.string().required("Optical title is required"),
    }),
    onSubmit: onSubmitCreateTriviaOptical,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setError(null);
  };

  return {
    loading,
    opticalid,
    error,
    selectedFile,
    backgroundImage,
    openDropdownIndex,
    setOpticalid,
    setOpenDropdownIndex,
    isModalEditTriviaOpticalOpen,
    isModalCreateTriviaOpticalOpen,
    isModalDeleteTriviaOpticalOpen,
    selectedEventId,
    initialValues,
    triviaopticaldata,
    triviaopticalerror,
    triviaopticalloading,
    formikUpdateTriviaOptical,
    formikTriviaOpticalCreate,
    openModalEditTriviaOptical,
    openModalDeleteTriviaOptical,
    openModalCreateTriviaOptical,
    closeModalEditTriviaOptical,
    closeModalDeleteTriviaOptical,
    closeModalCreateTriviaOptical,
    handleDropdownToggle,
    handleFileChange,
    handleFileSelect,
    removeFromTriviaOptical,
  };
};

export default useAdminOptical;
