import React, { useState, useEffect } from "react";
import { Head } from "../../components/Header";
import styled from "styled-components";
import Input from "../../components/Input";
import Drop from "../../components/Dropdown";
import Radio from "../../components/Radio";
import { WaiterHeader } from "../../components/Text/WaiterHeader";
import { URL } from "../../../../utils/url";
import { useFormik } from "formik";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { REASONS_OPTIONS } from "../../../../utils/data";
import { callwaiterinitialValues } from "../../../../utils/initialvalues";
import { callwaitervalidationSchema } from "../../../../utils/validation";
import { PrimaryButton2 } from "../../components/Button2";
import { Error } from "../../components/Text/ErrorText";


export default function CallWaiter() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [label, setlabel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [current, setCurrent] = useState("");
  const [reasond, setReasonD] = useState([]);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [categoryreject, setCategoryReject] = useState(false);
  const [reasonreject, setReasonRejected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [eventid, setEventId] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const { token } = useParams();

  const navigate = useNavigate();

  const qr_id = token;

  useEffect(() => {
    onGetCategory();
    onGetWaiterReason();
    const storedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode);
    }
  }, [selectedCategory]);

  const goBack = () => {
    navigate(`/${token}/food-menu`);
  };

  const timeBeforeRedirectCallNotify = () => {
    setTimeout(() => {
      navigate(`/${token}/call-confirmation`);

      timeforselectCategory();
      setSelectedCategory("");
    }, 2000);
  };

  const timeforselectCategory = () => {
    setTimeout(() => {
      setlabel("Choose One");
      setReasonD([]);
      setCurrent("");
    }, 1000);
  };

  const setBack = () => {
    setTimeout(() => {
      setAccepted(false);
      setCategoryReject(false);
      setReasonRejected(false);
    }, 2000); // Delay in milliseconds before resetting the state
  };

  const onGetCategory = async () => {
    try {
      const response = await axios.get(`${URL}category/${token}/`);
      const eventidValue = response.data.data[0].eventid;
      setEventId(eventidValue);
      const st = response.data.data.map((items) => ({
        label: items.cat_name,
        value: items.id,
      }));

      setCategory(st);
    } catch (error) {
      // console.log(error.response,"test")
    }
  };

  const onGetWaiterReason = async () => {
    try {
      const response = await axios.get(
        `${URL}call-waiter-reason/${eventid}/${selectedCategory}/`
      );
      const reasonOptions = response.data.data.map((items) => ({
        label: items.reason,
        value: items.reason,
      }));

      // If no reasons from backend, use default reasons
      if (reasonOptions.length === 0) {
        setReasonD(REASONS_OPTIONS);
      } else {
        setReasonD([...REASONS_OPTIONS, ...reasonOptions]);
      }
    } catch (error) {
      // Handle error
      // console.log(error);
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    if (!selectedCategory) {
      setCategoryReject(true);
      setLoading(false);
      setBack();

      return;
    }

    if (!selectedOption) {
      setReasonRejected(true);
      setLoading(false);
      setBack();
      return;
    }
    values.category = selectedCategory;
    values.qr_id = qr_id;
    values.reason = selectedOption;

    setLoading(true);
    try {
      const response = await axios.post(`${URL}callwaiterpost/`, values);
      resetForm();
      setLoading(false);
      setAccepted(true);
      setBack();
      timeBeforeRedirectCallNotify();
      timeforselectCategory();
    } catch (error) {
      setRejected(true);
      setLoading(false);
      setBack();
    }
  };

  const formik = useFormik({
    initialValues: callwaiterinitialValues,
    onSubmit,
    validationSchema: callwaitervalidationSchema,
  });

  return (
    <Parent isDarkMode={isDarkMode}>
      <div className="parent">
        <Head title="Call Waiter" isDarkMode={isDarkMode} onClick={goBack} />
        <CallWaiterContainer className="mt-3">
          <form onSubmit={formik.handleSubmit}>
            <Input
              placeholder="Enter Table Number"
              type="number"
              name="tableNo"
              onChange={formik.handleChange}
              value={formik.values.tableNo}
              error={formik.errors.tableNo}
            />
            <Input
              placeholder="Enter Seat Number or your Name"
              type="text"
              name="seatNo"
              onChange={formik.handleChange}
              value={formik.values.seatNo}
              error={formik.errors.seatNo}
            />
            <div className="mt-5">
              {categoryreject && (
                <div className="mb-2">
                  <Error title="Oops, seems you forgot to select a category." />
                </div>
              )}
              <WaiterHeader title="Select Category" />
              <Drop
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                category={category}
                isActive={isActive}
                setIsActive={setIsActive}
                label={label}
                setlabel={setlabel}
              />
            </div>

            {selectedCategory ? (
              <>
                <div className="mt-5">
                  {reasonreject && (
                    <div className="mb-2">
                      <Error title="Oops, seems you forgot to select a reason." />
                    </div>
                  )}
                  <WaiterHeader title="Select Reason for Call" />
                  <Radio
                    options={reasond}
                    selectedOption={selectedOption}
                    onOptionChange={handleOptionChange}
                  />
                </div>
              </>
            ) : null}

            <div className="button">
              <>
                {!accepted && !rejected && !categoryreject && !reasonreject && (
                  <PrimaryButton2
                    title={loading ? "loading..." : "Place Call"}
                    onClick={formik.handleSubmit}
                    backgroundColor="#FF5C00"
                  />
                )}
              </>

              {accepted && (
                <PrimaryButton2 title="Placed" backgroundColor="#098449" />
              )}
              {rejected && (
                <PrimaryButton2 title="Try again" backgroundColor="#969696" />
              )}
              {categoryreject && (
                <PrimaryButton2 title="No Category" backgroundColor="#969696" />
              )}
              {reasonreject && (
                <PrimaryButton2 title="No Reason" backgroundColor="#969696" />
              )}
            </div>
          </form>
        </CallWaiterContainer>
      </div>
    </Parent>
  );
}

const CallWaiterContainer = styled.div`
  /* Add this to enable scrolling within CartBox */
  height: 80vh;
  overflow-y: scroll;
  max-height: 1200px;
  margin-bottom: 70px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Parent = styled.div`
  height: 100vh;
  /* margin-top: 30px; */
  padding: 0px 30px;
  background-color: ${(props) => (props.isDarkMode ? "#000000" : "#f3f2f2")};
  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
 


`;
