import React, { useState } from "react";
import styled from "styled-components";
import { ModalTitle } from "../ModalBox/ModalTitle";
const ModalHeader = ({
  onClose,
  rightIconSrc,
  title,
  backgroundColor,
  borderColor,
  textColor,
  imageSource,
  showlogo,
}) => {
  return (
    <ModalHeaderWrapper>
      <div>
        {showlogo ? (
          <img src="/images/favicon.png" alt="logo" className="logo" />
        ) : (
          <ModalTitle
            title={title}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            textColor={textColor}
            imageSource={imageSource}
          />
        )}
      </div>
      <div onClick={onClose}>
        <img src={rightIconSrc} alt="modal_close" className="close" />
      </div>
    </ModalHeaderWrapper>
  );
};

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .logo {
    width: auto;
    height: 2.5rem;
  }
  & .close {
    cursor: pointer;
  }
`;

export default ModalHeader;
