import React from "react";
import styled from "styled-components";
import { DashHeader2 } from "../../components/dashboardmain/Header";
import { DetailsBox } from "../../components/dashboardmain/DetailsBox";

export const HostEventDetails = () => {
  return (
    <Wrapper>
      <MainContent>
        <CenteredContent>
          <DashHeader2
            host
            title="Event Details"
            towhere="/dashboard-host-overview"
          />
        </CenteredContent>
        <CenteredContent>
          <DetailsBox userType="host" />
        </CenteredContent>
      </MainContent>
    </Wrapper>
  );
};

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`;

const MainContent = styled.div`
  width: 100%;
  background-color: #f0f4f7;
  height: 100%;
  overflow-y: auto;
`;
