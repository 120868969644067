import React, { useState } from "react";
import { TableData } from "../../components/TableData";
import Pagination from "../../../../utils/pagination";
import { DashHeader2 } from "../../components/dashboardmain/Header";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import useHostOngoingEvent from "../../../../utils/apilogics/host/ongoingLogic";
import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  StyledNavLink2,
} from "../../../../utils/styles/host/ongoingstyles";
import StatusHandler from "../../../../utils/statusHandler";

export default function HostOnGoingEvent() {
  const {
    searchTerm,
    pageNumber,
    setPageNumber,
    ongoingloading,
    ongoingeventdata,
    ongoingerror,
    handleSearch,
  } = useHostOngoingEvent();

  return (
    <Wrapper>
      <MainContent>
        <CenteredContent>
          <DashHeader2
            title="Ongoing Event"
            towhere="/dashboard-host-overview"
          />
        </CenteredContent>
        <Table>
          <TableData
            headtitle="All Ongoing Event"
            onChange={handleSearch}
            placeholder="Search ongoing"
            {...(!ongoingloading && {
              others:
                ongoingeventdata && ongoingeventdata?.data?.results.length === 0
                  ? ""
                  : ongoingeventdata,
            })}
          >
            <StatusHandler isError={ongoingerror} isLoading={ongoingloading}>
              {ongoingeventdata &&
              ongoingeventdata?.data?.results.length === 0 ? (
                <ModalEmpty title="no ongoing event found" />
              ) : (
                ongoingeventdata?.data?.results.map((e, index) => (
                  <tr key={index}>
                    <td data-cell="event id">
                      <StyledNavLink2
                        to={`/dashboard-host-event/${e.event_uuid}/details`}
                      >
                        {e.event_uuid.substring(0, 5)}
                      </StyledNavLink2>
                    </td>
                    <td data-cell="location">{e.city}</td>
                    <td data-cell="event type">{e.event_type}</td>
                    <td data-cell="date">{e.event_date}</td>
                  </tr>
                ))
              )}
            </StatusHandler>
          </TableData>
        </Table>
        <div className="pagination-container">
          {ongoingeventdata && ongoingeventdata?.data.results?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={ongoingeventdata?.data.count}
              pageSize={5}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </div>
      </MainContent>
    </Wrapper>
  );
}
