import React from "react";
import styled from "styled-components";
import ModalHeader from "../dashboardmain/ModalHeader";

const Modal = ({
  onClose,
  children,
  rightIconSrc,
  title,
  backgroundColor,
  borderColor,
  textColor,
  imageSource,
  showlogo,
}) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalHeader
          rightIconSrc={rightIconSrc}
          title={title}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          textColor={textColor}
          imageSource={imageSource}
          onClose={onClose}
          showlogo={showlogo}
        />
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  background: white;
  padding: 15px 25px;
  width: 440px;
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: auto;

  /* WebKit Browsers */
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144, 155, 182, 1);
    border-radius: 0px;
  }

  /* Firefox */
  scrollbar-width:thin;
  scrollbar-color: rgba(144, 155, 182, 1) transparent;

  /* Edge and IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media (max-width: 640px) {
    width: 70%;
    padding: 15px 15px;
  }
  @media (max-width: 480px) {
    width: 90%;
    padding: 15px 15px;
  }
  @media (max-width: 320px) {
    width: 97%;
    padding: 15px 10px;
  }
`;

export default Modal;
