import React from "react";
import { TableData } from "../../components/TableData";
import { Button2 } from "../../components/button/Button2";
import { ModalEmpty } from "../../components/ModalBox/ModalEmpty";
import { Input } from "../../components/input/Input";
import { DashHeader2 } from "../../components/header/DashHeader2";
import Modal from "../../components/ModalBox/Modal";
import { ModalNote } from "../../components/ModalBox/ModalNote";
import { RadioInput } from "../../components/input/RadioInput";
import StatusHandler from "../../../../utils/statusHandler";

import {
  Wrapper,
  MainContent,
  CenteredContent,
  Table,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  StyledNavLink,
  StyledLink,
  DropdownWrapper,
  OptionContainer,
} from "../../../../utils/styles/admin/overview/triviastyles";

import useAdminTrivia from "../../../../utils/apilogics/admin/overview/triviaLogic";

export const AdminTrivia = () => {
  const {
    loading,
    questionsid,
    correctAnswer,
    openDropdownIndex,
    isModalEditTriviaQuestionsOpen,
    isModalCreateTriviaQuestionsOpen,
    isModalDeleteTriviaQuestionsOpen,
    triviaquestionsdata,
    triviaquestionsloading,
    triviaquestionserror,
    formikUpdateTriviaQuestions,
    formikTriviaQuestionsCreate,
    openModalEditTriviaQuestions,
    openModalDeleteTriviaQuestions,
    openModalCreateTriviaQuestions,
    closeModalEditTriviaQuestions,
    closeModalDeleteTriviaQuestions,
    closeModalCreateTriviaQuestions,
    handleRadioChange,
    handleDropdownToggle,
    removeFromTriviaQuestion,
    setQuestionsid,
    setOpenDropdownIndex,
    id
  } = useAdminTrivia();

  const questionsLength = triviaquestionsdata?.data?.length || 0;
  return (
    <Wrapper>
      {isModalEditTriviaQuestionsOpen && (
        <form onSubmit={formikUpdateTriviaQuestions.handleSubmit}>
          <Modal
            title="Update Trivia Question"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalEditTriviaQuestions}
          >
            <div className="wrapper-div">
              <Input
                name="question"
                type="text"
                label="Questions"
                onChange={formikUpdateTriviaQuestions.handleChange}
                value={formikUpdateTriviaQuestions.values.question}
                error={formikUpdateTriviaQuestions.errors.question}
                placeholder="Enter trivia question"
              />
            </div>
            {formikUpdateTriviaQuestions.values.options.map(
              (optionText, index) => (
                <OptionContainer key={index}>
                  <RadioInput
                    placeholder="Enter trivia option"
                    name={`options.${index}`}
                    type="text"
                    onChange={formikUpdateTriviaQuestions.handleChange}
                    value={optionText}
                    error={formikUpdateTriviaQuestions.errors.options}
                    onClickRemove={() =>
                      formikUpdateTriviaQuestions.setFieldValue(
                        "options",
                        formikUpdateTriviaQuestions.values.options.filter(
                          (_, i) => i !== index
                        )
                      )
                    }
                    radioName="correctAnswer"
                    radioChecked={correctAnswer === index}
                    radioOnChange={() => handleRadioChange(index)}
                  />
                </OptionContainer>
              )
            )}
            <div className="align">
              <Button2
                title="Add Options"
                src="/images/addd.svg"
                backgroundColor="rgba(0, 0, 0, 1)"
                onClick={() =>
                  formikUpdateTriviaQuestions.setFieldValue(
                    "options",
                    formikUpdateTriviaQuestions.values.options.concat("")
                  )
                }
                alt="add"
              />
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
              />
            </div>
          </Modal>
        </form>
      )}
      {isModalDeleteTriviaQuestionsOpen && (
        <Modal
          title="Delete Trivia Question"
          imageSource="/images/mdt6.png"
          rightIconSrc="/images/mdx.svg"
          borderColor="0.5px solid white"
          backgroundColor="#FF0000"
          textColor="white"
          onClose={closeModalDeleteTriviaQuestions}
        >
          <div className="wrapper-div">
            <ModalNote note="Are you absolutely certain about proceeding with this decision? Once made, there is no turning back. If yes, please click on the button below" />
          </div>
          <div className="align">
            <Button2
              title="Delete"
              src="/images/mdt6.png"
              backgroundColor={loading ? "rgba(255, 92, 0, 0.1)" : "#FF0000"}
              alt="update"
              onClick={() => removeFromTriviaQuestion(questionsid)} // Pass questionId directly
            />
          </div>
        </Modal>
      )}
      {isModalCreateTriviaQuestionsOpen && (
        <form onSubmit={formikTriviaQuestionsCreate.handleSubmit}>
          <Modal
            title="Create Trivia Question"
            imageSource="/images/mdt1.png"
            rightIconSrc="/images/mdx.svg"
            onClose={closeModalCreateTriviaQuestions}
          >
            <div className="wrapper-div">
              <Input
                name="question"
                type="text"
                label="Questions"
                onChange={formikTriviaQuestionsCreate.handleChange}
                value={formikTriviaQuestionsCreate.values.question}
                error={formikTriviaQuestionsCreate.errors.question}
                placeholder="enter trivia question"
              />
            </div>
            {formikTriviaQuestionsCreate.values.options.map(
              (optionText, index) => (
                <OptionContainer key={index}>
                  <RadioInput
                    placeholder="enter trivia options"
                    name={`options.${index}`}
                    type="text"
                    onChange={formikTriviaQuestionsCreate.handleChange}
                    value={optionText}
                    error={
                      Array.isArray(
                        formikTriviaQuestionsCreate.errors.options
                      ) && formikTriviaQuestionsCreate.errors.options[index]
                    }
                    onClickRemove={() =>
                      formikTriviaQuestionsCreate.setFieldValue(
                        "options",
                        formikTriviaQuestionsCreate.values.options.filter(
                          (_, i) => i !== index
                        )
                      )
                    }
                    radioName="correctAnswer"
                    radioChecked={correctAnswer === index}
                    radioOnChange={() => handleRadioChange(index)}
                  />
                </OptionContainer>
              )
            )}

            <div className="align">
              <Button2
                title="Add Options"
                src="/images/addd.svg"
                backgroundColor="rgba(0, 0, 0, 1)"
                onClick={() =>
                  formikTriviaQuestionsCreate.setFieldValue(
                    "options",
                    formikTriviaQuestionsCreate.values.options.concat("")
                  )
                }
                alt="add"
              />
              <Button2
                title="Create"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="create"
              />
            </div>
          </Modal>
        </form>
      )}

      <MainContent>
        <CenteredContent>
          <DashHeader2
            backOnclick="/dashboard-admin-overview"
            show
            title="create trivia questions"
            onClick={openModalCreateTriviaQuestions}
            titleMenu="Add Menu"
            src="/images/arrow-right.svg"
            others
            titleOthers="view trivia result"
            // othersOnclick={`/dashboard-admin/${id}/trivia-results`}
            othersOnclick={{
              pathname: `/dashboard-admin/${id}/trivia-results`,
              state: { questionsLength: questionsLength }
            }}
          />
        </CenteredContent>

        <Table className="mt-5">
          <TableData
            headtitle="All Trivia Questions"
            showInput

            {...(!triviaquestionsloading && {
              triviaquestions:
                triviaquestionsdata && triviaquestionsdata?.data?.length === 0
                  ? ""
                  : triviaquestionsdata,
            })}
          >
            <StatusHandler
              isError={triviaquestionserror}
              isLoading={triviaquestionsloading}
            ></StatusHandler>
            {triviaquestionsdata && triviaquestionsdata?.data < 1 ? (
              <ModalEmpty title="no trivia questions found" />
            ) : (
              triviaquestionsdata?.data.map((e, index) => (
                <tr key={index}>
                  <td data-cell="trivia question">{e.text}</td>
                  <td data-cell="options">
                    {e.options.map((option) => (
                      <span key={index}>
                        <div className="mb-1">{option.text}</div>
                        <br />
                      </span>
                    ))}
                  </td>

                  <td data-cell="actions" className="dropdown action">
                    <DropdownWrapper>
                      <DropdownToggle
                        onClick={() => handleDropdownToggle(index, e.id)}
                      >
                        <img src="/images/more.svg" className="more" />
                      </DropdownToggle>
                      <DropdownMenu
                        onMouseLeave={() => setOpenDropdownIndex(null)}
                        className={openDropdownIndex === index ? "open" : ""}
                      >
                        <DropdownItem>
                          <StyledNavLink
                            className="nav-link"
                            onClick={() => openModalEditTriviaQuestions(e)}
                          >
                            edit questions
                          </StyledNavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <StyledLink
                            onClick={() => {
                              setQuestionsid(e.id);
                              openModalDeleteTriviaQuestions();
                            }}
                          >
                            delete questions
                          </StyledLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </DropdownWrapper>
                  </td>
                </tr>
              ))
            )}
          </TableData>
        </Table>
      </MainContent>
    </Wrapper>
  );
};
