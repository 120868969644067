import React, { useState } from "react";
import styled from "styled-components";
import { DashHeader2 } from "../../components/dashboardmain/Header";
import { DetailsBox } from "../../components/dashboardmain/DetailsBox";
import toast from "react-hot-toast";
import axios from "axios";
import { Button2 } from "../../components/button/Button2";
import Modal from "../../components/ModalBox/Modal";
import { Radio } from "../../components/input/Radio";
import { useParams } from "react-router-dom";
import { URL } from "../../../../utils/url";

export const AdminEventDetails = () => {
  const [loading, setLoading] = useState(false);
  const [isModalToggleSwitchOpen, setModalToggleSwitchOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { id } = useParams();

  const openModalSwitch = () => {
    setModalToggleSwitchOpen(true);
  };

  const closeModalSwitch = () => {
    setModalToggleSwitchOpen(false);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const multipartConfigs = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const onSubmitSwitch = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      ads_active: selectedOption === "ads_active",
      optical_active: selectedOption === "optical_active",
      trivia_active: selectedOption === "trivia_active",
    };

    try {
      const response = await axios.patch(
        `${URL}superadmin/event/${id}/`,
        payload,
        multipartConfigs
      );

      toast.success("status updated successfully !!");
      setLoading(false);
      closeModalSwitch();
    } catch (error) {
      setLoading(false);
      toast.error("an error occurred. please try again");
    }
  };

  return (
    <Wrapper>
      {isModalToggleSwitchOpen && (
        <form onSubmit={onSubmitSwitch}>
          <Modal
            title="Activate Ads | Trivia | Optical"
            imageSource="/images/mdt3.png"
            rightIconSrc="/images/mdx.svg"
            borderColor="0.5px solid rgba(0, 160, 64, 1)"
            backgroundColor="rgba(237, 255, 244, 1)"
            textColor="rgba(0, 160, 64, 1)"
            onClose={closeModalSwitch}
          >
            <div className="wrapper-div mt-3">
              <Radio
                placeholder="activate ads"
                radioChecked={selectedOption === "ads_active"}
                radioOnChange={handleRadioChange}
                radioName="activity"
                value="ads_active"
              />
              <Radio
                placeholder="activate trivia"
                radioChecked={selectedOption === "trivia_active"}
                radioOnChange={handleRadioChange}
                radioName="activity"
                value="trivia_active"
              />
              <Radio
                placeholder="activate optical illusion"
                radioChecked={selectedOption === "optical_active"}
                radioOnChange={handleRadioChange}
                radioName="activity"
                value="optical_active"
              />
            </div>
            <div className="align">
              <Button2
                title="Update"
                src="/images/uploadd.svg"
                backgroundColor={
                  loading ? "rgba(255, 92, 0, 0.1)" : "rgba(0, 0, 0, 1)"
                }
                alt="update"
                type="submit"
              />
            </div>
          </Modal>
        </form>
      )}
      <MainContent>
        <CenteredContent>
          <DashHeader2
            admin
            title="Event Details"
            towhere="/dashboard-admin-overview"
          />
        </CenteredContent>
        <CenteredContent>
          <DetailsBox userType="admin" />
        </CenteredContent>
        <FixedButton className="fixed-button bouncing-element" onClick={openModalSwitch}>
          <img src="/images/mdgreen.svg" alt="actions" />
          <Tooltip className="tooltip">activate ads | trivia | optical illusion</Tooltip>
        </FixedButton>
      </MainContent>
    </Wrapper>
  );
};

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  & .align {
    display: flex;
    justify-content: flex-end;
  }
`;

const MainContent = styled.div`
  width: 100%;
  background-color: #f0f4f7;
  height: 100%;
  overflow-y: auto;
`;

const FixedButton = styled.button`
  position: fixed;
  top: 2.5%;
  right: 30px;
  padding: 5px 5px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  
  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 480px) {
    top: 10%;
    right: 30px;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -125%; 
  right: 80%;
  transition: opacity 0.3s;
  padding: 0px 1px;
  &::after {
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;
