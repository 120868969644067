import React from "react";
import styled from "styled-components";

export const WhyCard = ({
  imageUrl,
  numberImageUrl,
  part1,
  part2,
  description,
  withPadding,
}) => {
  return (
    <StyledWhyCard $with={withPadding}>
      <div className="whydiv">
        <img src={imageUrl} alt="whyimage" className="why bouncing-element" />
      </div>
      <img src={numberImageUrl} alt="number" className="number" />
      <div className="contentdiv">
        <h1>
          {part1}
          <br />
          {part2}
        </h1>
        <img src="/images/line.svg" className="line" />
        <p className="des">{description}</p>
      </div>
    </StyledWhyCard>
  );
};

const StyledWhyCard = styled.div`
  width: 400px;
  border-radius: 35px;
  background: rgba(245, 245, 247, 0.59);
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  position: relative;

  .contentdiv {
    background-color: white;
    padding: 40px;
    border-radius: 25px;
  }

  .why {
    width: auto;
    height: 200px;
  }

  .line {
    margin: 10px 0px 10px 0px;
  }

  .whydiv {
    padding-left: ${(props) => (props.$with ? "40px" : "10px")};
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .number {
    width: auto;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 10px;
  }

  .des {
    font-size: 13px;
    color: #444;
    letter-spacing: 0.5px;
    line-height: 16px;
    line-height: 152%;
    font-family: "MatterNormal", sans-serif;
  }

  .descri {
    background-color: white;
  }

  h1 {
    font-family: "MatterNormal", sans-serif;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 124.9%;
  }

  @media (max-width: 1200px) {
    width: 400px;
    .line {
      width: auto;
    }
    .des {
      font-size: 17px;
    }

    h1 {
      font-size: 32px;
    }
    .contentdiv {
      padding: 30px;
    }
    .whydiv {
      padding-left: ${(props) => (props.$with ? "20px" : "0px")};
    }
  }

  @media (max-width: 912px) {
    width: 500px;
    .line {
      width: auto;
    }
    .des {
      font-size: 16px;
    }

    h1 {
      font-size: 30px;
    }
    .contentdiv {
      padding: 30px;
    }
    .whydiv {
      padding-left: ${(props) => (props.$with ? "20px" : "0px")};
    }
    .why {
      width: auto;
      height: 150px;
      /* object-fit: cover; */
    }
  }

  @media (max-width: 640px) {
    width: 450px;

    .line {
      width: 185px;
    }
    .des {
      font-size: 16px;
    }

    h1 {
      font-size: 30px;
    }
    .contentdiv {
      padding: 30px;
    }
    .whydiv {
      padding-left: ${(props) => (props.$with ? "20px" : "0px")};
    }
  }

  @media (max-width: 480px) {
    /* width: 360px; */
    width: 100%;
    .number {
      height: 80px;
    }
    .des {
      font-size: 17px;
    }
    .line {
      width: 185px;
    }
    h1 {
      font-size: 25px;
    }
    .contentdiv {
      padding: 30px;
    }
    .whydiv {
      padding-left: ${(props) => (props.$with ? "20px" : "0px")};
    }
  }
`;
