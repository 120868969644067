import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 24px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${(props) => props.start}%;
  background-color: #ff5c00;
  transition: width 1s ease-in-out;
`;

const ProgressBar2 = ({ start, end }) => {
  const [progress, setProgress] = useState(start);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < end ? prevProgress + 1 : end
      );
    }, 50);

    return () => clearInterval(progressInterval);
  }, [start, end]);

  return (
    <ProgressBarContainer>
      <ProgressBarFill start={progress} />
    </ProgressBarContainer>
  );
};

export default ProgressBar2;
