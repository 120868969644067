import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

export const Footer = () => {
  const instagramProfileUrl = 'https://www.instagram.com/usewiserve/';
  const twitterProfileUrl = 'https://twitter.com/usewiserve';

  return (
    <StyledFooter id="why_wiserve">
      {/* <div className="d-flex justify-content-center">
          <BigText title="Why Wiserve?" />
        </div> */}
      <div className="row parent">
        <div className="col-lg-6 col-md-6 flexingstart">
          <div className="wiservelogo">
            <img
              src="/images/footerlogo.png"
              alt="footerwiservelogo"
              className="wiserve bouncing-element"
            />
          </div>
          
          <div className="nav_links">
          <div className="mb-3">
              <h1 id="color">Menu</h1>
            </div>
            <ul>
              <li>
                <Link
                //   activeClass="active"
                  to="why_wiserve"
                  smooth={true}
                  duration={500}
                >
                  Why Wiserve?
                </Link>
              </li>
              <li>
                <Link
                //   activeClass="active"
                  to="how_it_works"
                  smooth={true}
                  duration={500}
                >
                  How it works?
                </Link>
              </li>
              <li>
                <Link
                //   activeClass="active"
                  to="faqs"
                  smooth={true}
                  duration={500}
                >
                  Faqs
                </Link>
              </li>
              <li>
                <Link
                //   activeClass="active"
                  to="contact"
                  smooth={true}
                  duration={500}
                >
                  {/* Contact us */}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <div>
              <p id="color">Connect with us</p>
            </div>
            <div className="socials bouncing-element">

              <img
                src="/images/facebook.svg"
                alt="facebook"
                className="socialslogo"
              />
          <a href={twitterProfileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src="/images/twitter.svg"
                alt="twitter"
                className="socialslogo"
              />
              </a>
          <a href={instagramProfileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src="/images/instagram.svg"
                alt="instagram"
                className="socialslogo"
              />
              </a>

            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 flexingend">
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  font-family: "MatterNormal", sans-serif;
  color: white;
  font-size: 14px;
  background: #000;
  padding: 40px 10%;
  #color{
    color: #ff5c00;
  }
  .parent {
    padding: 0px 0%;
  }
  li {
    padding: 0px 0px 15px 0px;
  }
  .socials {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  .socialslogo {
    width: 25px;
    height: 23px;
    cursor: pointer;
    margin-top:10px;
  }

  .wiserve {
    width: 100px;
    height: auto;
    margin-top: 5px;
  }
  .flexingend {
    display: flex;
    justify-content: flex-end;
  }
  .flexingstart {
    display: flex;
    justify-content: space-between;
    //   align-items:flex-start;
  }
  .nav_links ul {
    list-style: none;
    padding: 0px 0px;
    margin: 0;
    cursor: pointer;
  }
  @media (max-width: 912px) {
    .wiserve {
    width: 80px;
    height: auto;
  }
  }
  @media (max-width: 540px) {
    li {
    /* padding: 0px 0px 15px 0px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
    font-size: 16px;
    .flexingstart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
    .socials {
    display: flex;
    justify-content: center;
    align-items:center;
    gap: 1rem;
    margin-top: 10px;
  }
  .wiserve {
    width: 80px;
    height: auto;
    margin-bottom: 20px;
    /* margin-left: -20px; */
  }
    .flexingend {
      display: flex;
      justify-content: flex-start;
    }
  }
`;
